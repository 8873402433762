import { FC, useEffect, useRef, useState } from 'react';
import './Select.scss';
import AnimateHeight, { Height } from 'react-animate-height';
import { useClickOutside } from '@react-hooks-library/core';

export interface ISelectOptions {
  value: string | number;
  label: string | number;
}

interface IProps {
  options?: any;
  register?: any;
  name?: string;
  optionsValue: ISelectOptions[];
  setValue?: (value: string | number) => void;
  active?: boolean;
  callback?: (option: ISelectOptions) => void;
  prefix?: string;
  defaultValue?: ISelectOptions;
}

const SelectUi: FC<IProps> = ({
  options,
  register,
  name,
  optionsValue,
  setValue,
  active,
  callback,
  prefix,
  defaultValue,
}) => {
  const [height, setHeight] = useState<Height>(active ? 'auto' : 0);

  const [activeOption, setActiveOption] = useState(defaultValue || optionsValue?.[0]);

  const handleOpen = () => {
    setHeight(height === 0 ? 'auto' : 0);
  };

  useEffect(() => {
    setActiveOption(defaultValue || optionsValue?.[0]);
  }, [defaultValue, optionsValue]);

  const handleOptions = (option: ISelectOptions) => {
    setActiveOption(option);
    setValue && setValue(option.value);
    handleOpen();
    callback && callback(option);
  };

  const ref = useRef(null);

  useClickOutside(ref, () => {
    setHeight(0);
  });

  return (
    optionsValue && (
      <div className="select-wrapper" style={{ position: 'relative' }} ref={ref}>
        <div className="select">
          <select
            className="select__select select2-hidden-accessible"
            style={{ width: '100%' }}
            {...(register ? register(name, options) : {})}
          ></select>
          <span
            className="select2 select2-container select2-container--default"
            dir="ltr"
            data-select2-id="select2-data-2-jjfb"
            style={{ width: '100%' }}
          >
            <span className="selection">
              <span
                className="select2-selection select2-selection--single"
                role="combobox"
                aria-expanded={height !== 0}
                onClick={handleOpen}
                aria-controls="example-panel"
                aria-haspopup="true"
                tabIndex={0}
                aria-disabled="false"
                aria-labelledby="select2-1qy0-container"
              >
                <span
                  className="select2-selection__rendered"
                  id="select2-1qy0-container"
                  role="textbox"
                  aria-readonly="true"
                  title="30 Мбит - 250 ₽/мес"
                >
                  {`${prefix ? prefix : ''} ${activeOption?.label}`}
                </span>
                <span
                  className="select2-selection__arrow"
                  role="presentation"
                  style={{ transform: `rotate(${height !== 0 ? '180deg' : '0deg'})` }}
                >
                  <b role="presentation" />
                </span>
              </span>
            </span>

            <div className="react-select-bottom">
              <AnimateHeight id="example-panel" duration={500} height={height}>
                <div className="react-select-bottom__inner">
                  <span className="select2-results">
                    <div
                      className="select2-results__options"
                      role="listbox"
                      id="select2-rgc5-results"
                      aria-expanded="true"
                      aria-hidden="false"
                    >
                      {optionsValue.map((option, index) => (
                        <li
                          key={index}
                          className="select2-results__option select2-results__option--selectable"
                          onClick={() => handleOptions(option)}
                        >
                          {option.label}
                        </li>
                      ))}
                    </div>
                  </span>
                </div>
              </AnimateHeight>
            </div>
          </span>
        </div>
      </div>
    )
  );
};

export default SelectUi;
