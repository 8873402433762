import { FC, useState } from 'react'

import { useAppDispatch } from 'src/hooks/redusHooks'
import { asyncNotifications, asyncNotificationsStatus } from 'src/redux/slices/notificationsSlice'
import { Message } from 'src/types/swagger'

import './style.scss'

interface IProps {
	data: Message
}
const NotificationItem: FC<IProps> = ({ data }) => {
	const dispatch = useAppDispatch()
	const [isLoading, setIsLoading] = useState(false)
	const handleRead = async (item: Message) => {
		setIsLoading(true)
		await dispatch(
			asyncNotificationsStatus({
				new_status: item.is_read === 'new' ? 'read' : 'new',
				notification_id: item.id
			})
		)
		await dispatch(asyncNotifications())
		setIsLoading(false)
	}

	return (
		<div className="notification-page__item r-notification-page__item">
			<div className="notification-page__date">
				<div
					className={`notification-page__date-icon ${data.is_read === 'read' ? '' : 'red'}`}
				/>
				<div className="notification-page__date-text">{data.created}</div>
			</div>
			<div className="notification-page__item-heading">{data.title}</div>
			<div className="notification-page__item-text">{data.body}</div>
			{data.notif_type.type === 'system' && (
				<div className="notification-page__item-label">
					<div className="label alt">{data.notif_type.text}</div>
				</div>
			)}

			{data.is_read === 'new' && (
				<div
					className={`notification-page__item-btn ${isLoading ? 'notification-page__item-btn--loading' : ''}`}
					onClick={() => handleRead(data)}
				>
					<div className="notification-page__item-icon">
						{!isLoading ? (
							<svg
								className="notification-page__item-svg"
								xmlns="http://www.w3.org/2000/svg"
								width={24}
								height={24}
								viewBox="0 0 24 24"
								fill="none"
							>
								<path
									d="M17.8333 3H6.16667C5.062 3.0013 4.00296 3.43272 3.22185 4.19963C2.44073 4.96655 2.00132 6.00633 2 7.09091L2 16.9091C2.00132 17.9937 2.44073 19.0335 3.22185 19.8004C4.00296 20.5673 5.062 20.9987 6.16667 21H17.8333C18.938 20.9987 19.997 20.5673 20.7782 19.8004C21.5593 19.0335 21.9987 17.9937 22 16.9091V7.09091C21.9987 6.00633 21.5593 4.96655 20.7782 4.19963C19.997 3.43272 18.938 3.0013 17.8333 3ZM6.16667 4.63636H17.8333C18.3323 4.63733 18.8196 4.78488 19.2325 5.06002C19.6453 5.33517 19.9649 5.72532 20.15 6.18027L13.7683 12.4467C13.2987 12.906 12.6628 13.1638 12 13.1638C11.3372 13.1638 10.7013 12.906 10.2317 12.4467L3.85 6.18027C4.03512 5.72532 4.35468 5.33517 4.76754 5.06002C5.1804 4.78488 5.66768 4.63733 6.16667 4.63636ZM17.8333 19.3636H6.16667C5.50363 19.3636 4.86774 19.105 4.3989 18.6447C3.93006 18.1844 3.66667 17.5601 3.66667 16.9091V8.31818L9.05333 13.6036C9.83552 14.3697 10.8952 14.7998 12 14.7998C13.1048 14.7998 14.1645 14.3697 14.9467 13.6036L20.3333 8.31818V16.9091C20.3333 17.5601 20.0699 18.1844 19.6011 18.6447C19.1323 19.105 18.4964 19.3636 17.8333 19.3636Z"
									fill="#001F38"
								/>
							</svg>
						) : (
							<div>
								<svg
									version="1.1"
									id="loader-1"
									xmlns="http://www.w3.org/2000/svg"
									xmlnsXlink="http://www.w3.org/1999/xlink"
									x="0px"
									y="0px"
									width="40px"
									height="40px"
									viewBox="0 0 50 50"
									xmlSpace="preserve"
									className="notification-page__item-svg"
								>
									<path
										fill="#929CA5"
										d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z"
									>
										<animateTransform
											attributeType="xml"
											attributeName="transform"
											type="rotate"
											from="0 25 25"
											to="360 25 25"
											dur="0.6s"
											repeatCount="indefinite"
										/>
									</path>
								</svg>
							</div>
						)}
					</div>
					<div className={`notification-page__item-caption `}>
						Отметить как прочитанное
					</div>
				</div>
			)}
			{data.is_read === 'read' && (
				<div className="notification-page__item-btn r-notification-page__item-btn cursor-default">
					<div className="notification-page__item-icon  mr-2">
						<svg
							width={24}
							height={24}
							viewBox="0 0 24 24"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M5 12.5L9.667 17L19 8"
								stroke="#929CA5"
								strokeWidth="1.5"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
						</svg>
					</div>
					<div className="notification-page__item-text">Прочитано</div>
				</div>
			)}
		</div>
	)
}

export default NotificationItem
