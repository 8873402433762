import { FeeKindEnum } from 'src/types/swagger';

export function paymentDisplay(
  sum?: number | null,
  // @ts-ignore
  period: number | null,
  kind: FeeKindEnum
) {
  if (kind === FeeKindEnum.Day && sum) {
    return `${sum} ₽/${period !== 1 ? period + ' ' : ''}день`;
  } else if (kind === FeeKindEnum.Month && sum) {
    return `${sum} ₽/${period !== 1 ? period + ' ' : ''}мес`;
  } else if (kind === FeeKindEnum.Year && sum) {
    return `${sum} ₽/${period !== 1 ? period + ' ' : ''}год`;
  } else if (sum && !period && !kind) {
    return `${sum} ₽`;
  } else if (sum === 0) {
    return '0 ₽';
  } else {
    return null;
  }
}
