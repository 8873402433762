import { ServiceChangeState } from './type'

export const serviceChangeInitial: ServiceChangeState = {
	loading: false,
	data: null,
	sortedTariffs: [],

	selectTariff: null,
	selectAbonement: null
}
