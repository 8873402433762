import { NEWS } from 'src/routes/data';
import Panel from '../../panel/Panel';
import { useAppSelector } from 'src/hooks/redusHooks';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const NewsDetail = () => {
  const { detail } = useAppSelector((state) => state.news);
  const navigate = useNavigate();

  useEffect(() => {
    if (!detail) navigate(NEWS);
  }, []);

  return (
    detail && (
      <div className="news-page news-page--default">
        <Panel text={detail?.title} linkText={'Новости'} href={NEWS} />
        <section className="section section--first-panel news-page__section">
          <div className="container">
            <h1 className="section__title">Новости</h1>
            <div className="news-page__container">
              <div className="news-page__wrapper">
                <div className="news-page__content">
                  <h2 className="news-page__heading">{detail?.title}</h2>
                  <div className="section__label">{detail?.published}</div>
                  <div
                    className="react-conditions-content"
                    dangerouslySetInnerHTML={{ __html: detail?.full_content! }}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    )
  );
};

export default NewsDetail;
