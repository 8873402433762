import $api from 'src/api/service/request';
import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { NewsItem, ResponseNews } from 'src/types/swagger';
import { initialStore } from '../_initial';
import { AxiosResponse } from 'axios';
import { API_DOMAIN } from 'src/api/service/endpoints';

export const asyncNews = createAsyncThunk<NewsItem[], void, { rejectValue: string }>(
  'promotions/news',
  async (_, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<ResponseNews> = await $api.get(`${API_DOMAIN}promotions/news/`);

      console.info(typeof response.data.data.items);
      const arr = Object.values(response.data.data.items);

      return arr;
    } catch (error: any) {
      return rejectWithValue(error.message || 'Failed to fetch news');
    }
  }
);

export const newsSlice = createSlice({
  name: 'news',
  initialState: initialStore.news,

  reducers: {
    setTargetNews: (state, action: PayloadAction<NewsItem>) => {
      state.detail = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(asyncNews.fulfilled, (state, action) => {
      state.data = action.payload;
    });
  },
});

export const { setTargetNews } = newsSlice.actions;
export default newsSlice.reducer;
