import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { createSelector } from '@reduxjs/toolkit'

import { AxiosResponse } from 'axios'
import { DateRange } from 'rsuite/esm/DateRangePicker'
import { getFromLocalStorage } from 'src/api/service/helpers/localStorage.helper'
import {
	BaseResponse,
	NotifyPool,
	NotifyStatusRequest,
	ResponseNotifyPool,
	ResponseReportsOverview
} from 'src/types/swagger'
import { formatDate } from 'src/utils.js/formatDate'

import { API_DOMAIN } from '../../api/service/endpoints'
import $api from '../../api/service/request'
import { initialStore } from '../_initial'
import { RootState } from '../store'

export const asyncNotifications = createAsyncThunk<
	NotifyPool,
	DateRange | undefined,
	{ rejectValue: string }
>('asyncNotifications', async (date, { rejectWithValue }) => {
	try {
		const response: AxiosResponse<ResponseNotifyPool> = await $api.get(
			`${API_DOMAIN}clients/${getFromLocalStorage('agreement')}/notifications/${date && date !== undefined ? `?start_date=${formatDate(date[0], 'send')}&` + `end_date=${formatDate(date[1], 'send')}` : ''}`
		)
		return response.data.data
	} catch (error: any) {
		return rejectWithValue(error.message || 'Failed to fetch notifications')
	}
})

//Обновление статуса уведомления прочитано/нет
export const asyncNotificationsStatus = createAsyncThunk<
	BaseResponse,
	NotifyStatusRequest,
	{ rejectValue: string }
>('asyncNotificationsStatus', async (data, { rejectWithValue }) => {
	try {
		const response: AxiosResponse<ResponseReportsOverview> = await $api.post(
			`${API_DOMAIN}clients/${getFromLocalStorage('agreement')}/notifications/`,
			data
		)

		return response.data
	} catch (error: any) {
		return rejectWithValue(error.message || 'Failed to fetch asyncNotificationsStatus')
	}
})

export const clientSlice = createSlice({
	name: 'notifications',
	initialState: {
		...initialStore.notifications,
		currentPage: 1,
		itemsPerPage: 4
	},
	reducers: {
		setCurrentPage: (state, action) => {
			state.currentPage = action.payload
		},
		setItemsPerPage: (state, action) => {
			state.itemsPerPage = action.payload
		},
		setFilterCategory: (state, action: PayloadAction<'all' | 'new' | 'read'>) => {
			state.filter = action.payload
			state.currentPage = 1
		}
	},
	extraReducers: builder => {
		builder.addCase(asyncNotifications.fulfilled, (state, action) => {
			state.data = action.payload
			state.messages = action.payload.messages
			state.currentPage = 1
			state.range = [action.payload.start_date, action.payload.end_date]
			state.newMessages = action.payload.messages.length
				? action.payload.messages.filter(item => item.is_read === 'new')
				: []
		})
	}
})

export const { setCurrentPage, setItemsPerPage, setFilterCategory } = clientSlice.actions

export default clientSlice.reducer

export const selectMessages = (state: RootState) => state.notifications.messages
export const selectCurrentPage = (state: RootState) => state.notifications.currentPage
export const selectItemsPerPage = (state: RootState) => state.notifications.itemsPerPage
export const selectFilter = (state: RootState) => state.notifications.filter
export const selectNewMessages = (state: RootState) => state.notifications.newMessages.length
export const selectRange = (state: RootState) => state.notifications.range

export const selectPaginatedMessages = createSelector(
	[selectMessages, selectCurrentPage, selectItemsPerPage, selectFilter],
	(messages, currentPage, itemsPerPage, filter) => {
		const filteredMessages =
			filter === 'all' ? messages : messages.filter(item => item.is_read === filter)

		const total = filteredMessages.length
		const totalPages = Math.ceil(total / itemsPerPage)

		const adjustedCurrentPage = currentPage > totalPages ? totalPages : currentPage

		const startIndex = (adjustedCurrentPage - 1) * itemsPerPage
		const endIndex = startIndex + itemsPerPage

		const paginatedMessages = filteredMessages.slice(startIndex, endIndex)

		return {
			notificationItems: paginatedMessages,
			total: filteredMessages.length,
			currentPage: adjustedCurrentPage,
			totalPages: totalPages
		}
	}
)
