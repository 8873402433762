import { FC, useEffect, useState } from 'react';
import Panel from '../panel/Panel';
import { AxiosResponse } from 'axios';
import $api from 'src/api/service/request';
import { API_DOMAIN } from 'src/api/service/endpoints';
import Loader from 'src/components/ui/loader/Loader';
import { ResponseUsefulInfo, UsefulInfo, UsefulInfoItem } from 'src/types/swagger';
import InfoSvg from './components/InfoSvg';
import './Info.scss';

//TODO: нет значка (Рисуется)

const Info: FC = () => {
  const [data, setData] = useState<UsefulInfo>();
  const [isLoading, setIsLoading] = useState(true);
  const [sortData, setSortData] = useState<UsefulInfoItem[]>();

  //Первичные данные
  useEffect(() => {
    setIsLoading(true);
    const fetchData = async () => {
      try {
        const response: AxiosResponse<ResponseUsefulInfo> = await $api.get(
          `${API_DOMAIN}promotions/helpinfo/`
        );

        const { data } = response.data;
        if (data) {
          setData(data);
        }
      } catch (error: any) {
        console.error('Failed to fetch video:', error.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);
  //Первичные данные

  //Сортировка
  useEffect(() => {
    if (data) {
      const sortedData = data.items.sort((a, b) => {
        return a.order_number - b.order_number;
      });
      setSortData(sortedData);
    }
  }, [data]);
  //Сортировка

  //прелоадер
  if (isLoading) {
    return <Loader state={isLoading} />;
  }
  //прелоадер

  return data && sortData ? (
    <div className="info-page r-info-page">
      <Panel text="Полезная информация" />
      <section className="section section--first-panel">
        <div className="container">
          <h1 className="section__title">{data.title}</h1>

          <div className="info-page__grid">
            {sortData.map((item) => (
              <a className="info-page__item" target="blank" href={item.url} key={item.url}>
                <div className="info-page__icon">
                  <InfoSvg type={item.icon_code} />
                </div>
                <div className="info-page__text">{item.title}</div>
              </a>
            ))}
          </div>
        </div>
      </section>
    </div>
  ) : null;
};

export default Info;
