import { FC, useState } from 'react';
import './ProfileEmail.scss';
import Input from 'src/components/ui/input/Input';
import { useForm } from 'react-hook-form';
import { BaseResponse, Email, EmailRequest } from 'src/types/swagger';
import { AxiosResponse } from 'axios';
import $api from 'src/api/service/request';
import { API_DOMAIN } from 'src/api/service/endpoints';
import { getFromLocalStorage } from 'src/api/service/helpers/localStorage.helper';
import Loader from 'src/components/ui/loader/Loader';
import ProfileEmailModalChange from '../emailModalChange/ProfileEmailModalChange';
import useModal from 'src/hooks/useModal';

interface IProps {
  data: Email | null;
}
const ProfileEmail: FC<IProps> = ({ data }) => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    getValues,
  } = useForm({ mode: 'onChange' });

  const [isLoading, setIsLoading] = useState(false);
  const [isEmailChangeActive, setEmailChangeActive] = useState(false);
  const [isAddEmailActive, setIsAddEmailActive] = useState(false);
  const { open } = useModal();

  const emailChange = () => {
    setEmailChangeActive((prev) => !prev);
  };

  //Обработчик клика по кнопке добавления почты
  const handleEmailAddClick = () => {
    setIsAddEmailActive((prev) => !prev);
  };
  //Обработчик клика по кнопке добавления почты

  //отправка запроса на добавление почты
  const addEmailSubmit = async () => {
    setIsLoading((prev) => !prev);
    const body: EmailRequest = {
      email: getValues('addEmail'),
    };

    try {
      const response: AxiosResponse<BaseResponse> = await $api.post(
        `${API_DOMAIN}clients/${getFromLocalStorage('agreement')}/profile/contacts/email/`,
        body
      );
      const { data } = response;
      open({
        text: data?.data?.title || data?.client_message || 'Изменения сохранены',
        modalType: 'success',
        isReload: true,
        description: data?.data?.description || '',
      });
    } catch (error: any) {
      open({
        text: error.response?.data?.data?.title || error.response.data.client_message || 'Ошибка',
        description: error.response?.data?.data?.description || '',
        modalType: 'error',
      });
    } finally {
      setIsLoading((prev) => !prev);
    }
  };
  //отправка запроса на добавление почты

  //отправка запроса на подтверждение почты
  const confirmEmail = async () => {
    setIsLoading((prev) => !prev);
    const body: EmailRequest = {
      email: data?.email!,
    };

    try {
      const response: AxiosResponse<BaseResponse> = await $api.post(
        `${API_DOMAIN}clients/${getFromLocalStorage('agreement')}/profile/contacts/email/confirm/`,
        body
      );
      const { data } = response;
      open({
        text: data?.data?.title || data?.client_message || 'Изменения сохранены',
        modalType: 'success',
        isReload: true,
        description: data?.data?.description || '',
      });
    } catch (error: any) {
      console.info('error', error);
      open({
        text: error.response?.data?.data?.title || error.response.data.client_message || 'Ошибка',
        description: error.response?.data?.data?.description || '',
        modalType: 'error',
      });
    } finally {
      setIsLoading(false);
    }
  };
  //отправка запроса на подтверждение почты

  return (
    <>
      {/* //лоадер */}
      <Loader state={isLoading} />
      {/* //лоадер */}
      <ProfileEmailModalChange
        state={isEmailChangeActive}
        setState={setEmailChangeActive}
        oldEmail={data?.email || ''}
      />
      <>
        {data?.email ? (
          <div className="profile-page__block-item">
            <div className="section__label">Эл. почта</div>
            <div className="profile-page__text">
              {data.email}

              {data.is_email_verified && (
                <span className="section__label profile-page__block-label">Подтверждено</span>
              )}
            </div>
            <div className="profile-email__row">
              {data.is_change_email && (
                <div className="profile-page__button" onClick={emailChange}>
                  Изменить
                </div>
              )}
              {!data.is_email_verified && data.email && (
                <div className="profile-page__button" onClick={confirmEmail}>
                  Подтвердить
                </div>
              )}
            </div>
          </div>
        ) : (
          <form className="profile-page__block-item" onSubmit={handleSubmit(addEmailSubmit)}>
            {!isAddEmailActive && <div className="section__label">Эл. почта</div>}
            <div className="profile-page__text">
              {isAddEmailActive ? (
                <Input
                  button={true}
                  register={register}
                  filled={watch('addEmail')}
                  placeholder={'Эл. почта'}
                  name="addEmail"
                  error={errors.addEmail}
                  options={{
                    required: 'Заполните поле',
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                      message: 'Неверный формат',
                    },
                  }}
                />
              ) : (
                <span className="section__label profile-page__block-label profile-email__ml-0">
                  Не заполнено
                </span>
              )}
            </div>

            {!isAddEmailActive && (
              <div className="profile-email__row">
                <div className="profile-page__button" onClick={handleEmailAddClick}>
                  Добавить
                </div>
              </div>
            )}
          </form>
        )}
      </>
    </>
  );
};

export default ProfileEmail;
