export const ERROR = {
  PHONE_NUMBER_INVALID: 'Некорректный номер',
  // PHONE_NUMBER_LENGTH: 'Номер телефона должен содержать 11 цифр',
  // PHONE_NUMBER_DIGITS: 'Номер телефона должен содержать только цифры',
  // PHONE_NUMBER_EMPTY: 'Поле не может быть пустым',
  // PHONE_NUMBER_INVALID: 'Неверный формат номера телефона',
  // EMAIL_INVALID: 'Неверный формат адреса электронной почты',
  // EMAIL_EMPTY: 'Поле не может быть пустым',
  // PASSWORD_EMPTY: 'Поле не может быть пустым',
  // PASSWORD_LENGTH: 'Пароль должен содержать не менее 6 символов',
  // PASSWORDS_NOT_EQUAL: 'Пароли не совпадают',
  // NAME_EMPTY: 'Поле не может быть пустым',
  // NAME_LENGTH: 'Имя должно содержать не менее 2 символов',
  // SURNAME_EMPTY: 'Поле не может быть пустым',
  // SURNAME_LENGTH: 'Фамилия должна содержать не менее 2 символов',
  // PATRONYMIC_LENGTH: 'Отчество должно содержать не менее 2 символов',
  // PATRONYMIC_EMPTY: 'Поле не может быть пустым',
  // DATE_OF_BIRTH_EMPTY: 'Поле не может быть пустым',
};
