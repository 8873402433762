import React, { FC } from 'react';
import Button from '../button/Button';
import { IModalData } from 'src/redux/slices/modalSlice';
import { useNavigate } from 'react-router-dom';
import Panel from 'src/components/common/panel/Panel';

interface IProps {
  data: IModalData;
}
const ResponsePage: FC<IProps> = ({ data }) => {
  const { modalType, text, description, isReload } = data;
  const navigate = useNavigate();
  return (
    <div className="services-page connected-page connected-page--default">
      <Panel text="" onBack={true} linkText="Назад" />
      <section className="section section--first-panel partners-page__section white">
        <div className="container">
          <div className="partners-page__part">
            <div className="partners-page__part-container">
              <div className="partners-page__part-icon">
                {modalType === 'success' ? (
                  <svg
                    className="partners-page__part-svg"
                    xmlns="http://www.w3.org/2000/svg"
                    width={41}
                    height={40}
                    viewBox="0 0 41 40"
                    fill="none"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M37.1673 31.6667V17.475C37.1673 17.2392 37.151 17.006 37.1347 16.773C37.1322 16.7376 37.1297 16.7021 37.1273 16.6667H25.5007C24.1746 16.6667 22.9028 16.1399 21.9651 15.2022C21.0274 14.2645 20.5007 12.9927 20.5007 11.6667V0.04C20.4652 0.0375781 20.4298 0.0350981 20.3943 0.0326176C20.1614 0.0163204 19.9281 0 19.6923 0H12.1673C9.95799 0.00264643 7.83991 0.88147 6.27768 2.4437C4.71545 4.00593 3.83663 6.12401 3.83398 8.33333V31.6667C3.83663 33.876 4.71545 35.9941 6.27768 37.5563C7.83991 39.1185 9.95799 39.9974 12.1673 40H28.834C31.0433 39.9974 33.1614 39.1185 34.7236 37.5563C36.2859 35.9941 37.1647 33.876 37.1673 31.6667ZM22.8721 23.9179C22.2745 23.3442 21.325 23.3636 20.7513 23.9612C20.1775 24.5588 20.1969 25.5084 20.7945 26.0821L22.2715 27.5H16C15.1716 27.5 14.5 28.1716 14.5 29C14.5 29.8284 15.1716 30.5 16 30.5H22.2715L20.7945 31.9179C20.1969 32.4916 20.1775 33.4412 20.7513 34.0388C21.325 34.6364 22.2745 34.6558 22.8721 34.0821L27.0388 30.0821L28.166 29L27.0388 27.9179L22.8721 23.9179Z"
                      fill="#0076D8"
                    />
                    <path
                      d="M23.834 0.765625V11.6656C23.834 12.1077 24.0096 12.5316 24.3221 12.8441C24.6347 13.1567 25.0586 13.3323 25.5007 13.3323H36.4007C35.8188 11.7905 34.9148 10.3904 33.749 9.22563L27.9423 3.41562C26.7761 2.25114 25.3757 1.34785 23.834 0.765625Z"
                      fill="#EC003E"
                    />
                  </svg>
                ) : (
                  <svg width={40} height={40} viewBox="0 0 40 40" fill="none">
                    <g clipPath="url(#clip0_3770_35008)">
                      <circle cx={20} cy={20} r={20} fill="#EC003E" />
                      <g clipPath="url(#clip1_3770_35008)">
                        <path
                          d="M26.1913 14.309C26.1002 14.2179 25.9765 14.1667 25.8476 14.1667C25.7187 14.1667 25.5951 14.2179 25.504 14.309L20.5003 19.3126L15.4967 14.309C15.4055 14.2179 15.2819 14.1667 15.153 14.1667C15.0241 14.1667 14.9005 14.2179 14.8093 14.309C14.7182 14.4002 14.667 14.5238 14.667 14.6527C14.667 14.7816 14.7182 14.9052 14.8093 14.9964L19.813 20L14.8093 25.0036C14.7182 25.0948 14.667 25.2184 14.667 25.3473C14.667 25.4762 14.7182 25.5998 14.8093 25.691C14.9005 25.7821 15.0241 25.8333 15.153 25.8333C15.2819 25.8333 15.4055 25.7821 15.4967 25.691L20.5003 20.6874L25.504 25.691C25.5951 25.7821 25.7187 25.8333 25.8476 25.8333C25.9765 25.8333 26.1002 25.7821 26.1913 25.691C26.2825 25.5998 26.3337 25.4762 26.3337 25.3473C26.3337 25.2184 26.2825 25.0948 26.1913 25.0036L21.1877 20L26.1913 14.9964C26.2825 14.9052 26.3337 14.7816 26.3337 14.6527C26.3337 14.5238 26.2825 14.4002 26.1913 14.309Z"
                          fill="white"
                        />
                        <path
                          d="M26.1913 14.309C26.1002 14.2179 25.9765 14.1667 25.8476 14.1667C25.7187 14.1667 25.5951 14.2179 25.504 14.309L20.5003 19.3126L15.4967 14.309C15.4055 14.2179 15.2819 14.1667 15.153 14.1667C15.0241 14.1667 14.9005 14.2179 14.8093 14.309C14.7182 14.4002 14.667 14.5238 14.667 14.6527C14.667 14.7816 14.7182 14.9052 14.8093 14.9964L19.813 20L14.8093 25.0036C14.7182 25.0948 14.667 25.2184 14.667 25.3473C14.667 25.4762 14.7182 25.5998 14.8093 25.691C14.9005 25.7821 15.0241 25.8333 15.153 25.8333C15.2819 25.8333 15.4055 25.7821 15.4967 25.691L20.5003 20.6874L25.504 25.691C25.5951 25.7821 25.7187 25.8333 25.8476 25.8333C25.9765 25.8333 26.1002 25.7821 26.1913 25.691C26.2825 25.5998 26.3337 25.4762 26.3337 25.3473C26.3337 25.2184 26.2825 25.0948 26.1913 25.0036L21.1877 20L26.1913 14.9964C26.2825 14.9052 26.3337 14.7816 26.3337 14.6527C26.3337 14.5238 26.2825 14.4002 26.1913 14.309Z"
                          fill="white"
                        />
                      </g>
                    </g>
                    <defs>
                      <clipPath id="clip0_3770_35008">
                        <rect width={40} height={40} fill="white" />
                      </clipPath>
                      <clipPath id="clip1_3770_35008">
                        <rect width={14} height={14} fill="white" transform="translate(13.5 13)" />
                      </clipPath>
                    </defs>
                  </svg>
                )}
              </div>
              <div className="section__heading partners-page__part-title">{text}</div>
              <div className="partners-page__part-btn">
                <Button text={'Ок'} onClick={() => navigate(-1)} />
              </div>
              {description && <div className="partners-page__part-caption">{description}</div>}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ResponsePage;
