import './ChangeTariffTubs.scss';

const tubsList = [
  {
    title: 'Выбор тарифа',
    step: 1,
  },
  {
    title: 'Дата изменения',
    step: 2,
  },
  {
    title: 'Информация о заявке',
    step: 3,
  },
];

const ChangeTariffTubs = ({ step }) => {
  return (
    <div className="services-page__change-list">
      {tubsList.map((item, index) => (
        <div
          className={`services-page__change-item ${
            item.step < step ? 'active' : item.step === step ? '' : 'locked'
          } `}
          key={index}
        >
          <div className="services-page__change-num">
            {item.step}
            <svg
              className="services-page__change-svg"
              width={18}
              height={12}
              viewBox="0 0 18 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.10556 9.92108L1.85234 5.84758C1.64882 5.65271 1.37283 5.54325 1.08505 5.54325C0.797271 5.54325 0.521276 5.65271 0.317755 5.84758C0.114297 6.0425 0 6.30683 0 6.58245C0 6.85807 0.114297 7.1224 0.317755 7.31732L4.57097 11.3908C4.77254 11.584 5.01186 11.7372 5.27526 11.8417C5.53866 11.9462 5.82098 12 6.1061 12C6.39122 12 6.67354 11.9462 6.93694 11.8417C7.20034 11.7372 7.43966 11.584 7.64123 11.3908L17.6822 1.77407C17.8857 1.57915 18 1.31482 18 1.0392C18 0.763584 17.8857 0.49925 17.6822 0.304329C17.4787 0.109467 17.2027 0 16.915 0C16.6272 0 16.3512 0.109467 16.1477 0.304329L6.10556 9.92108Z"
                fill="white"
              />
            </svg>
          </div>
          <div className="services-page__change-text">{item.title}</div>
        </div>
      ))}
    </div>
  );
};

export default ChangeTariffTubs;
