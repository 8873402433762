//@ts-nocheck
//FIXME:

import { format } from 'date-fns';
import { FC, useEffect, useState } from 'react';
import { API_DOMAIN } from 'src/api/service/endpoints';
import $api from 'src/api/service/request';
import CalendarUi from 'src/components/ui/calendar/CalenedarUi';
import Loader from 'src/components/ui/loader/Loader';
import './ServiceHistoryTariff.scss';
import { AxiosResponse } from 'axios';
import { getFromLocalStorage } from 'src/api/service/helpers/localStorage.helper';
import { PlanHistory, ResponsePlansChangeList } from 'src/types/swagger';
import { Link } from 'react-router-dom';
import Panel from 'src/components/common/panel/Panel';
import { SERVICES, SERVICESTABAVAILABLE, SERVICESTABHISTORY } from 'src/routes/data';
import useModal from 'src/hooks/useModal';
import ServicesTabs from '../components/ServicesTabs';

// TODO: получить даты как в детализации звонков от пакта
//TODO: пакт не фильтрует

const ServiceTabHistory: FC = () => {
  const [historyTariffList, setHistoryTariffList] = useState<PlanHistory[] | []>([]);
  const [isLoading, setIsLoading] = useState(true);
  const { open } = useModal();

  //календарь
  const [date, setDate] = useState<Date[] | null>(null);
  const [dateError, setDateError] = useState<boolean>(false);
  const [startDate, setStartDate] = useState<string | null>(null);
  const [endDate, setEndDate] = useState<string | null>(null);
  //календарь

  //Запрос данных
  const fetchData = async () => {
    setIsLoading(true);
    try {
      const response: AxiosResponse<ResponsePlansChangeList> = await $api.get(
        `${API_DOMAIN}clients/${getFromLocalStorage('agreement')}/services/history/${
          date ? `?start_date=${startDate}&` + `end_date=${endDate}` : ''
        }`
      );

      const { data } = response.data;

      if (data) {
        const { plan_changes } = data;
        setHistoryTariffList(plan_changes);

        //FIXME: раскомментировать после того как будет готова бекенд часть
        //установка значения периода при загурзке

        // const startDate = data.start_date
        //   ? parse(data.start_date, 'dd.MM.yyyy', new Date())
        //   : new Date();
        // const endDate = data.end_date ? parse(data.end_date, 'dd.MM.yyyy', new Date()) : new Date();
        // setDate([startDate, endDate]);
        //установка значения периода при загурзке
      }
    } catch (error: any) {
      console.error('Failed to fetch client:', error.message);
    } finally {
      setIsLoading(false);
    }
  };
  //Запрос данных

  //Колбек для запроса данных после календаря
  const handleShow = () => {
    const start_date = date?.[0] ? format(date[0], 'yyyy-MM-dd') : null;
    const end_date = date?.[1] ? format(date[1], 'yyyy-MM-dd') : null;
    setStartDate(start_date);
    setEndDate(end_date);
    //Валидация календаря
    setDateError(!startDate);
    if (!startDate || !endDate) return;
    //Валидация календаря
    fetchData();
  };
  //Колбек для запроса данных после календаря

  //Получение данных при загрузке
  useEffect(() => {
    fetchData();
  }, []);
  //Получение данных при загрузке

  //Лоадер
  if (isLoading) {
    return <Loader state={isLoading} />;
  }
  //Лоадер

  return historyTariffList && historyTariffList.length > 0 ? (
    <div className="services-page">
      <Panel text="Услуги" />
      <section className="section section--first-panel r-section-first">
        <div className="container">
          <ServicesTabs />
          <div className="services-page__inner">
            <div className="services-page__block active">
              <div className="services-page__history">
                <h3 className="section__title">История смены тарифов</h3>
                <div className="services-page__history-calendar">
                  <CalendarUi
                    value={date}
                    setValue={setDate}
                    isRange={true}
                    svg={true}
                    onClick={handleShow}
                    maxDate={new Date()}
                    isError={dateError}
                  />
                </div>
                <div className="only-desktop">
                  <div className="services-page__history-grid">
                    <div className="services-page__history-label">Дата подключения</div>
                    <div className="services-page__history-label">Категория</div>
                    <div className="services-page__history-label">Тариф</div>
                    <div className="services-page__history-label">Дата смены тарифа</div>
                  </div>
                </div>
                {historyTariffList.map((item, index) => (
                  <div className="services-page__history-line" key={index}>
                    <div className="services-page__history-grid">
                      <div className="services-page__history-item">
                        <div className="only-mobile">
                          <div className="services-page__history-label">Дата подключения</div>
                        </div>
                        <div className="services-page__history-text">{item.connection_date}</div>
                      </div>
                      <div className="services-page__history-item">
                        <div className="only-mobile">
                          <div className="services-page__history-label">Категория</div>
                        </div>
                        <div className="services-page__history-text">{item.service_type}</div>
                      </div>
                      <div className="services-page__history-item">
                        <div className="only-mobile">
                          <div className="services-page__history-label">Тариф</div>
                        </div>
                        <div className="services-page__history-text">{item.plan}</div>
                      </div>
                      <div className="services-page__history-item">
                        <div className="only-mobile">
                          <div className="services-page__history-label">Дата смены тарифа</div>
                        </div>
                        <div className="services-page__history-text">
                          {format(item.plan_change_date, 'dd-MM-yyyy')}
                        </div>
                      </div>
                    </div>

                    <div className="services-page__history-mobile">
                      <div className="services-page__history-inner">
                        <div className="services-page__history-text">Интернет</div>
                      </div>
                      <div className="services-page__history-inner">
                        <div className="services-page__history-text">Базовый пакет</div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  ) : null;
};

export default ServiceTabHistory;
