import Button from 'src/components/ui/button/Button';
import Panel from '../../../panel/Panel';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { FC, useEffect, useState } from 'react';
import $api from 'src/api/service/request';
import { API_DOMAIN } from 'src/api/service/endpoints';
import { CONDITIONSPAGE, SERVICES } from 'src/routes/data';
import { AxiosResponse } from 'axios';
import TooltipUi from 'src/components/ui/tooltip/TooltipUi';
import CalendarUi from 'src/components/ui/calendar/CalenedarUi';
import { formatDate } from 'src/utils.js/formatDate';
import './ServiceBlock.scss';
import { BlockServiceGet, BlockServiceRequest, ResponseBlockServiceGet } from 'src/types/swagger';
import Loader from 'src/components/ui/loader/Loader';
import { IModalData } from 'src/redux/slices/modalSlice';
import ResponsePage from 'src/components/ui/responsePage/ResponsePage';
import ConditionUi from 'src/components/ui/conditionUI/ConditionUi';

const ServiceBlock: FC<ResponseBlockServiceGet> = () => {
  const { service_cid } = useParams();
  const navigation = useNavigate();
  const [data, setData] = useState<BlockServiceGet | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  const [modalData, setModalData] = useState<IModalData | null>(null);

  //календари
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [startDateError, setStartDateError] = useState<boolean>(false);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [endDateError, setEndStartDateError] = useState<boolean>(false);
  const [minDate, setMinDate] = useState<Date | null>(null);
  const [maxDate, setMaxDate] = useState<Date | null>(null);
  //календари

  useEffect(() => {
    if (!endDate || !minDate) return;
    if (endDate < minDate) {
      setEndDate(minDate);
    }
  }, [minDate, startDate]);

  //Первичные данные
  useEffect(() => {
    setIsLoading(true);
    const fetchData = async () => {
      try {
        const response: AxiosResponse<ResponseBlockServiceGet> = await $api.get(
          `${API_DOMAIN}services/${service_cid}/block/`
        );

        const { data } = response.data;
        if (data) {
          setData(data);
        }
      } catch (error: any) {
        console.error('Failed to fetch video:', error.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);
  //Первичные данные

  const handleModal = (data: IModalData) => {
    setModalData(data);
  };

  //Отправка Заявления на Приостановку
  const onSubmit = () => {
    //Валидация календарей
    setStartDateError(!startDate);
    setEndStartDateError(!endDate);
    if (!startDate || !endDate) return;
    //Валидация календарей

    const requestBody: BlockServiceRequest = {
      connection_address: data!.connection_address,
      full_name: data!.full_name!,
      contact_phone: data!.contact_phone,
      start_block_date: formatDate(startDate, 'send')!,
      end_block_date: formatDate(endDate, 'send')!,
    };

    //Отправка заявления на приостановку
    const fetchData = async () => {
      setIsLoading(true);

      try {
        const response = await $api.put(`${API_DOMAIN}services/${service_cid}/block/`, requestBody);
        const { data } = response;

        handleModal({
          text: data?.data?.title || data?.client_message || 'Изменения сохранены',
          modalType: 'success',
          isReload: true,
          description: data?.data?.description || '',
        });
      } catch (error: any) {
        handleModal({
          text: error.response?.data?.data?.title || error.response.data.client_message || 'Ошибка',
          description: error.response?.data?.data?.description || '',
          modalType: 'error',
        });
      } finally {
        setIsLoading(false);
      }
    };
    //Отправка заявления на приостановку

    fetchData();
  };
  //Отправка Заявления на Приостановку

  useEffect(() => {
    if (startDate && data) {
      const date = new Date(startDate);
      const minDate = new Date(date);
      minDate.setDate(minDate.getDate() + 1);

      const maxDate = new Date(date);
      maxDate.setDate(maxDate.getDate() + (data?.days_limit || 30));

      setMinDate(minDate);
      setMaxDate(maxDate);
    }
  }, [startDate, data]);

  //Окно об успешной приостановке
  if (modalData) {
    return <ResponsePage data={modalData} />;
  }
  //Окно об успешной приостановке

  return (
    <>
      {data && !modalData && (
        <>
          <div className="services-page connected-page connected-page--default">
            <Panel text="Заявление на приостановку" linkText="Услуги" onBack={true} />
            <section className="section section--first-panel">
              <div className="container">
                <form className="section__container" onSubmit={onSubmit}>
                  <div className="services-page__delay">
                    <h1 className="section__title">Заявление на&nbsp;приостановку</h1>
                    <div className="connected-page__inner">
                      <div className="connected-page__accordion-heading">Данные абонента</div>
                      <div className="connected-page__inner">
                        <div className="services-page__delay-wrapper">
                          <div className="services-page__delay-inner">
                            <div className="services-page__delay-item">
                              <div className="services-page__card-label">Услуга</div>
                              <div className="connected-page__text">{data.service_title}</div>
                            </div>
                          </div>
                          <div className="services-page__delay-inner connected-page__inner">
                            <div className="services-page__delay-item">
                              <div className="services-page__card-label">ФИО</div>
                              <div className="connected-page__text">{data.full_name}</div>
                            </div>
                            <div className="services-page__delay-item">
                              <div className="services-page__card-label">Номер договора</div>
                              <div className="connected-page__text">{data.agreement}</div>
                            </div>
                            <div className="services-page__delay-item">
                              <div className="services-page__card-label">Номер телефона</div>
                              <div className="connected-page__text">{data.contact_phone}</div>
                            </div>
                            <div className="services-page__delay-item">
                              <div className="services-page__card-label">Адрес подключения</div>
                              <div className="connected-page__text">{data.connection_address}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="connected-page__inner">
                      <div className="connected-page__accordion-heading">
                        Выберите дату приостановки услуг
                      </div>
                      <div className="services-page__inner">
                        <div className="services-page__delay-wrapper">
                          <div className="services-page__delay-calendars">
                            <div className="services-page__delay-container">
                              <div className="services-page__delay-tooltip react-gap-6 tooltip tooltip--alt">
                                <div className="section__label default">Дата запуска</div>
                                {data.tooltip_start_date && (
                                  <TooltipUi text={data.tooltip_start_date} />
                                )}

                                <div className="tooltip__container">
                                  Число месяца, при наступлении которого счёт будет автоматически
                                  пополняться
                                </div>
                              </div>
                              <div className="services-page__delay-calendar">
                                <CalendarUi
                                  isError={startDateError}
                                  value={startDate}
                                  setValue={setStartDate}
                                  svg={true}
                                  isButtonVisible={false}
                                  minDate={new Date()}
                                />
                              </div>
                            </div>
                            <div className="services-page__delay-container">
                              <div className="services-page__delay-tooltip tooltip tooltip--alt react-gap-6">
                                <div className="section__label default">Дата окончания</div>
                                {data.tooltip_end_date && (
                                  <TooltipUi text={data.tooltip_end_date} />
                                )}

                                <div className="tooltip__container">
                                  Число месяца, при наступлении которого счёт будет автоматически
                                  пополняться
                                </div>
                              </div>
                              <div className="services-page__delay-calendar">
                                <CalendarUi
                                  value={endDate}
                                  setValue={setEndDate}
                                  svg={true}
                                  isButtonVisible={false}
                                  isError={endDateError}
                                  minDate={minDate || undefined}
                                  maxDate={maxDate || undefined}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="services-page__delay-bot">
                        <div className="services-page__delay-btn">
                          <Button
                            text="Отменить"
                            theme="outline"
                            size="small"
                            onClick={() => navigation(-1)}
                          />
                        </div>
                        <div className="services-page__delay-btn">
                          <Button text="Отправить" size="small" onClick={onSubmit} />
                        </div>
                      </div>

                      {/* //ссылка на условия  */}
                      <ConditionUi data={data.condition_block} />
                      {/* //ссылка на условия  */}
                    </div>
                  </div>
                </form>
              </div>
            </section>
          </div>
        </>
      )}
      <Loader state={isLoading} />;
    </>
  );
};

export default ServiceBlock;
