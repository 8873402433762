import { AxiosResponse } from 'axios';
import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { API_DOMAIN } from 'src/api/service/endpoints';
import $api from 'src/api/service/request';
import Categories, { ICategory } from 'src/components/ui/categories/Categories';
import Loader from 'src/components/ui/loader/Loader';
import { AvailablePlans, MainExtraOptions, ResponseMainExtraOptions } from 'src/types/swagger';
import SpecialOffer from '../components/specialOffer/SpecialOffer';
import AvailableOptionsCard from './components/AvailableOptionsCard';

interface IProps {
  service_cid: string;
}
const ServiceAvailableOptions: FC<IProps> = ({ service_cid }) => {
  const params = useParams();

  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState<MainExtraOptions>();

  const [categories, setCategories] = useState<ICategory[]>();
  const [currentCategory, setCurrentCategory] = useState<ICategory>();
  const [sortedData, setSortedData] = useState<AvailablePlans[]>();

  //Первичные данные
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response: AxiosResponse<ResponseMainExtraOptions> = await $api.get(
          `${API_DOMAIN}services/${service_cid || params.service_cid}/extra_options/`
        );

        const { data } = response.data;
        if (data) {
          setData(data);
        }
      } catch (error: any) {
        console.error('Failed to fetch TelephonyAvailable:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);
  //Первичные данные

  //Получаем список категорий
  useEffect(() => {
    if (data) {
      const uniqueCategories: { [key: string]: boolean } = {};
      const arr: ICategory[] = [];
      let allItems = 0;

      for (const extra in data.extra_plans) {
        for (const category in data.extra_plans[extra]) {
          if (data.extra_plans[extra].hasOwnProperty(category)) {
            const categoryCode = data.extra_plans[extra].category_code;
            if (!uniqueCategories[categoryCode]) {
              uniqueCategories[categoryCode] = true;
              allItems += data.extra_plans[extra].items?.length ?? 0;

              arr.push({
                category_code: categoryCode,
                category_name: data.extra_plans[extra].category_name,
                quantity: data.extra_plans[extra].items?.length || 0,
              });
            }
          }
        }
      }

      //Получаем количество всех доступных планов

      const allItemsCategory = {
        category_code: '',
        category_name: 'Все',
        quantity: allItems,
      };
      arr.unshift(allItemsCategory);
      //Получаем количество всех доступных планов

      setCategories(arr);
      setCurrentCategory(allItemsCategory);
    }
  }, [data]);
  //Получаем список категорий

  // Сортировка и фильтрация
  useEffect(() => {
    if (data && data.extra_plans) {
      const sortedData = Object.values(data.extra_plans)
        .sort((a, b) => {
          return a.display_order - b.display_order;
        })
        .filter((item) => {
          if (currentCategory && currentCategory.category_code) {
            return item.category_code === currentCategory.category_code;
          } else {
            return true;
          }
        });

      setSortedData(sortedData);
    }
  }, [data, currentCategory]);

  //Сортировка и фильтрация

  //колбек для категорий
  const categoryCallback = (category: ICategory) => {
    setCurrentCategory(category);
  };
  //колбек для категорий

  //Лоадер
  if (isLoading) {
    return <Loader state={isLoading} />;
  }
  //Лоадер

  return data ? (
    <div className="services-page__inner">
      <div className="services-page__block active">
        {categories && categories.length > 1 && (
          <Categories
            data={categories}
            callback={categoryCallback}
            currentCategory={currentCategory!}
          />
        )}
        {/* //Специальное предложение */}
        {data.spec_offers && <SpecialOffer data={data.spec_offers} />}
        {/* //Специальное предложение */}

        {sortedData &&
          sortedData.map((item) => (
            <div className="services-page__list" key={item.category_name}>
              <div className="services-page__item">
                <div className="services-page__item-heading">{item.category_name}</div>
                <div className="services-page__item-grid">
                  {item.items &&
                    item.items.map((card) => (
                      <AvailableOptionsCard
                        variant="options"
                        key={card.plan_id}
                        data={card}
                        service_cid={service_cid}
                        category_code={item.category_code}
                        // service_title={data.service_title}
                      />
                    ))}
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  ) : null;
};

export default ServiceAvailableOptions;
