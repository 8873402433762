export function setToSessionStorage(key: string, value: any) {
  window.sessionStorage.setItem(key, JSON.stringify(value));
}

export function getFromSessionStorage(key: string) {
  const value = window.sessionStorage.getItem(key);
  return value ? JSON.parse(value) : null;
}

export function removeFromSessionStorage(key: string) {
  window.sessionStorage.removeItem(key);
}
