import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { API_DOMAIN } from 'src/api/service/endpoints';
import $api from 'src/api/service/request';
import { ResponseServiceCondition, ServiceCondition } from 'src/types/swagger';

interface IConditionModalStore {
  isOpen: boolean;
  loading: boolean;
  conditionData: IConditionModalData;
}

export interface IConditionModalData {
  condition: any;
}

export const asyncCondition = createAsyncThunk<
  ServiceCondition,
  { id: string },
  { rejectValue: string }
>('promotions/service_conditions', async ({ id }, { rejectWithValue }) => {
  try {
    const response: AxiosResponse<ResponseServiceCondition> = await $api.get(
      `${API_DOMAIN}promotions/service_conditions/${id}/`
    );

    return response.data.data;
  } catch (error: any) {
    return rejectWithValue(error.message || 'Failed to fetch conditions');
  }
});

const initialState: IConditionModalStore = {
  isOpen: false,
  loading: false,
  conditionData: {
    condition: {},
  },
};

const conditionSlice = createSlice({
  name: 'conditionSlice',
  initialState,
  reducers: {
    openModal: (state, action: PayloadAction) => {
      state.isOpen = true;
    },
    closeConditionModal: (state) => {
      state.isOpen = false;
      state.conditionData.condition = null;
      state.loading = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(asyncCondition.pending, (state) => {
        state.loading = true;
        state.isOpen = true;
      })
      .addCase(asyncCondition.fulfilled, (state, action) => {
        state.conditionData.condition = action.payload;
        state.loading = false;
      })
      .addCase(asyncCondition.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const { openModal, closeConditionModal } = conditionSlice.actions;

export default conditionSlice.reducer;
