import * as path from './data';
import { Login } from '../pages/auth/Login';
import PartnersPage from 'src/pages/partners/PartnersPage';
import PartnersFormPage from 'src/pages/partners/partnersForm/PartnersFormPage';
import InfoPage from 'src/pages/info/InfoPage';
import CostsControlPage from 'src/pages/costsControl/CostsControlPage';
import InternetSettingsPage from 'src/pages/services/internet/settings/InternetSettingsPage';
import HomePage from 'src/pages/home/HomePage';
import TvPage from 'src/pages/services/tv/TvPage';
import TvAvailablePage from 'src/pages/available/AvailablePage';
import TelephonySettingsPage from 'src/pages/services/telephony/settings/TelephonySettingsPage';
import VideoSettingsPage from 'src/pages/services/video/settings/VideoSettingsPage';
import NewsPage from 'src/pages/news/NewsPage';
import NewsDetailPage from 'src/pages/news/detail/NewsDetailPage';
import NotificationPage from 'src/pages/notification/NotificationPage';
import PaymentPage from 'src/pages/payment/PaymentPage';
import ProfilePage from 'src/pages/profile/ProfilePage';
import ProfileSubscriptionsPage from 'src/pages/profile/subscriptions/ProfileSubscriptionsPage';
import ProfileDocumentsPage from 'src/pages/profile/documents/ProfileDocumentsPage';
import UdomofonSettingsPage from 'src/pages/services/udomofon/UdomofonSettingsPage';
import ConditionsPage from 'src/pages/conditions/ConditionsPage';
import ServicePausePage from 'src/pages/services/pause/ServicePausePage';
import PhoneDetail from 'src/components/common/phoneDetail/PhoneDetail';
import RadioSettingsPage from 'src/pages/services/radio/RadioSettingsPage';
import ServiceTariffPage from 'src/pages/services/internet/tariff/ServiceTariffPage';
import ServiceUnblockPage from 'src/pages/services/unblock/ServiceUnblockPage';
import PaymentErrorPage from 'src/pages/payment/paymentError/PaymentErrorPage';
import ServiceConnectPage from 'src/pages/services/serviceConnectPage/ServiceConnectPage';
import TvExpensesPage from 'src/pages/services/telephony/expenses/TvExpensesPage';
import ServiceAvailableOptionsPage from 'src/pages/services/availableOptions/ServiceAvailableOptionsPage';
import EditAutoPaymentPage from 'src/pages/payment/editAutoPayment/EditAutoPaymentPage';
import AutoPaymentPage from 'src/pages/payment/autoPaymentPage/AutoPaymentPage';
import ServiceTabConnectedPage from 'src/pages/services/tabs/ServiceTabConnectedPage';
import ServiceTabAvailablePage from 'src/pages/services/tabs/ServiceTabAvailablePage';
import ServiceTabHistoryPage from 'src/pages/services/tabs/ServiceTabHistoryPage';
import AvailableNewPlanPage from 'src/pages/services/availableNewPlan/AvailableNewPlanPage';
import ServiceTabSpecPage from 'src/pages/services/tabs/ServiceTabSpecPage';

export const openRoutes = [
  {
    path: path.LOGIN,
    element: <Login />,
  },
];

export const routes = [
  {
    path: path.HOME,
    element: <HomePage />,
  },
  {
    path: path.NEWS,
    element: <NewsPage />,
  },
  {
    path: path.NEWSDETAIL,
    element: <NewsDetailPage />,
  },
  {
    path: path.PARTNERS,
    element: <PartnersPage />,
  },
  {
    path: path.PARTNERSFORM,
    element: <PartnersFormPage />,
  },
  {
    path: path.INFO,
    element: <InfoPage />,
  },
  {
    path: path.COSTCONTROL,
    element: <CostsControlPage />,
  },

  //Services
  {
    path: path.SERVICE_SPEC,
    element: <ServiceTabSpecPage />,
  },
  {
    path: path.SERVICES,
    element: <ServiceTabConnectedPage />,
  },
  {
    path: path.SERVICESTABHISTORY,
    element: <ServiceTabHistoryPage />,
  },
  {
    path: path.SERVICESTABAVAILABLE,
    element: <ServiceTabAvailablePage />,
  },
  {
    path: path.SERVICESPAUSE + '/:service_cid',
    element: <ServicePausePage />,
  },
  {
    path: path.SERVICEUNBLOCK + '/:service_cid',
    element: <ServiceUnblockPage />,
  },
  {
    path: path.SERVICEAVAILABLENEWPLAN + '/:service_type',
    element: <AvailableNewPlanPage />,
  },
  {
    path: path.SERVICECONNECTNEW + '/:variant/:service_cid/:id/:category_code?',
    element: <ServiceConnectPage />,
  },
  {
    path: path.SERVICEAVAILABLEOPTIONS + '/:service_cid',
    element: <ServiceAvailableOptionsPage />,
  },
  {
    path: path.SERVICEAVAILABLE + '/:service_type',
    element: <TvAvailablePage />,
  },

  //services/internet

  {
    path: path.INTERNETSETTINGS + '/:id/:type',
    element: <InternetSettingsPage />,
  },
  {
    path: path.SERVICECHANGETARIFF + '/:id',
    element: <ServiceTariffPage />,
  },

  //services/tv
  {
    path: path.TVSETTINGS + '/:id',
    element: <TvPage />,
  },
  {
    path: path.TVEXPENSES + '/:service_agr',
    element: <TvExpensesPage />,
  },

  //services/telephony
  {
    path: path.TELEPHONYSETTINGS + '/:id',
    element: <TelephonySettingsPage />,
  },

  //services/video
  {
    path: path.VIDEOSETTINGS + '/:id',
    element: <VideoSettingsPage />,
  },
  //services/video
  {
    path: path.NOTIFICATION,
    element: <NotificationPage />,
  },

  //payment
  {
    path: path.PAYMENT + '/:tab',
    element: <PaymentPage />,
  },
  {
    path: path.PAYMENTCONNECTAUTO,
    element: <AutoPaymentPage />,
  },
  {
    path: path.PAYMENTEDITAUTO + '/:id',
    element: <EditAutoPaymentPage />,
  },

  {
    path: path.PAYMENTCLOSE,
    element: <PaymentErrorPage />,
  },

  //profile
  {
    path: path.PROFILE,
    element: <ProfilePage />,
  },
  {
    path: path.PROFILESUBSCRIPTIONSPAGE,
    element: <ProfileSubscriptionsPage />,
  },
  {
    path: path.PROFILEDOCUMENTS,
    element: <ProfileDocumentsPage />,
  },

  //udomofon
  {
    path: path.UDOMOFONSETTINGS + '/:id/:type',
    element: <UdomofonSettingsPage />,
  },

  //conditionsPage

  {
    path: path.CONDITIONSPAGE + '/:id',
    element: <ConditionsPage />,
  },

  //phoneDetail
  {
    path: path.PHONEDETAIL,
    element: <PhoneDetail />,
  },
  //radio
  {
    path: path.RADIOSETTINGS + '/:id',
    element: <RadioSettingsPage />,
  },
];
