import { FC, ReactNode, useEffect, useRef, useState } from 'react';
import './Modal.scss';
import { CSSTransition } from 'react-transition-group';
import { scrollBlock, scrollUnblock } from 'src/utils.js/scrollBlock';

interface IProps {
  state: boolean;
  setState: (prev: any) => void;
  callback?: () => void;
  children: ReactNode;
  zIndex?: number;
}
const Modal: FC<IProps> = ({ state, setState, children, callback, zIndex }) => {
  const nodeRef = useRef(null);

  //обработчик клика по оверлею
  const handlerOverlayClick = () => {
    if (callback) {
      callback();
    } else {
      setState((prev: boolean) => !prev);
      scrollUnblock();
    }
  };
  //обработчик клика по оверлею

  //Отключение скролла при открытии модалки
  useEffect(() => {
    if (state) {
      scrollBlock();
    } else {
      scrollUnblock();
    }
  }, [state, setState]);
  //Отключение скролла при открытии модалки

  return (
    <CSSTransition
      nodeRef={nodeRef}
      classNames="active"
      in={state}
      mountOnEnter
      unmountOnExit
      timeout={600}
    >
      <div className="modal-wrapper" ref={nodeRef} style={{ zIndex: zIndex ? zIndex : 1000 }}>
        <div className="overlay" onClick={handlerOverlayClick}></div>
        <div className="content">{children}</div>
      </div>
    </CSSTransition>
  );
};

export default Modal;
