import Button from 'src/components/ui/button/Button';
import './ProfileSubscription.scss';
import Accordion from 'src/components/ui/accordion/Accordion';
import { FC, useEffect, useState } from 'react';
import {
  BaseResponse,
  Subscription,
  SubscriptionRequest,
  SubscriptionUpdateRequest,
} from 'src/types/swagger';
import $api from 'src/api/service/request';
import { API_DOMAIN } from 'src/api/service/endpoints';
import { getFromLocalStorage } from 'src/api/service/helpers/localStorage.helper';
import { AxiosResponse } from 'axios';
import useModal from 'src/hooks/useModal';

interface IProps {
  data: Subscription[];
  handleSubscriptionSetting: () => void;
}

const ProfileSubscriptionSetting: FC<IProps> = ({ data, handleSubscriptionSetting }) => {
  const [checkboxesState, setCheckboxesState] = useState<SubscriptionRequest[] | null>();
  const [isLoading, setIsLoading] = useState(false);
  const { open } = useModal();

  // колбек для чекбокса
  const handleCheckboxChange = (data: any) => {
    setCheckboxesState((prevCheckboxesState) => {
      const updatedCheckboxesState = prevCheckboxesState?.map((item) => {
        if (item.type_subscription === data.name) {
          return {
            ...item,
            action: data.checked ? 'subscribe' : 'unsubscribe',
          };
        }
        return item;
      });
      return updatedCheckboxesState;
    });
  };
  // колбек для чекбокса

  //Отправка формы
  const onSubmit = async () => {
    setIsLoading((prev) => !prev);
    const body: SubscriptionUpdateRequest = {
      updates: checkboxesState!,
    };

    try {
      const response: AxiosResponse<BaseResponse> = await $api.post(
        `${API_DOMAIN}clients/${getFromLocalStorage('agreement')}/profile/subscriptions`,
        body
      );
      const { data } = response;
      open({
        text: data?.data?.title || data?.client_message || 'Изменения сохранены',
        modalType: 'success',
        isReload: true,
        description: data?.data?.description || '',
      });
    } catch (error: any) {
      console.info('error', error);
      open({
        text: error.response?.data?.data?.title || error.response.data.client_message || 'Ошибка',
        description: error.response?.data?.data?.description || '',
        modalType: 'error',
      });
    } finally {
      setIsLoading(false);
    }
  };
  //Отправка формы

  //Добавление подписок в стейт при загрузке
  useEffect(() => {
    if (!data) return;
    const newCheckboxesState: SubscriptionRequest[] = data.map((item) => {
      return {
        type_subscription: item.sub_type,
        value: item.recipient || '',
        action: item.is_subscribed ? 'subscribe' : 'unsubscribe',
      };
    });

    setCheckboxesState(newCheckboxesState);
  }, []);
  //Добавление подписок в стейт при загрузке

  return (
    data && (
      <div className="profile-page__news-wrapper">
        {data.map((item) => (
          <Accordion
            key={item.sub_type}
            checkbox={{
              text: item.description,
              disabled: item.is_change_sub,
              defaultValue: item.is_subscribed,
              callback: handleCheckboxChange,
              name: item.sub_type,
            }}
          >
            <div className="profile-page__news-list">
              {item?.subscription_items?.map((sub) => (
                <div className="profile-page__news-item" key={sub}>
                  {sub}
                </div>
              ))}
            </div>
          </Accordion>
        ))}
        {/* //сохранить и отменить */}
        <div className="profile-page__news-bot">
          <div className="profile-page__news-btn">
            <Button text={'Сохранить'} size={'small'} onClick={onSubmit} isLoading={isLoading} />
          </div>
          <div className="profile-page__news-btn">
            <Button
              text={'Отменить'}
              onClick={handleSubscriptionSetting}
              size={'small'}
              theme={'outline'}
            />
          </div>
        </div>
        {/* //сохранить и отменить */}
      </div>
    )
  );
};

export default ProfileSubscriptionSetting;
