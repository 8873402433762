import { FC, useEffect } from 'react'

import { getFromLocalStorage } from 'src/api/service/helpers/localStorage.helper'
import { Header } from 'src/components/common/header/Header'
import { Sidebar } from 'src/components/common/sidebar/Sidebar'
import { DISABLE_BACKGROUND_NOTIFICATIONS, NOTIFICATION_POLLING_INTERVAL } from 'src/config'
import { useAppDispatch, useAppSelector } from 'src/hooks/redusHooks'
import { loggedIn } from 'src/redux/slices/appSlice'
import { asyncClient } from 'src/redux/slices/clientSlice'
import { asyncNotifications } from 'src/redux/slices/notificationsSlice'

import { AppRouter } from '../appRouter/AppRouter'

const BaseLayout: FC = () => {
	const isAuth = useAppSelector(state => state.app.app.isAuth)
	const { clientData, loading } = useAppSelector(state => state.client)
	const dispatch = useAppDispatch()

	useEffect(() => {
		if (!loading && !clientData && isAuth) {
			dispatch(asyncClient())
		}
	}, [isAuth, loading, clientData])

	//Фоновое обновление уведомлений
	useEffect(() => {
		if (!isAuth || DISABLE_BACKGROUND_NOTIFICATIONS) return

		const fetchNotifications = async () => {
			await dispatch(asyncNotifications())
		}

		const intervalId = setInterval(fetchNotifications, NOTIFICATION_POLLING_INTERVAL)

		// Очистка интервала при размонтировании компонента
		return () => clearInterval(intervalId)
	}, [isAuth])

	useEffect(() => {
		const token = getFromLocalStorage('token')
		const user = getFromLocalStorage('agreement')

		if (token && user) {
			dispatch(loggedIn())
		}
	}, [])

	return (
		<div className="wrapper">
			<div className="header-wrapper">
				<Header />
			</div>
			<div className="row">
				<div className="row__col">
					<Sidebar />
				</div>
				<div className="row__col">
					<div className="main">
						<AppRouter />
					</div>
				</div>
			</div>
		</div>
	)
}

export default BaseLayout
