import { useParams } from 'react-router-dom';
import Panel from 'src/components/common/panel/Panel';
import { SERVICES } from 'src/routes/data';
import { FC, useEffect, useState } from 'react';
import { API_DOMAIN } from 'src/api/service/endpoints';
import $api from 'src/api/service/request';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import Loader from 'src/components/ui/loader/Loader';

import { AxiosResponse } from 'axios';
import { ResponseServiceEthernet, ServiceEthernet, ServiceTypeEnum } from 'src/types/swagger';
import InternetEthernet from './connected/InternetEthernet';
import ServiceAvailableOptions from '../../availableOptions/ServiceAvailableOptions';

const InternetSettings: FC = () => {
  const { id, type } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState<ServiceEthernet | null>(null);

  //Первичные данные
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response: AxiosResponse<ResponseServiceEthernet> = await $api.get(
          `${API_DOMAIN}services/${
            type === ServiceTypeEnum.Docsis
              ? ServiceTypeEnum.Docsis
              : type === ServiceTypeEnum.Eth
              ? ServiceTypeEnum.Eth
              : ServiceTypeEnum.Pon
          }/${id}/`
        );
        const { data } = response.data;
        setData(data);
      } catch (error: any) {
        console.error('Failed to fetch client:', error.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);
  //Первичные данные

  //Лоадер
  if (isLoading) {
    return <Loader state={isLoading} />;
  }
  //Лоадер

  return (
    data && (
      <div className="services-page connected-page">
        <Panel text="Подключенные" linkText="Услуги" href={SERVICES} />
        <section className="section section--first-panel r-panel-tubs">
          <div className="container">
            <Tabs>
              <div className="main-page__services-top">
                <TabList>
                  <div className="sections">
                    <div className="sections__list">
                      <Tab className="sections__tab" selectedClassName="active">
                        Мои опции
                      </Tab>
                      <Tab className="sections__tab" selectedClassName="active">
                        Доступные опции
                      </Tab>
                    </div>
                  </div>
                </TabList>
              </div>
              <TabPanel>
                <InternetEthernet data={data as ServiceEthernet} />
              </TabPanel>
              <TabPanel>
                <ServiceAvailableOptions service_cid={data.service_cid} />
              </TabPanel>
            </Tabs>
          </div>
        </section>
      </div>
    )
  );
};

export default InternetSettings;
