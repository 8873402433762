import { FC } from 'react';
import Button from 'src/components/ui/button/Button';

interface IProps {
  handleCloseClick: () => void;
  variant: 'success' | 'error';
  text: string;
}

const FeedBackCallbackSuccess: FC<IProps> = ({ handleCloseClick, variant, text }) => {
  return (
    <div className="feedback-modal__block active">
      <div className="feedback-modal__call">
        <div className="feedback-modal__call-top r-feedback-modal__call-top">
          <div className="feedback-modal__call-resp active">
            <div className="reponse">
              <div className="response__icon">
                {variant === 'success' ? (
                  <svg
                    className="response__svg"
                    xmlns="http://www.w3.org/2000/svg"
                    width={41}
                    height={40}
                    viewBox="0 0 41 40"
                    fill="none"
                  >
                    <g clipPath="url(#clip0_3552_71092)">
                      <circle cx="20.5" cy={20} r={20} fill="#56BD68" />
                      <path
                        d="M17.6056 23.9211L13.3523 19.8476C13.1488 19.6527 12.8728 19.5432 12.585 19.5432C12.2973 19.5432 12.0213 19.6527 11.8178 19.8476C11.6143 20.0425 11.5 20.3068 11.5 20.5824C11.5 20.8581 11.6143 21.1224 11.8178 21.3173L16.071 25.3908C16.2725 25.584 16.5119 25.7372 16.7753 25.8417C17.0387 25.9462 17.321 26 17.6061 26C17.8912 26 18.1735 25.9462 18.4369 25.8417C18.7003 25.7372 18.9397 25.584 19.1412 25.3908L29.1822 15.7741C29.3857 15.5792 29.5 15.3148 29.5 15.0392C29.5 14.7636 29.3857 14.4992 29.1822 14.3043C28.9787 14.1095 28.7027 14 28.415 14C28.1272 14 27.8512 14.1095 27.6477 14.3043L17.6056 23.9211Z"
                        fill="white"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_3552_71092">
                        <rect width={40} height={40} fill="white" transform="translate(0.5)" />
                      </clipPath>
                    </defs>
                  </svg>
                ) : (
                  <svg
                    width={40}
                    height={40}
                    viewBox="0 0 40 40"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clipPath="url(#clip0_3770_35008)">
                      <circle cx={20} cy={20} r={20} fill="#EC003E" />
                      <g clipPath="url(#clip1_3770_35008)">
                        <path
                          d="M26.1913 14.309C26.1002 14.2179 25.9765 14.1667 25.8476 14.1667C25.7187 14.1667 25.5951 14.2179 25.504 14.309L20.5003 19.3126L15.4967 14.309C15.4055 14.2179 15.2819 14.1667 15.153 14.1667C15.0241 14.1667 14.9005 14.2179 14.8093 14.309C14.7182 14.4002 14.667 14.5238 14.667 14.6527C14.667 14.7816 14.7182 14.9052 14.8093 14.9964L19.813 20L14.8093 25.0036C14.7182 25.0948 14.667 25.2184 14.667 25.3473C14.667 25.4762 14.7182 25.5998 14.8093 25.691C14.9005 25.7821 15.0241 25.8333 15.153 25.8333C15.2819 25.8333 15.4055 25.7821 15.4967 25.691L20.5003 20.6874L25.504 25.691C25.5951 25.7821 25.7187 25.8333 25.8476 25.8333C25.9765 25.8333 26.1002 25.7821 26.1913 25.691C26.2825 25.5998 26.3337 25.4762 26.3337 25.3473C26.3337 25.2184 26.2825 25.0948 26.1913 25.0036L21.1877 20L26.1913 14.9964C26.2825 14.9052 26.3337 14.7816 26.3337 14.6527C26.3337 14.5238 26.2825 14.4002 26.1913 14.309Z"
                          fill="white"
                        />
                        <path
                          d="M26.1913 14.309C26.1002 14.2179 25.9765 14.1667 25.8476 14.1667C25.7187 14.1667 25.5951 14.2179 25.504 14.309L20.5003 19.3126L15.4967 14.309C15.4055 14.2179 15.2819 14.1667 15.153 14.1667C15.0241 14.1667 14.9005 14.2179 14.8093 14.309C14.7182 14.4002 14.667 14.5238 14.667 14.6527C14.667 14.7816 14.7182 14.9052 14.8093 14.9964L19.813 20L14.8093 25.0036C14.7182 25.0948 14.667 25.2184 14.667 25.3473C14.667 25.4762 14.7182 25.5998 14.8093 25.691C14.9005 25.7821 15.0241 25.8333 15.153 25.8333C15.2819 25.8333 15.4055 25.7821 15.4967 25.691L20.5003 20.6874L25.504 25.691C25.5951 25.7821 25.7187 25.8333 25.8476 25.8333C25.9765 25.8333 26.1002 25.7821 26.1913 25.691C26.2825 25.5998 26.3337 25.4762 26.3337 25.3473C26.3337 25.2184 26.2825 25.0948 26.1913 25.0036L21.1877 20L26.1913 14.9964C26.2825 14.9052 26.3337 14.7816 26.3337 14.6527C26.3337 14.5238 26.2825 14.4002 26.1913 14.309Z"
                          fill="white"
                        />
                      </g>
                    </g>
                    <defs>
                      <clipPath id="clip0_3770_35008">
                        <rect width={40} height={40} fill="white" />
                      </clipPath>
                      <clipPath id="clip1_3770_35008">
                        <rect width={14} height={14} fill="white" transform="translate(13.5 13)" />
                      </clipPath>
                    </defs>
                  </svg>
                )}
              </div>
              <div className="response__text">{text}</div>
              <div className="response__btn">
                <Button text={'Ok'} onClick={handleCloseClick} />
              </div>
            </div>
          </div>
        </div>

        <div className="feedback-modal__call-bot">
          <div className="feedback-modal__call-item">
            Контактый центр: <a href="#">8 (812) 595-81-21</a>
          </div>
          <div className="feedback-modal__call-item">
            Техническая поддержка: <a href="#">8 (812) 595-81-22</a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeedBackCallbackSuccess;
