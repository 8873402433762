import { FC } from 'react';
import Button from 'src/components/ui/button/Button';
import ConditionUi from 'src/components/ui/conditionUI/ConditionUi';
import { PAYMENTCONNECTAUTO } from 'src/routes/data';
import { Condition } from 'src/types/Condition';

interface IProps {
  title: string | null;
  description: string | null;
  condition_block: Condition | null;
}
const AutoPaymentEmpty: FC<IProps> = ({ title, description, condition_block }) => {
  return (
    <div className="payment-page__autopay">
      <div className="payment-page__autopay-container">
        <div className="payment-page__autopay-icon">
          <svg
            className="payment-page__autopay-svg"
            width={40}
            height={40}
            viewBox="0 0 40 40"
            fill="none"
          >
            <path
              d="M32.1675 7.9698C35.0688 11.3062 36.6667 13.9119 36.6667 18.3333H33.3333C33.3333 14.7962 31.7217 13.0449 29.4007 10.3759C27.0796 7.70676 23.8722 5.96639 20.3693 5.47538C16.8664 4.98437 13.3041 5.7758 10.3387 7.70386C7.37321 9.63193 5.20444 12.5667 4.23193 15.9675C3.25942 19.3683 3.54868 23.006 5.04642 26.2104C6.54417 29.4148 9.14949 31.97 12.3824 33.4052C15.6153 34.8403 19.2579 35.0589 22.6392 34.0204C25.9094 33.0161 28.7221 30.9015 30.5951 28.0474L32.846 30.7485C30.5747 34.0843 27.6545 36.3158 23.7156 37.5255C19.489 38.8236 14.9357 38.5504 10.8946 36.7564C6.85352 34.9625 3.59687 31.7685 1.72469 27.763C-0.147487 23.7575 -0.509065 19.2104 0.706577 14.9594C1.92222 10.7084 4.63318 7.0399 8.33998 4.62982C12.0468 2.21974 16.4997 1.23045 20.8783 1.84421C25.2569 2.45798 29.2662 4.63344 32.1675 7.9698Z"
              fill="#0076D8"
            />
            <path d="M34.5556 26.6667L28.3333 18.3333H40L34.5556 26.6667Z" fill="#0076D8" />
            <path
              d="M15 15L25.5621 15C25.3761 13.8652 25.0956 12.7499 24.7242 11.6667H11.6667C10.7462 11.6667 10 12.4129 10 13.3333V25C10 25.9205 10.7462 26.6667 11.6667 26.6667H29.4444L22.5 18.3333H15C14.0795 18.3333 13.3333 17.5871 13.3333 16.6667C13.3333 15.7462 14.0795 15 15 15Z"
              fill="#0076D8"
            />
          </svg>
        </div>
        <h3 className="section__heading">{title}</h3>
        <div className="payment-page__autopay-text">{description}</div>
        <div className="payment-page__autopay-text">
          {/* //ссылка на условия  */}
          <ConditionUi data={condition_block} />
          {/* //ссылка на условия  */}
        </div>
        <div className="payment-page__autopay-btn">
          <Button text={'Подключить автоплатеж'} href={PAYMENTCONNECTAUTO} />
        </div>
      </div>
    </div>
  );
};

export default AutoPaymentEmpty;
