import { PROFILE } from 'src/routes/data';
import Panel from '../../panel/Panel';
import { useEffect, useState } from 'react';
import { ResponseSubscriptionManagement, SubscriptionManagement } from 'src/types/swagger';
import { AxiosResponse } from 'axios';
import $api from 'src/api/service/request';
import { API_DOMAIN } from 'src/api/service/endpoints';
import { getFromLocalStorage } from 'src/api/service/helpers/localStorage.helper';
import AnnounceUi from 'src/components/ui/announce/Announce';

import Loader from 'src/components/ui/loader/Loader';
import ProfileSubscriptionsPrev from './components/profileSubscriptionsPrev/ProfileSubscriptionsPrev';
import ProfileSubscriptionSetting from './components/setting/ProfileSubscriptionSetting';

const ProfileSubscriptions = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<SubscriptionManagement | null>(null);
  const [changeSubscriptionActive, setChangeSubscriptionActive] = useState(false);

  //Первичные данные
  useEffect(() => {
    setIsLoading(true);
    const fetchData = async () => {
      try {
        const response: AxiosResponse<ResponseSubscriptionManagement> = await $api.get(
          `${API_DOMAIN}clients/${getFromLocalStorage('agreement')}/profile/subscriptions/`
        );

        const { data } = response.data;
        if (data) {
          setData(data);
        }
      } catch (error: any) {
        return error.message || 'Failed to fetch client';
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);
  //Первичные данные

  //Кнопка настроить
  const handleSubscriptionSetting = () => {
    setChangeSubscriptionActive((prev) => !prev);
  };
  //Кнопка настроить

  //Лоадер
  if (isLoading) {
    return <Loader state={isLoading} />;
  }
  //Лоадер

  return (
    data && (
      <div className="profile-page react-profile-page">
        <Panel text={'Настройки рассылок'} href={PROFILE} linkText={'Управление профилем'} />
        <section className="section section--first-panel">
          <div className="container">
            {data.announces &&
              data.announces.map((item) => (
                <AnnounceUi key={item.announce_id} text={item.message} />
              ))}

            <div className="profile-page__inner">
              <div className="section__container small">
                <div className="profile-page__container profile-page__news" data-news="">
                  <div className="section__title profile-page__heading">Подключенные рассылки</div>
                  {data.subscriptions && !changeSubscriptionActive && (
                    <ProfileSubscriptionsPrev
                      data={data.subscriptions}
                      handleSubscriptionSetting={handleSubscriptionSetting}
                    />
                  )}
                  {data.subscriptions && changeSubscriptionActive && (
                    <ProfileSubscriptionSetting
                      data={data.subscriptions}
                      handleSubscriptionSetting={handleSubscriptionSetting}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    )
  );
};

export default ProfileSubscriptions;
