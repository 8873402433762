import { format, isToday, isYesterday } from 'date-fns';

export const formatDate = (date: Date, variant: 'send' | 'wordTime' | 'date') => {
  if (variant === 'send') {
    return format(date, 'yyyy-MM-dd');
  } else if (variant === 'wordTime') {
    if (isToday(date)) {
      return `Сегодня, ${format(date, 'HH:mm')}`;
    } else if (isYesterday(date)) {
      return `Вчера, ${format(date, 'HH:mm')}`;
    } else {
      return format(date, 'dd.MM.yyyy, HH:mm');
    }
  } else if (variant === 'date') {
    return format(date, 'dd.MM.yyyy');
  }
};
