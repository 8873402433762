import React, { useState } from "react";
import Panel from "../panel/Panel";
import { HOME, MAIN, PARTNERS } from "src/routes/data";
import Button from "src/components/ui/button/Button";
import Input from "src/components/ui/input/Input";
import { useForm } from "react-hook-form";
import InputError from "src/components/ui/inputError/InputError";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import Loader from "src/components/ui/loader/Loader";
import { API_DOMAIN } from "src/api/service/endpoints";
import $api from "src/api/service/request";

const PartnersFrom = () => {
  const [success, setSuccess] = useState(false);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    getValues,
  } = useForm({ mode: "onChange" });
  const { clientData } = useSelector((state) => state.profile);
  const { data } = clientData;
  const [isLoading, setIsLoading] = useState(false);


  const onSubmit = async () => {
    setIsLoading(true);

    try {
      const response = await $api
        .post

        // `${API_DOMAIN}promotions/partners/${part_id}/`,
        ();
      const { data } = response.data;

      if (data) {
        console.info("Заявка направлена успешно изменены", data);

        // handleSuccessModal();
      }
    } catch (error) {
      console.error("Failed to change packets:", error.message);
    } finally {
      setIsLoading(false);
      setSuccess((prev) => !prev);
    }
  };

  if (isLoading) {
    return <Loader state={isLoading} />;
  }

  return data ? (
    <div className="partners-page">
      <Panel
        text="Участвовать"
        linkText="Партнерская программа"
        href={PARTNERS}
      />

      <section className="section section--first-panel partners-page__section">
        <div className="container">
          <div className="partners-page__part">
            {!success && (
              <div className="section__container">
                <form
                  className="partners-page__part-wrapper"
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <h1 className="section__title">
                    Участие в партнерской программе
                  </h1>
                  <div className="partners-page__part-inner">
                    <div className="partners-page__heading">
                      Данные абонента
                    </div>
                  </div>
                  <div className="partners-page__part-grid">
                    <div className="partners-page__part-item">
                      <div className="section__label">ФИО</div>
                      <div className="partners-page__part-text">{data.fio}</div>
                    </div>
                    <div className="partners-page__part-item">
                      <div className="section__label">Номер договора</div>
                      <div className="partners-page__part-text">
                        {data.agreement}
                      </div>
                    </div>
                    {data.phones[0].phone_number && (
                      <div className="partners-page__part-item">
                        <div className="section__label">Номер телефона</div>
                        <div className="partners-page__part-text">
                          {data.phones[0].phone_number}
                        </div>
                      </div>
                    )}
                    {data.building_name && (
                      <div className="partners-page__part-item">
                        <div className="section__label">Адрес подключения</div>
                        <div className="partners-page__part-text">
                          <span>{`${data?.building_name} ${
                            data?.flat ? data?.flat : ""
                          } ${data?.apart ? data?.apart : ""}`}</span>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="partners-page__part-data">
                    <div className="partners-page__heading">
                      Данные нового абонента
                    </div>
                    <div className="partners-page__part-input">
                      <Input
                        placeholder="Номер договора"
                        register={register}
                        filled={watch("aggr")}
                        name="aggr"
                        error={errors.aggr}
                        options={{
                          required: "Заполните поле",
                          pattern: {
                            value: /^\d+$/,
                            message: "Неверный формат",
                          },
                          // max: {
                          //   value: 12,
                          //   //TODO: текст ошибки
                          //   message: "неверный формат",
                          // },
                          // min: {
                          //   value: 1,
                          //   message: "неверный формат",
                          // },
                        }}
                      />
                      {errors.contract && (
                        <InputError text={errors?.contract?.message} />
                      )}
                    </div>
                  </div>
                  <div className="partners-page__part-bot">
                    <div className="partners-page__button">
                      <Button type="submit" text="Отправить" />
                    </div>
                  </div>
                </form>
              </div>
            )}
            {success && (
              <div className="partners-page__part">
                <div className="partners-page__part-container">
                  <div className="partners-page__part-icon">
                    <svg
                      className="partners-page__part-svg"
                      xmlns="http://www.w3.org/2000/svg"
                      width={41}
                      height={40}
                      viewBox="0 0 41 40"
                      fill="none"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M37.1673 31.6667V17.475C37.1673 17.2392 37.151 17.006 37.1347 16.773C37.1322 16.7376 37.1297 16.7021 37.1273 16.6667H25.5007C24.1746 16.6667 22.9028 16.1399 21.9651 15.2022C21.0274 14.2645 20.5007 12.9927 20.5007 11.6667V0.04C20.4652 0.0375781 20.4298 0.0350981 20.3943 0.0326176C20.1614 0.0163204 19.9281 0 19.6923 0H12.1673C9.95799 0.00264643 7.83991 0.88147 6.27768 2.4437C4.71545 4.00593 3.83663 6.12401 3.83398 8.33333V31.6667C3.83663 33.876 4.71545 35.9941 6.27768 37.5563C7.83991 39.1185 9.95799 39.9974 12.1673 40H28.834C31.0433 39.9974 33.1614 39.1185 34.7236 37.5563C36.2859 35.9941 37.1647 33.876 37.1673 31.6667ZM22.8721 23.9179C22.2745 23.3442 21.325 23.3636 20.7513 23.9612C20.1775 24.5588 20.1969 25.5084 20.7945 26.0821L22.2715 27.5H16C15.1716 27.5 14.5 28.1716 14.5 29C14.5 29.8284 15.1716 30.5 16 30.5H22.2715L20.7945 31.9179C20.1969 32.4916 20.1775 33.4412 20.7513 34.0388C21.325 34.6364 22.2745 34.6558 22.8721 34.0821L27.0388 30.0821L28.166 29L27.0388 27.9179L22.8721 23.9179Z"
                        fill="#0076D8"
                      />
                      <path
                        d="M23.834 0.765625V11.6656C23.834 12.1077 24.0096 12.5316 24.3221 12.8441C24.6347 13.1567 25.0586 13.3323 25.5007 13.3323H36.4007C35.8188 11.7905 34.9148 10.3904 33.749 9.22563L27.9423 3.41562C26.7761 2.25114 25.3757 1.34785 23.834 0.765625Z"
                        fill="#EC003E"
                      />
                    </svg>
                  </div>

                  <div className="section__heading partners-page__part-title">
                    Заявление поступило в обработку
                  </div>
                  <div className="partners-page__part-btn">
                    <Link className="button" to={HOME}>
                      Вернуться на главную
                    </Link>
                  </div>
                  <div className="partners-page__part-caption">
                    При возникновении вопросов обращайтесь в контактный центр по
                    номеру: 8 (812) 595-81-21
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
    </div>
  ) : (
    <Loader state={data} />
  );
};

export default PartnersFrom;
