import React, { FC, useEffect, useState } from 'react';
import Accordion from 'src/components/ui/accordion/Accordion';
import Button from 'src/components/ui/button/Button';
import { paymentDisplay } from 'src/utils.js/paymentDisplay';
import TvChangePacket from '../changePacket/TvChangePacket';
import './TvDtv.scss';
import { PacketDtvOtt, ServiceDtvOtt } from 'src/types/swagger';
import ServiceEquipment from 'src/components/common/services/components/serviceEquipment/ServiceEquipment';
import ServiceSchedule from 'src/components/common/services/components/serviceSchedule/ServiceSchedule';
import ServiceInfoBlock from 'src/components/common/services/components/serviceInfoBlock/ServiceInfoBlock';
import { SERVICEAVAILABLEOPTIONS } from 'src/routes/data';

interface IProps {
  data: ServiceDtvOtt;
  accordionActive?: boolean;
}

const TvDtv: FC<IProps> = ({ data, accordionActive }) => {
  const packets = data.packets;
  const [isPacketModal, setPacketModal] = useState(false);
  const [connectedPackets, setConnectedPackets] = useState<PacketDtvOtt[] | null>([]);

  //Открытие модального окна с дополнительными пакетами
  const handleOptionsClick = () => {
    setPacketModal((prev) => !prev);
  };
  //Открытие модального окна с дополнительными пакетами

  //Фильтрация добавленных пакетов при загрузке
  useEffect(() => {
    const addedPackets = data?.packets?.filter((item) => item.packet_set === true);
    setConnectedPackets(addedPackets);
  }, []);
  //Фильтрация добавленных пакетов при загрузке

  return (
    <>
      {/* //Модальное окно с дополнительными пакетами */}
      {isPacketModal && (
        <TvChangePacket
          title={'Дополнительные пакеты'}
          state={isPacketModal}
          setState={setPacketModal}
          data={packets}
          serviceType={data.service_type}
          serviceCid={data.service_cid}
          condition_block={data.condition_block}
        />
      )}
      {/* //Модальное окно с дополнительными пакетами */}
      <Accordion status={data.service_status} title={data.dtv_module_name} active={accordionActive}>
        <div
          className="connected-page__inner"
          style={{ ...(connectedPackets?.length ?? 0 > 0 ? {} : { marginTop: 0 }) }}
        >
          <div className="connected-page__accordion-grid">
            {connectedPackets &&
              connectedPackets.map((item) => (
                <React.Fragment key={item.packet_name}>
                  <div className="connected-page__accordion-item">
                    <div className="services-page__card-label">Название пакета</div>
                    <div className="connected-page__text">{item.packet_name}</div>
                  </div>
                  {item.plan_fee && (
                    <div className="connected-page__accordion-item border">
                      <div className="services-page__card-label">Стоимость</div>
                      <div className="connected-page__text">
                        {paymentDisplay(item.plan_fee, item.fee_period, item.fee_kind)}
                      </div>
                    </div>
                  )}
                </React.Fragment>
              ))}
          </div>

          <div
            className="connected-page__inner"
            style={{ ...(connectedPackets?.length ?? 0 > 0 ? {} : { marginTop: 0 }) }}
          >
            <div className="connected-page__buttons gap-8 r-connected-page__buttons">
              {data.show_plan_change_btn && (
                <div className="connected-page__btn">
                  <Button
                    text="Редактировать"
                    theme="outline"
                    size="small"
                    onClick={handleOptionsClick}
                  />
                </div>
              )}
              {/* // Опции */}
              {data.show_dop_options && (
                <div className="connected-page__btn">
                  <Button
                    size={'small'}
                    text="Опции"
                    theme="outline"
                    href={SERVICEAVAILABLEOPTIONS + '/' + data.service_cid}
                  />
                </div>
              )}
              {/* // Опции */}
            </div>
          </div>
        </div>

        {/* //Оборудование, планировщик, инфоблок о планировке */}
        {data.equipment && (
          <ServiceEquipment data={data?.equipment} service_cid={data.service_cid} />
        )}
        {data.schedule && <ServiceSchedule data={data.schedule} />}
        {data.info_block && <ServiceInfoBlock data={data.info_block} />}
        {/* //Оборудование, планировщик, инфоблок о планировке */}
      </Accordion>
    </>
  );
};

export default TvDtv;
