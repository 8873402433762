import { FC } from 'react';

interface IProps {
  name: string;
  date: string;
  size: string;
  url: string;
}
const ProfileDocumentsCard: FC<IProps> = ({ name, date, size, url }) => {
  return (
    <div className="profile-page__card">
      <div className="profile-page__card-container">
        <div className="profile-page__card-heading">{name}</div>
        <div className="profile-page__card-bot">
          <div className="profile-page__card-col">
            {date && <div className="section__label default">{date}</div>}
            <div className="section__label default">{size}</div>
          </div>
          <div className="profile-page__card-col">
            <a className="profile-page__card-link" href={url} target="blank">
              Скачать
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileDocumentsCard;
