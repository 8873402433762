import { PROFILE } from 'src/routes/data';
import Panel from '../../panel/Panel';
import ProfileDocumentsCard from './components/ProfileDocumentsCard';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { FC, useEffect, useState } from 'react';
import $api from 'src/api/service/request';
import { API_DOMAIN } from 'src/api/service/endpoints';
import { useDispatch } from 'react-redux';
import Loader from 'src/components/ui/loader/Loader';
import SelectUi, { ISelectOptions } from 'src/components/ui/select/SelectUi';
import './ProfileDocuments.scss';
import { getFromLocalStorage } from 'src/api/service/helpers/localStorage.helper';
import { AxiosResponse } from 'axios';
import { Document, ResponseDocsOneTypeList } from 'src/types/swagger';

interface IYears {
  label: string;
  value: number;
}

//филтрация на документы и контракты
const filterDocumentsByType = (documents: Document[], type: string) =>
  documents.filter((item) => item.type_doc === type);
// фильтрация на документы и контракты

const ProfileDocuments: FC = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);

  const [contractList, setContractList] = useState<Document[] | null>(null);
  const [invoiceList, setInvoice] = useState<Document[] | null>(null);

  const [contractYears, setContractYears] = useState<IYears[] | null>(null);
  const [currentYear, setCurrentYear] = useState<number | null>();
  const [filteredContractList, setFilteredContractList] = useState<Document[] | null>(null);

  //Первичные данные
  useEffect(() => {
    setIsLoading(true);
    const fetchData = async () => {
      try {
        const response: AxiosResponse<ResponseDocsOneTypeList> = await $api.get(
          `${API_DOMAIN}clients/${getFromLocalStorage('agreement')}/profile/contracts/`
        );
        const { data } = response.data;

        if (data) {
          //Фильтрация документов на основные и прочие
          const filteredContract: Document[] = filterDocumentsByType(data.documents, 'contract');
          const filteredInvoice: Document[] = filterDocumentsByType(data.documents, 'invoice');

          setContractList(filteredContract);
          setInvoice(filteredInvoice);
          //Фильтрация документов на основные и прочие

          //Получаем года для фильтрации
          if (filteredContract) {
            getYears(filteredContract);
          }
          //Получаем года для фильтрации
        }
      } catch (error) {
        console.error('Ошибка при получении профильных документов:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [dispatch]);
  //Первичные данные

  //функция для получения годов
  const getYears = (contracts: Document[]) => {
    const yearsSet = new Set();
    const years: IYears[] = contracts
      .map((item) => {
        const dateObject = new Date(item.start_date);
        const year = dateObject.getFullYear();

        if (!yearsSet.has(year)) {
          yearsSet.add(year);
          return {
            label: `${year} год`,
            value: year,
          };
        }

        return null;
      })
      .filter((item): item is IYears => item !== null);
    const sortedYears = years.sort((a, b) => b.value - a.value);
    setCurrentYear(sortedYears[0]?.value);
    setContractYears(sortedYears);
  };
  //функция для получения годов

  //Колбек для выбора года
  const selectYearCallback = (option: ISelectOptions) => {
    setCurrentYear(option.value as number);
  };
  //Колбек для выбора года

  //фильтрация и обрезка элементов
  useEffect(() => {
    if (contractList) {
      const filteredContactList = contractList.filter((item) => {
        if (currentYear) {
          return new Date(item.start_date).getFullYear() === currentYear;
        } else {
          return true;
        }
      });

      // Обрезаем список, если он больше 10 элементов
      const trimmedList = filteredContactList.slice(0, 12);
      setFilteredContractList(trimmedList);
    }
  }, [contractList, currentYear]);
  //фильтрация и обрезка

  //функция для показа всего спика
  const showFullList = () => {
    setFilteredContractList(contractList);
  };
  //функция для показа всего спика

  //Лоадер
  if (isLoading) {
    return <Loader state={isLoading} />;
  }
  //Лоадер

  return (
    (contractList || invoiceList) && (
      <div className="profile-page">
        <Panel text={'Сопутствующие документы'} href={PROFILE} linkText={'Управление профилем'} />
        <section
          className="section section--first-panel"
          data-real-tabs=""
          data-select2-id="select2-data-10-f1ja"
        >
          <div className="container">
            <Tabs>
              <div className="profile-page__tabs">
                <div className="sections">
                  <TabList className="sections__list">
                    <Tab className="sections__tab" selectedClassName="active">
                      Основные
                    </Tab>
                    <Tab className="sections__tab" selectedClassName="active">
                      Прочие
                    </Tab>
                  </TabList>
                </div>
              </div>
              <div className="profile-page__inner">
                <TabPanel>
                  <div className="profile-page__wrapper active">
                    <div className="react-profile-documents__contacts-select">
                      {contractYears && (
                        <SelectUi
                          optionsValue={contractYears as ISelectOptions[]}
                          setValue={() => {}}
                          callback={selectYearCallback}
                          name="available"
                        />
                      )}
                    </div>

                    <div className="profile-page__grid react-profile-page__box">
                      {filteredContractList &&
                        filteredContractList.map((item) => (
                          <ProfileDocumentsCard
                            key={item.id}
                            name={item.name}
                            date={item.start_date}
                            size={item.size}
                            url={item.url_download}
                          />
                        ))}
                    </div>

                    {filteredContractList && filteredContractList.length > 12 && (
                      <div className="profile-page__show">
                        <div className="profile-page__card-link" onClick={() => showFullList()}>
                          Показать еще
                        </div>
                      </div>
                    )}
                  </div>
                </TabPanel>
                <TabPanel>
                  <div className="profile-page__wrapper active">
                    <div className="profile-page__grid">
                      {invoiceList &&
                        invoiceList.map((item) => (
                          <ProfileDocumentsCard
                            key={item.id}
                            name={item.name}
                            size={item.size}
                            url={item.url_download}
                            date={item.start_date}
                          />
                        ))}
                    </div>
                  </div>
                </TabPanel>
              </div>
            </Tabs>
          </div>
        </section>
      </div>
    )
  );
};

export default ProfileDocuments;
