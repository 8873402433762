import { useParams } from 'react-router-dom';
import Panel from 'src/components/common/panel/Panel';
import { SERVICES } from 'src/routes/data';
import { FC, useEffect, useState } from 'react';
import { API_DOMAIN } from 'src/api/service/endpoints';
import $api from 'src/api/service/request';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import UdomofonConnected from '../connected/UdomofonConnected';
import Loader from 'src/components/ui/loader/Loader';
import { AxiosResponse } from 'axios';
import { ResponseServiceUDomofon, ServiceTypeEnum, ServiceUDomofon } from 'src/types/swagger';
import './UdomofonSenntings.scss';
import ServiceAvailableOptions from '../../availableOptions/ServiceAvailableOptions';

const UdomofonSettings: FC = () => {
  const { id, type } = useParams();
  const [data, setData] = useState<ServiceUDomofon | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  //Первичный запрос
  useEffect(() => {
    setIsLoading(true);

    const fetchData = async () => {
      try {
        const response: AxiosResponse<ResponseServiceUDomofon> = await $api.get(
          `${API_DOMAIN}services/${
            type === ServiceTypeEnum.UDomofon ? 'udomofon' : 'collective_domofon'
          }/${id}/`
        );

        const { data } = response.data;
        if (data) {
          setData(data);
        }
      } catch (error: any) {
        console.error('Failed to fetch udomofon:', error.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);
  //Первичный запрос

  //Лоадер
  if (isLoading) {
    return <Loader state={isLoading} />;
  }
  //Лоадер

  return (
    data && (
      <div className="services-page connected-page">
        <Panel text="Подключенные" linkText="Услуги" href={SERVICES} />
        <section className="section section--first-panel r-panel-tubs">
          <div className="container">
            <Tabs>
              <div className="main-page__services-top">
                <TabList>
                  <div className="sections">
                    <div className="sections__list">
                      <Tab className="sections__tab" selectedClassName="active">
                        Мои опции
                      </Tab>
                      <Tab className="sections__tab" selectedClassName="active">
                        Доступные опции
                      </Tab>
                    </div>
                  </div>
                </TabList>
              </div>
              <TabPanel>
                <UdomofonConnected data={data} />
              </TabPanel>
              <TabPanel>
                <ServiceAvailableOptions service_cid={data.service_cid} />
              </TabPanel>
            </Tabs>
          </div>
        </section>
      </div>
    )
  );
};

export default UdomofonSettings;
