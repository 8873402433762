import { AxiosResponse } from 'axios';
import { FC, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { API_DOMAIN } from 'src/api/service/endpoints';
import { getFromLocalStorage } from 'src/api/service/helpers/localStorage.helper';
import $api from 'src/api/service/request';
import Button from 'src/components/ui/button/Button';
import InputPhone from 'src/components/ui/inputPhone/InputPhone';
import Loader from 'src/components/ui/loader/Loader';
import SelectUi from 'src/components/ui/select/SelectUi';
import {
  AvailableNewPlanPreview,
  BaseResponse,
  ResponseAvailableNewPlanPreview,
  ServiceNewRequestRequest,
} from 'src/types/swagger';
import { selectOptions } from 'src/utils.js/selectOptions';
import { validatePhoneNumber } from 'src/utils.js/validatePhoneNumber';
import './AddService.scss';
import useModal from 'src/hooks/useModal';
import Panel from 'src/components/common/panel/Panel';
import ConditionUi from 'src/components/ui/conditionUI/ConditionUi';
import { SERVICES } from 'src/routes/data';
import { openModalConfig } from 'src/utils.js/openModalOptions';

const AvailableNewPlan: FC = () => {
  const { service_type } = useParams();
  const [data, setData] = useState<AvailableNewPlanPreview>();
  const location = useLocation();
  const navigate = useNavigate();
  const { open } = useModal();

  const { plan_id, plan_name, cost } = location.state || {};

  const [phoneValue, setPhoneValue] = useState<string>('');
  const [phoneError, setPhoneError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (data && data.contact_phone) {
      setPhoneValue(data.contact_phone);
    }
  }, [data]);
  const [timeStart, setTimeStart] = useState<string | number>(selectOptions[8].value);
  const [timeEnd, setTimeEnd] = useState<string | number>(selectOptions[18].value);
  //Селекты

  //Отправка формы
  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const phone = validatePhoneNumber(phoneValue);

    if (data?.is_time_for_call && !phone) {
      setPhoneError('Некорректный номер');
      return;
    }

    const body: ServiceNewRequestRequest = {
      service_type: service_type!,
      plan_id: plan_id,
      plan_name: plan_name,
      contact_phone: phone || data?.contact_phone || null,
      call_time: {
        start: timeStart.toString(),
        end: timeEnd.toString(),
      },
    };
    setIsLoading((prev) => !prev);

    try {
      const response: AxiosResponse<BaseResponse> = await $api.post(
        `${API_DOMAIN}clients/${getFromLocalStorage('agreement')}/services/new/`,
        body
      );
      open({ ...openModalConfig(response, false), redirect: SERVICES });
    } catch (error: any) {
      console.info('error', error);
      open({ ...openModalConfig(error.response, true), redirect: SERVICES });
    } finally {
      setIsLoading(false);
    }
  };
  //Отправка формы

  //Запрос данных
  const fetchData = async () => {
    setIsLoading(true);
    try {
      const response: AxiosResponse<ResponseAvailableNewPlanPreview> = await $api.get(
        `${API_DOMAIN}clients/${getFromLocalStorage(
          'agreement'
        )}/services/available/${service_type}/info/`
      );

      if (response.data.data) {
        setData(response.data.data);
      }
    } catch (error: any) {
      console.error('Failed to fetch serviceConnect:', error.message);
    } finally {
      setIsLoading(false);
    }
  };
  //Запрос данных

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      {isLoading && <Loader state={isLoading} />}
      {data && (
        <>
          <Panel text="Подключение услуги" onBack />
          <section className="section section--first-panel r-add-service">
            <div className="container">
              <div className="section__container">
                <div className="connected-page__service">
                  <h1 className="section__title">Подключение услуги {data.service_title}</h1>
                  <div className="connected-page__service-inner">
                    {data.is_time_for_call && (
                      <div className="connected-page__service-row">
                        <div className="connected-page__service-col">
                          <div className="section__label">Удобное время для звонка</div>
                          <div className="connected-page__service-selects">
                            <div className="connected-page__service-item">
                              <div className="select-wrapper" style={{ position: 'relative' }}>
                                <div className="select select--small">
                                  <SelectUi
                                    optionsValue={selectOptions}
                                    defaultValue={selectOptions[8]}
                                    setValue={setTimeStart}
                                    name="time"
                                    prefix="с"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="connected-page__service-item">
                              <div className="select-wrapper" style={{ position: 'relative' }}>
                                <div className="select select--small">
                                  <SelectUi
                                    optionsValue={selectOptions}
                                    defaultValue={selectOptions[18]}
                                    prefix="до"
                                    setValue={setTimeEnd}
                                    name="time"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="connected-page__service-col r-add-service__phone">
                          <InputPhone
                            setValue={setPhoneValue}
                            value={phoneValue}
                            error={phoneError}
                            submitHidden={true}
                            placeholder="Телефон"
                          />
                        </div>
                      </div>
                    )}
                    {data?.text?.map((item) => (
                      <p className="service-card__text" key={item}>
                        {item}
                      </p>
                    ))}
                  </div>
                  <div className="connected-page__service-inner">
                    <div className="connected-page__bot">
                      <div className="connected-page__bot-col">
                        <div className="connected-page__bot-item">
                          <div className="connected-page__btn">
                            <Button
                              text="Отменить"
                              size="small"
                              theme="outline"
                              onClick={() => navigate(-1)}
                            />
                          </div>
                        </div>

                        <div className="connected-page__bot-item">
                          <div className="connected-page__btn">
                            <Button
                              onClick={onSubmit}
                              text="Продолжить"
                              size="small"
                              disabled={!data.is_btn_active}
                            />
                          </div>
                        </div>
                      </div>
                      {data.is_show_cost && cost && cost !== 'null' && (
                        <div className="connected-page__bot-col">
                          <div className="services-page__card-label">Итоговая стоимость</div>
                          <div className="connected-page__heading">{cost}</div>
                        </div>
                      )}
                    </div>
                  </div>

                  {/* //ссылка на условия  */}
                  <ConditionUi data={data.condition_block} />
                  {/* //ссылка на условия  */}
                </div>
              </div>
            </div>
          </section>
        </>
      )}
    </>
  );
};

export default AvailableNewPlan;
