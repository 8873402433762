import { FC, useState } from 'react';
import { API_DOMAIN } from 'src/api/service/endpoints';
import $api from 'src/api/service/request';
import Button from 'src/components/ui/button/Button';
import Modal from 'src/components/ui/modal/Modal';
import './ProfileModalChange.scss';
import { useForm } from 'react-hook-form';
import Input from 'src/components/ui/input/Input';
import { getFromLocalStorage } from 'src/api/service/helpers/localStorage.helper';
import { EmailUpdateRequest } from 'src/types/swagger';
import useModal from 'src/hooks/useModal';

interface IProps {
  state: boolean;
  setState: (prev: any) => void;
  oldEmail: string;
}

const ProfileEmailModalChange: FC<IProps> = ({ state, setState, oldEmail }) => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({ defaultValues: { email: oldEmail } });

  const [isSuccessModalActive, setSuccessModalActive] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { open } = useModal();

  //Закрытие модального окна
  const modalClose = () => {
    setState((prev: any) => !prev);
    // window.location.reload();
  };
  //Закрытие модального окна

  //Отправка данных
  const onSubmit = () => {
    const fetchData = async () => {
      setIsLoading((prev) => !prev);
      const body: EmailUpdateRequest = {
        old_email: oldEmail,
        new_email: watch('email'),
      };

      try {
        const response = await $api.put(
          `${API_DOMAIN}clients/${getFromLocalStorage('agreement')}/profile/contacts/email/`,
          body
        );

        setSuccessModalActive((prev) => !prev);

        open({
          text:
            response?.data?.data?.title || response?.data?.client_message || 'Изменения сохранены',
          modalType: 'success',
          isReload: true,
          description: response?.data?.data?.description || '',
        });
      } catch (error: any) {
        setState((prev: any) => !prev);
        open({
          text:
            error?.response?.data?.data?.title || error?.response?.data?.client_message || 'Ошибка',
          description: error.response?.data?.data?.description || '',
          modalType: 'error',
        });
        console.info('error', error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  };
  //Отправка данных

  return (
    <Modal state={state} setState={setState} callback={modalClose}>
      <div
        className="change-modal modal fancybox-content"
        data-fancy-modal="change"
        style={{ display: 'inline-block' }}
      >
        <div className="change-modal__container">
          <div className="modal__title">Изменить адрес email</div>

          {!isSuccessModalActive ? (
            <form className="change-modal__form" onSubmit={handleSubmit(onSubmit)}>
              <Input
                register={register}
                variant={'border'}
                filled={!!watch('email')}
                placeholder="Эл. почта"
                name="email"
                error={errors.email}
                options={{
                  required: 'Заполните поле',
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                    message: 'Неверный формат',
                  },
                }}
              />
              <div className="change-modal__btns change-modal__inner">
                <div className="change-modal__item">
                  <Button
                    text={'Продолжить'}
                    onClick={handleSubmit(onSubmit)}
                    isLoading={isLoading}
                  />
                </div>
                <div className="change-modal__item">
                  <Button text={'Отменить'} theme={'outline'} onClick={() => modalClose()} />
                </div>
              </div>
            </form>
          ) : (
            <div className="change-modal__response" data-response="change-email">
              <div className="change-modal__text">
                На адрес <a>{watch('email')}</a> отправлено письмо с подтверждением. Пройдите по
                ссылке в письме для подтверждения изменения адрес а эл.почты
              </div>
              <div className="change-modal__inner">
                <div className="change-modal__button">
                  <Button text={'Готово'} onClick={() => modalClose()} />
                </div>
              </div>
            </div>
          )}

          <div className="change-modal__caption">
            Если у вас отсутствует доступ к номеру или почте, обратитесь в КЦ по тел.: 8 (812)
            595-81-21
          </div>
        </div>
        <div className="modal__icon alt" onClick={() => modalClose()}>
          <svg className="modal__svg small" width={24} height={24} viewBox="0 0 24 24" fill="none">
            <path
              d="M21.756 2.24399C21.5997 2.08776 21.3878 2 21.1668 2C20.9459 2 20.7339 2.08776 20.5777 2.24399L12 10.8216L3.42235 2.24399C3.26607 2.08776 3.05414 2 2.83317 2C2.6122 2 2.40027 2.08776 2.24399 2.24399C2.08776 2.40027 2 2.6122 2 2.83317C2 3.05414 2.08776 3.26607 2.24399 3.42235L10.8216 12L2.24399 20.5777C2.08776 20.7339 2 20.9459 2 21.1668C2 21.3878 2.08776 21.5997 2.24399 21.756C2.40027 21.9122 2.6122 22 2.83317 22C3.05414 22 3.26607 21.9122 3.42235 21.756L12 13.1784L20.5777 21.756C20.7339 21.9122 20.9459 22 21.1668 22C21.3878 22 21.5997 21.9122 21.756 21.756C21.9122 21.5997 22 21.3878 22 21.1668C22 20.9459 21.9122 20.7339 21.756 20.5777L13.1784 12L21.756 3.42235C21.9122 3.26607 22 3.05414 22 2.83317C22 2.6122 21.9122 2.40027 21.756 2.24399Z"
              fill="#001F38"
            />
          </svg>
        </div>
        <button
          type="button"
          onClick={() => modalClose()}
          className="fancybox-button fancybox-close-small"
          title="Close"
        >
          <svg viewBox="0 0 24 24">
            <path d="M13 12l5-5-1-1-5 5-5-5-1 1 5 5-5 5 1 1 5-5 5 5 1-1z" />
          </svg>
        </button>
      </div>
    </Modal>
  );
};

export default ProfileEmailModalChange;
