import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import TooltipUi from 'src/components/ui/tooltip/TooltipUi';
import { SERVICEAVAILABLENEWPLAN, SERVICECONNECTNEW } from 'src/routes/data';
import { PlanItem } from 'src/types/swagger';
import { paymentDisplay } from 'src/utils.js/paymentDisplay';
import './AvailableOptionsCard.scss';

interface IProps {
  data: PlanItem;
  service_cid?: string;
  category_code?: string;
  variant: 'options' | 'available' | 'spec';
  service_type?: string;
  handleChannels?: (key: string, serviceType: string) => void;
  plan_id?: string | number;
}

const AvailableOptionsCard: FC<IProps> = ({
  data,
  service_cid,
  category_code,
  variant,
  service_type,
  handleChannels,
  plan_id,
}) => {
  const navigate = useNavigate();
  const handleClick = () => {
    variant === 'options'
      ? navigate(
          SERVICECONNECTNEW + `/extra_options/${service_cid}/${data.plan_id}/${category_code}`
        )
      : navigate(SERVICEAVAILABLENEWPLAN + '/' + service_type, {
          state: {
            service_type,
            plan_id: data.plan_id || plan_id,
            plan_name: data.plan_name,
            cost: paymentDisplay(data.plan_fee, data.fee_period, data.fee_kind),
            variant: variant,
          },
        });
  };

  return (
    <div className="services-page__card r-available-card">
      <div className="services-page__card-container">
        {data.is_highlighted && (
          <div className="services-page__card-icon">
            <svg
              className="services-page__card-svg"
              width={24}
              height={24}
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M15 17.5973C15 16.2348 14.1743 14.3727 12.6776 12.3534C12.5947 12.2413 12.491 12.1515 12.3738 12.0904C12.2567 12.0293 12.129 11.9984 12.0001 12.0001C11.8702 11.9994 11.7417 12.0308 11.6236 12.0921C11.5055 12.1535 11.4006 12.2433 11.3162 12.3552C9.67766 14.5325 8.87682 16.4582 9.01537 17.9343C9.06989 18.401 9.21098 18.8493 9.42936 19.2497C9.64773 19.65 9.93843 19.9933 10.2823 20.257C10.7687 20.7094 11.3716 20.9701 12.0001 21C12.7954 20.999 13.558 20.6402 14.1204 20.0023C14.6828 19.3644 14.9992 18.4995 15 17.5973Z"
                fill="#EC003E"
              />
              <path
                d="M16.1728 3.65791C15.4639 3.04533 14.7199 2.40341 13.9623 1.70279C13.675 1.43761 13.3342 1.23948 12.9638 1.12226C12.5933 1.00504 12.2021 0.971569 11.8176 1.02419C11.4449 1.07437 11.0876 1.20715 10.7709 1.41315C10.4543 1.61914 10.1859 1.89333 9.98493 2.21633C8.91022 4.04291 8.15013 6.04265 7.73741 8.12937C7.57593 7.89163 7.43242 7.64179 7.3081 7.38199C7.18045 7.10851 6.9882 6.87142 6.74852 6.69185C6.50884 6.51229 6.22917 6.39584 5.93447 6.35289C5.63977 6.30994 5.33919 6.34182 5.05955 6.4457C4.77991 6.54958 4.52991 6.72223 4.33185 6.94823C2.81964 8.52039 1.98138 10.6375 2.00044 12.8365C1.97953 15.0827 2.698 17.2715 4.04154 19.0547C5.38508 20.8379 7.27651 22.113 9.41493 22.6772C10.2337 22.8867 11.0743 22.9951 11.9186 23C10.8397 22.9591 9.80268 22.5648 8.96218 21.8757C8.37992 21.459 7.89034 20.9225 7.52521 20.301C7.16008 19.6795 6.92755 18.9869 6.84272 18.2681C6.63529 16.2415 7.64812 13.8819 9.85325 11.2555C10.1178 10.9402 10.4467 10.6872 10.817 10.5142C11.1874 10.3411 11.5904 10.252 11.998 10.2532H12.007C12.4134 10.2536 12.8149 10.344 13.1834 10.5182C13.5519 10.6924 13.8787 10.9462 14.1409 11.262C15.528 12.9374 17.183 15.4207 17.183 17.7371C17.181 19.0721 16.681 20.3565 15.7839 21.3313C14.8869 22.306 13.6596 22.8984 12.3497 22.989C14.9396 22.9042 17.3954 21.7975 19.1967 19.9035C20.998 18.0094 22.0034 15.4768 22 12.842C21.9955 8.68784 19.297 6.35858 16.1728 3.65791Z"
                fill="#EC003E"
              />
            </svg>
          </div>
        )}
        <div className="services-page__card-top">
          <div className="services-page__card-heading tooltip flex gap-8">
            {data.plan_name}
            {data.tooltip && <TooltipUi text={data.tooltip} />}
          </div>

          {data.details_url && (
            <a className="services-page__card-link" href={data.details_url.url} target="blank">
              {data.details_url.text_link}
            </a>
          )}
          {(data.key || data.channels_key) && (
            <button
              className="services-page__card-link"
              onClick={() =>
                handleChannels && handleChannels(data.key || data.channels_key, service_type)
              }
            >
              Список каналов
            </button>
          )}

          {data.speed && (
            <div className="services-page__card-body">
              <div className="services-page__card-list">
                <div className="services-page__card-item">
                  <div className="services-page__card-label">Скорость</div>
                  <div className="services-page__card-text">{data.speed}</div>
                </div>
              </div>
            </div>
          )}
          {data.additional_info && (
            <div className="services-page__card-body">
              <div className="services-page__card-list">
                <div className="services-page__card-item">
                  <div className="services-page__card-label">{data.additional_info.label}</div>
                  <div className="services-page__card-text">{data.additional_info.title}</div>
                </div>
              </div>
            </div>
          )}

          {data.plan_descr && (
            <div className="react-telephony-card__description">{data.plan_descr}</div>
          )}
          {/* //телефония */}
          {data.tariff_conditions && (
            <div className="services-page__card-body">
              <div className="services-page__card-list">
                {data.tariff_conditions.map((row) => (
                  <div className="services-page__card-row" key={row.condition}>
                    <div className="services-page__card-col">
                      <div className="services-page__card-text">{row.condition}</div>
                    </div>
                    <div className="services-page__card-text bold">{row.cost}</div>
                  </div>
                ))}
              </div>
            </div>
          )}
          {/* //телефония */}
        </div>
        <div className="services-page__card-bot">
          <div className="services-page__card-price">
            {paymentDisplay(data.plan_fee, data.fee_period, data.fee_kind)}
          </div>
          {/* //Подключить */}
          <div className="services-page__card-btn" onClick={handleClick}>
            Подключить
          </div>
          {/* //Подключить */}
        </div>
      </div>
    </div>
  );
};

export default AvailableOptionsCard;
