import { Link } from 'react-router-dom';
import './Button.scss';
import { FC } from 'react';

interface IProps {
  text: string;
  href?: string;
  type?: any;
  theme?: any;
  size?: any;
  className?: string;
  onClick?: any;
  props?: any;
  isLoading?: boolean;
  isLink?: boolean;
  disabled?: boolean;
}
const Button: FC<IProps> = ({
  text,
  href,
  type,
  theme,
  size,
  className,
  onClick,
  props,
  isLoading,
  disabled,
  isLink,
}) => {
  const classes = `button react-button ${className} ${
    theme === 'outline' ? 'button--outline' : ''
  } ${size === 'small' ? 'button--small' : ''}  ${disabled ? 'disabled' : ''} ${
    isLoading ? 'button_loading' : ''
  }`;

  if (type === 'submit' || type === 'button') {
    return (
      <button
        className={classes}
        disabled={isLoading || disabled}
        type={type}
        to={href}
        onClick={onClick}
        {...props}
      >
        {isLoading ? (
          <svg
            version="1.1"
            id="loader-1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            width="40px"
            height="40px"
            viewBox="0 0 50 50"
            // @ts-ignore
            style={{ enableBackground: 'new 0 0 50 50' }}
            xmlSpace="preserve"
          >
            <path
              fill="#ffff"
              d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z"
            >
              <animateTransform
                attributeType="xml"
                attributeName="transform"
                type="rotate"
                from="0 25 25"
                to="360 25 25"
                dur="0.6s"
                repeatCount="indefinite"
              />
            </path>
          </svg>
        ) : (
          text
        )}
      </button>
    );
  }

  if (isLink) {
    return (
      <a
        className={classes}
        target="blank"
        href={href}
        disabled={isLoading || disabled}
        onClick={onClick}
        {...props}
      >
        {isLoading ? (
          <svg
            version="1.1"
            id="loader-1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            width="40px"
            height="40px"
            viewBox="0 0 50 50"
            // @ts-ignore

            style={{ enableBackground: 'new 0 0 50 50' }}
            xmlSpace="preserve"
          >
            <path
              fill="#ffff"
              d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z"
            >
              <animateTransform
                attributeType="xml"
                attributeName="transform"
                type="rotate"
                from="0 25 25"
                to="360 25 25"
                dur="0.6s"
                repeatCount="indefinite"
              />
            </path>
          </svg>
        ) : (
          text
        )}
      </a>
    );
  }

  return (
    <Link
      className={classes}
      to={href}
      disabled={isLoading || disabled}
      onClick={onClick}
      {...props}
    >
      {isLoading ? (
        <svg
          version="1.1"
          id="loader-1"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          width="40px"
          height="40px"
          viewBox="0 0 50 50"
          // @ts-ignore

          style={{ enableBackground: 'new 0 0 50 50' }}
          xmlSpace="preserve"
        >
          <path
            fill="#ffff"
            d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z"
          >
            <animateTransform
              attributeType="xml"
              attributeName="transform"
              type="rotate"
              from="0 25 25"
              to="360 25 25"
              dur="0.6s"
              repeatCount="indefinite"
            />
          </path>
        </svg>
      ) : (
        text
      )}
    </Link>
  );
};

export default Button;
