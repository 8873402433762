import ServiceStatus from '../ui/status/ServiceStatus';
import CopyButton from 'src/components/ui/copyButton/CopyButton';
import { Link } from 'react-router-dom';
import Svg from 'src/components/ui/svg/Svg';
import { linkOfType } from 'src/utils.js/linkOfType';
import { FC } from 'react';
import { paymentDisplay } from 'src/utils.js/paymentDisplay';
import { ServiceSummary } from 'src/types/swagger';
import './ServicePreviewCard.scss';

interface IProps {
  data: ServiceSummary;
}

//TODO: выснить есть ли модули

const ServicePreviewCard: FC<IProps> = ({ data }) => {
  return (
    <div className="service-card service-card--big r-service-preview">
      <div className="service-card__container">
        <div className="service-card__top">
          <div className="service-card__inner">
            <div className="service-card__icon default">
              <Svg type={data.service_type} />
            </div>

            {data.service_status && <ServiceStatus status={data.service_status} />}
          </div>
          <div className="service-card__title">{data.service_title}</div>
          {data.service_agr && (
            <div className="service-card__id">
              <CopyButton text={data.service_agr} />
            </div>
          )}

          <ul className="service-card__list">
            {/* //TODO: выяснить будут ли модули */}
            {/* {module && (
              <li class="service-card__item">
                <div class="service-card__label">Модуль</div>
                <div class="service-card__text">{module}</div>
              </li>
            )} */}
            {/* //Тариф */}
            {data.plan_name && (
              <li className="service-card__item">
                <div className="service-card__label">Тариф</div>
                <div className="service-card__text">{data.plan_name}</div>
              </li>
            )}
            {/* //Тариф */}
            {data.end_date && (
              <li className="service-card__item">
                <div className="service-card__label">Абонемент до</div>
                <div className="service-card__text">{data.end_date}</div>
              </li>
            )}
          </ul>
        </div>

        <div className="service-card__bot">
          {data.additional_info && (
            <div className="service-card__bot-text">
              <div className="section__label">{data.additional_info}</div>
            </div>
          )}
          <div className="service-card__bot-row">
            <div className="service-card__col">
              {data.plan_fee !== null && (
                <>
                  <div className="service-card__label">Абонентская плата</div>
                  <div className="service-card__price">
                    {paymentDisplay(data.plan_fee, data.fee_period, data.fee_kind)}
                  </div>
                </>
              )}
            </div>
            <div className="service-card__col">
              <Link
                className="service-card__settings"
                to={linkOfType(data.service_type, data.service_cid)}
              >
                <Svg type={'setting'} />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServicePreviewCard;
