import { FC } from "react";
import { Link } from "react-router-dom";

interface IProps {
  title: string;
  href: string;
}
const ServiceButton: FC<IProps> = ({ title, href }) => {
  return (
    <object>
      <Link className="service-card__btn" to={href!}>
        {title}
      </Link>
    </object>
  );
};

export default ServiceButton;
