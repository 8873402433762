import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import "./Tooltip.scss";
import { FC, useId } from "react";
import { v4 as uuidv4 } from "uuid";

interface IProps {
  text: string;
  type?: "more" | "plans" | undefined;
  clickable?: boolean;
}

const TooltipUi: FC<IProps> = ({ text, type, clickable }) => {
  const tooltipId = uuidv4();

  return (
    <div>
      {type === "more" ? (
        <div
          id={`clickable-${tooltipId}`}
          className="modules-modal__link"
          data-tooltip-icon=""
          aria-expanded="false"
        >
          Подробнее
        </div>
      ) : type === "plans" ? (
        <div
          id={`clickable-${tooltipId}`}
          className="modules-modal__link"
          data-tooltip-icon=""
          aria-expanded="false"
        >
          Описание тарифа
        </div>
      ) : (
        <a id={`clickable-${tooltipId}`} className="react-tooltip-question">
          ?
        </a>
      )}

      <Tooltip
        anchorSelect={`#clickable-${tooltipId}`}
        clickable={clickable}
        style={{
          maxWidth: "270px",
          borderRadius: "8px",
          backgroundColor: "#fff",
          fontSize: "14px",
          fontWeight: 400,
          color: "#001f38",
          boxShadow: "0px 18px 18px 0px rgba(0, 31, 56, 0.2)",
        }}
      >
        <div dangerouslySetInnerHTML={{ __html: text }} />
      </Tooltip>
    </div>
  );
};

export default TooltipUi;
