import { PayloadAction, createSlice } from '@reduxjs/toolkit'

interface ModalState {
	isOpen: boolean
	modalData: IModalData
}

export interface IModalData {
	modalType: 'success' | 'error' | 'tv' | null
	text: string
	description?: string
	isReload?: boolean
	href?: string
	redirect?: string
	text_html?: string
	goBack?: boolean
}

const initialState: ModalState = {
	isOpen: false,
	modalData: {
		modalType: 'success',
		text: '',
		description: '',
		isReload: true,
		href: '',
		redirect: '',
		text_html: ''
	}
}

const modalSlice = createSlice({
	name: 'modalSlice',
	initialState,
	reducers: {
		openModal: (state, action: PayloadAction<IModalData>) => {
			state.isOpen = true
			state.modalData = action.payload
		},
		closeModal: state => {
			state.isOpen = false
		},
		clearModalState: state => {
			state.isOpen = initialState.isOpen
			state.modalData = initialState.modalData
		}
	}
})

export const { openModal, closeModal, clearModalState } = modalSlice.actions

export default modalSlice.reducer
