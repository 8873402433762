import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { openRoutes, routes } from '../../../routes/routes.js';
import PrivateRoute from 'src/routes/PrivateRoute';
import { PAGE404 } from 'src/routes/data';

const renderRoutes = (routeList: any) => {
  return routeList.map((item: any) => (
    <Route key={item.path} path={item.path} element={item.element} />
  ));
};

const AppRouter = () => {
  const location = useLocation();

  return (
    <Routes location={location} key={location.pathname}>
      <Route element={<PrivateRoute />}>{renderRoutes(routes)}</Route>

      {openRoutes.map((item) => (
        <Route key={item.path} path={item.path} element={item.element} />
      ))}

      <Route path="*" element={<Navigate to={PAGE404} replace />} />
    </Routes>
  );
};

export { AppRouter };
