import React, { FC } from 'react';
import { ISpecOffers } from 'src/types/swagger';
import './SpecialOffer.scss';
interface IProps {
  data: ISpecOffers;
}
const SpecialOffer: FC<IProps> = ({ data }) => {
  return (
    <>
      <div className="connected-page__inner r-special-offer">
        <div className="services-page__item-heading">{data.category_name}</div>
      </div>

      {data.items.map((item) => (
        <div className="services-page__offer r-special-offer" key={item.link_url}>
          <div className="services-page__offer-container">
            <div className="services-page__offer-title">{item.title}</div>
            <div className="services-page__offer-text">
              <p>{item.paragraph_one}</p>
              <p>{item.paragraph_two}</p>
            </div>
            {/* <div className="services-page__offer-btn">
              <a
                href={item.button_url}
                target="blank"
                className="button button--black cursor-pointer"
              >
                {item.button_text}
              </a>
            </div> */}

            <a
              className="services-page__offer-link cursor-pointer"
              style={{ color: 'white' }}
              target="blank"
              href={item.link_url}
            >
              {item.link_text}
            </a>
          </div>
        </div>
      ))}
    </>
  );
};

export default SpecialOffer;
