import { FC, useState } from 'react';
import './Checkbox.scss';

interface IProps {
  name?: string;
  disabled?: boolean;
  text?: string | number;
  defaultValue?: boolean;
  callback: (data: any) => void;
  isBlocked?: boolean;
}
const CheckboxDefault: FC<IProps> = (props) => {
  const { name, disabled, text, defaultValue, callback, isBlocked } = props;
  const [isChecked, setChecked] = useState(defaultValue || false);
  const isDisabled = disabled || false;

  const handleCheckboxChange = () => {
    const newCheckedState = !isChecked;
    setChecked(newCheckedState);

    if (callback) {
      callback({
        ...props,
        checked: newCheckedState,
      });
    }
  };

  return (
    <div
      className={`checkbox ${isBlocked ? 'checkbox_blocked' : ''}`}
      onClick={(e) => e.stopPropagation()}
    >
      <label className="checkbox__label">
        <input
          className="checkbox__input"
          type="checkbox"
          name={name}
          disabled={isDisabled}
          checked={isChecked}
          onChange={handleCheckboxChange}
        />
        <div className="checkbox__box">
          <svg className="checkbox__svg" width={14} height={10} viewBox="0 0 14 10" fill="none">
            <path
              d="M4.77733 8.26648L1.51149 5.00065C1.35522 4.84442 1.1433 4.75666 0.922327 4.75666C0.701357 4.75666 0.489434 4.84442 0.333161 5.00065C0.176935 5.15692 0.0891724 5.36885 0.0891724 5.58982C0.0891724 5.81079 0.176935 6.02271 0.333161 6.17898L3.59899 9.44481C3.75377 9.59965 3.93753 9.72247 4.13978 9.80627C4.34204 9.89007 4.55882 9.9332 4.77774 9.9332C4.99667 9.9332 5.21345 9.89007 5.41571 9.80627C5.61796 9.72247 5.80172 9.59965 5.95649 9.44481L13.6665 1.73482C13.8227 1.57855 13.9105 1.36662 13.9105 1.14565C13.9105 0.924684 13.8227 0.712761 13.6665 0.556488C13.5102 0.400262 13.2983 0.3125 13.0773 0.3125C12.8564 0.3125 12.6444 0.400262 12.4882 0.556488L4.77733 8.26648Z"
              fill="white"
            />
          </svg>
        </div>
        {text && <div className="checkbox__caption big">{text}</div>}
      </label>
    </div>
  );
};

export default CheckboxDefault;
