import CopyButton from 'src/components/ui/copyButton/CopyButton';
import Svg from 'src/components/ui/svg/Svg';
import ServiceStatus from '../../components/ui/status/ServiceStatus';
import { paymentDisplay } from 'src/utils.js/paymentDisplay';
import React, { FC } from 'react';
import Button from 'src/components/ui/button/Button';
import { SERVICECHANGETARIFF, SERVICESPAUSE, SERVICEUNBLOCK } from 'src/routes/data';
import { ServiceUDomofon } from 'src/types/swagger';
import ServiceEquipment from '../../components/serviceEquipment/ServiceEquipment';
import ServiceSchedule from '../../components/serviceSchedule/ServiceSchedule';
import ServiceInfoBlock from '../../components/serviceInfoBlock/ServiceInfoBlock';

interface IProps {
  data: ServiceUDomofon;
  isRadio?: boolean;
}

const UdomofonConnected: FC<IProps> = ({ data, isRadio }) => {
  return (
    data && (
      <div className="services-page__inner">
        <div className="services-page__block active" data-tabs-block="">
          <div className="connected-page__container">
            <div className="connected-page__top">
              <div className="connected-page__top-col">
                <div className="connected-page__icon">
                  <Svg type={isRadio ? 'collective_radio' : 'uDomofon'} />
                </div>
              </div>
              <div className="connected-page__top-col tooltip">
                <CopyButton text={data.service_agr} />
                <div className="connected-page__top-label">
                  <ServiceStatus status={data.service_status} />
                </div>
              </div>
            </div>
            <div className="connected-page__head">
              <div className="connected-page__head-col">
                <h3 className="section__title">{data.service_title}</h3>
              </div>
              {data.additional_info && (
                <div className="connected-page__head-col">
                  <div className="section__label default">{data.additional_info}</div>
                </div>
              )}
            </div>
            <div className="connected-page__layout">
              <div className="connected-page__inner">
                <div className="connected-page__row">
                  {/* //Дополнительная информация */}
                  {data.plan?.plan_name && (
                    <div className="connected-page__row-col">
                      <div className="services-page__card-label">Тариф</div>
                      <div className="connected-page__heading underline">{data.plan.plan_name}</div>
                    </div>
                  )}
                  {/* //Дополнительная информация */}

                  <div className="connected-page__row-col">
                    {data?.plan && data.plan.plan_fee !== null && (
                      <div className="connected-page__row-item">
                        <div className="services-page__card-label">Стоимость</div>
                        <div className="connected-page__text">
                          {paymentDisplay(
                            data.plan.plan_fee,
                            data.plan.fee_period,
                            data.plan.fee_kind
                          )}
                        </div>
                      </div>
                    )}

                    {/* //Дополнительная инфомрация только для телефона */}
                    {data.additional_info && (
                      <div className="connected-page__row-item only-mobile">
                        <div className="section__label default">{data.additional_info}</div>
                      </div>
                    )}
                    {/* //Дополнительная инфомрация только для телефона */}

                    {data.plan?.start_date && (
                      <div className="connected-page__row-item">
                        <div className="services-page__card-label">Дата подключения</div>
                        <div className="connected-page__text">{data.plan.start_date}</div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            {/* //Оборудование, планировщик, инфоблок о планировке */}
            {data.equipment && (
              <ServiceEquipment data={data?.equipment} service_cid={data.service_cid} />
            )}
            {data.schedule && <ServiceSchedule data={data.schedule} />}
            {data.info_block && <ServiceInfoBlock data={data.info_block} />}
            {/* //Оборудование, планировщик, инфоблок о планировке */}

            {/* //Приостановить  смена тарифа  Возобновить */}
            <div className="connected-page__inner">
              <div className="connected-page__bot">
                <div className="connected-page__bot-col">
                  {data.show_plan_change_btn && (
                    <div className="connected-page__bot-item">
                      <div className="connected-page__btn">
                        <Button
                          text="Сменить тариф"
                          size="small"
                          theme="outline"
                          href={SERVICECHANGETARIFF + '/' + data.service_cid}
                        />
                      </div>
                    </div>
                  )}
                  {data.show_block_btn && (
                    <div className="connected-page__bot-item">
                      <div className="connected-page__btn">
                        <Button
                          text="Приостановить"
                          theme="outline"
                          size="small"
                          href={SERVICESPAUSE + '/' + data.service_cid}
                        />
                      </div>
                    </div>
                  )}
                  {data.show_unblock_btn && (
                    <div className="connected-page__bot-item">
                      <div className="connected-page__btn">
                        <Button
                          text="Возобновить"
                          theme="outline"
                          href={SERVICEUNBLOCK + '/' + data.service_cid}
                          size="small"
                        />
                      </div>
                    </div>
                  )}
                </div>

                {data.total_cost && (
                  <div className="connected-page__bot-col">
                    <div className="services-page__card-label">Итоговая стоимость</div>
                    <div className="connected-page__heading">
                      {paymentDisplay(
                        data.total_cost.cost,
                        data.total_cost.fee_period,
                        data.total_cost.fee_kind
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
            {/* //Приостановить  смена тарифа  Возобновить */}
          </div>
        </div>
      </div>
    )
  );
};

export default UdomofonConnected;
