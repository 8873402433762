import Accordion from 'src/components/ui/accordion/Accordion';
import CopyButton from 'src/components/ui/copyButton/CopyButton';
import { FC, useEffect, useState } from 'react';
import $api from 'src/api/service/request';
import { API_DOMAIN } from 'src/api/service/endpoints';
import Loader from 'src/components/ui/loader/Loader';
import { format, parse } from 'date-fns';
import CalendarUi from 'src/components/ui/calendar/CalenedarUi';
import { AxiosResponse } from 'axios';
import { Charge, Payment, ReportsOverview, ResponseReportsOverview } from 'src/types/swagger';
import { getFromLocalStorage } from 'src/api/service/helpers/localStorage.helper';
import Panel from 'src/components/common/panel/Panel';
import { useNavigate, useParams } from 'react-router-dom';

const TvExpenses: FC = () => {
  const { service_agr } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState<ReportsOverview | null>(null);

  const [chargesList, setChargesList] = useState<Charge[] | null>(null);
  const [visibleCharges, setVisibleCharges] = useState<Charge[] | null>([]);

  const [paymentsList, setPaymentsList] = useState<Payment[] | null>([]);
  const [visiblePayments, setVisiblePayments] = useState<Payment[] | null>([]);

  const [date, setDate] = useState<Date[] | null>(null);

  //Запрос данных
  const fetchData = async (start_date: string | null = '', end_date: string | null = '') => {
    setIsLoading(true);

    try {
      const response: AxiosResponse<ResponseReportsOverview> = await $api.get(
        `${API_DOMAIN}clients/${getFromLocalStorage(
          'agreement'
        )}/services/${service_agr}/collective_expenses/${
          date ? `?start_date=${start_date}&` + `end_date=${end_date}` : ''
        }`
      );

      const { data } = response.data;

      if (data) {
        setData(data);

        //Установление начислений
        if (data?.charges?.charges) {
          setChargesList(data.charges.charges);
        }
        //Установление начислений

        //Установление Платежей
        if (data.payments?.payments) {
          setPaymentsList(data.payments.payments);
        }
        //Установление Платежей

        //установка значения периода при загурзке

        const startDate = data.start_date
          ? parse(data.start_date, 'dd.MM.yyyy', new Date())
          : new Date();
        const endDate = data.end_date ? parse(data.end_date, 'dd.MM.yyyy', new Date()) : new Date();
        setDate([startDate, endDate]);
        //установка значения периода при загурзке
      }
    } catch (error: any) {
      console.error('Failed to fetch client:', error.message);
    } finally {
      setIsLoading(false);
    }
  };

  //Запрос данных
  //Первичная загрузка
  useEffect(() => {
    fetchData();
  }, []);
  //Первичная загрузка

  //Разделение списка
  useEffect(() => {
    if (chargesList) {
      setVisibleCharges(chargesList.slice(0, 7));
    }

    if (paymentsList) {
      setVisiblePayments(paymentsList.slice(0, 7));
    }
  }, [chargesList, paymentsList]);
  //Разделение списка

  //Текущая дата
  const currentDate = new Date();

  const formattedDate = (date: Date) => {
    return format(date, 'dd.MM.yyyy');
  };
  //Текущая дата

  //Показ всего списка
  const handleShowAllClicks = (type: 'charges' | null) => {
    if (type === 'charges') {
      setVisibleCharges(chargesList);
    } else {
      setVisiblePayments(paymentsList);
    }
  };
  //Показ всего списка

  //Колбек для запроса данных после календаря
  const handleShow = () => {
    // e.preventDefault();
    const start_date = date?.[0] ? format(date[0], 'yyyy-MM-dd') : null;
    const end_date = date?.[1] ? format(date[1], 'yyyy-MM-dd') : null;
    fetchData(start_date, end_date);
  };
  //Колбек для запроса данных после календаря

  if (isLoading) {
    return <Loader state={isLoading} />;
  }
  return (
    data &&
    visibleCharges && (
      <div className="costs-page">
        <Panel text="Контроль расходов" onBack={true} />
        <section className="section section--first-panel">
          <div className="container">
            <div className="section__container">
              <div className="costs-page__wrapper">
                <div className="costs-page__top">
                  <h1 className="section__title">Контроль расходов</h1>

                  <div className="costs-page__copy">
                    <CopyButton text={data.agreement} size={true} />
                  </div>
                </div>
                <div className="costs-page__inner">
                  <div className="costs-page__inner-col">
                    <div className="costs-page__calendar">
                      <CalendarUi
                        value={date}
                        setValue={setDate}
                        isRange={true}
                        svg={true}
                        onClick={handleShow}
                        maxDate={new Date()}
                      />
                    </div>
                    <div className="costs-page__balance">
                      <div className="costs-page__balance-text">
                        Баланс на {formattedDate(currentDate)}:
                      </div>
                      {data.balance && <div className="costs-page__price">{data.balance} ₽</div>}
                    </div>
                  </div>
                </div>

                <div className="costs-page__accordions">
                  {/* //Расходы */}
                  <Accordion title={'Начисления'} active={true}>
                    <div className="costs-page__accordions-inner">
                      <div className="costs-page__accordions-col">
                        <div className="costs-page__price">
                          Баланс на {data?.charges?.start_date}
                        </div>
                      </div>
                      <div className="costs-page__accordions-col">
                        <div className="costs-page__price">{data?.charges?.start_balance} ₽</div>
                      </div>
                    </div>
                    <div className="costs-page__accordions-table">
                      <div className="costs-page__accordions-top">
                        <div className="costs-page__accordions-grid">
                          <div className="costs-page__accordions-item">
                            <div className="section__label default">Дата</div>
                          </div>
                          <div className="costs-page__accordions-item">
                            <div className="section__label default">Услуга/Тариф</div>
                          </div>
                          <div className="costs-page__accordions-item">
                            <div className="section__label default">Сумма начисления</div>
                          </div>
                          <div className="costs-page__accordions-item">
                            <div className="section__label default">Сумма платежа</div>
                          </div>
                        </div>
                      </div>
                      <div className="costs-page__accordions-body">
                        {visibleCharges.map((item, index) => (
                          <div className="costs-page__accordions-grid" key={index}>
                            <div className="costs-page__accordions-item">
                              <div className="section__label only-mobile">Дата</div>
                              <div className="costs-page__text">{item.date}</div>
                            </div>
                            <div className="costs-page__accordions-item">
                              <div className="section__label only-mobile">Услуга/Тариф</div>
                              <div className="costs-page__text">{item.name}</div>
                            </div>
                            <div className="costs-page__accordions-item">
                              <div className="section__label only-mobile">Сумма начисления</div>
                              {item.amount_charge && (
                                <div className="costs-page__text">{item.amount_charge} ₽</div>
                              )}
                            </div>

                            <div className="costs-page__accordions-item">
                              {item.amount_payment && (
                                <div className="costs-page__text">{item.amount_payment} ₽</div>
                              )}
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>

                    {visibleCharges.length === 7 && (
                      <div
                        className="costs-page__accordions-btn"
                        onClick={() => handleShowAllClicks('charges')}
                      >
                        Показать все
                      </div>
                    )}

                    {data?.charges?.end_date && (
                      <div className="costs-page__accordions-inner">
                        <div className="costs-page__accordions-col">
                          <div className="costs-page__price react-costs-page__price">
                            Баланс на {data.charges.end_date}
                          </div>
                        </div>
                        <div className="costs-page__accordions-col">
                          <div className="costs-page__price">{data.charges.end_balance} ₽</div>
                        </div>
                      </div>
                    )}
                  </Accordion>
                  {/* //Расходы */}

                  {/* //Платежи */}
                  <Accordion active={true} price={data?.payments?.total_amount} title={'Платежи'}>
                    <div className="costs-page__accordions-top">
                      <div className="costs-page__accordions-grid alt">
                        <div className="costs-page__accordions-item">
                          <div className="section__label default">Дата</div>
                        </div>
                        <div className="costs-page__accordions-item">
                          <div className="section__label default">Тип платежа</div>
                        </div>
                        <div className="costs-page__accordions-item">
                          <div className="section__label default">Сумма</div>
                        </div>
                      </div>
                    </div>
                    <div className="costs-page__accordions-body">
                      {visiblePayments &&
                        visiblePayments.map((item, index) => (
                          <div className="costs-page__accordions-grid alt" key={index}>
                            <div className="costs-page__accordions-item">
                              <div className="section__label only-mobile">Дата</div>
                              <div className="costs-page__text">{item.date}</div>
                            </div>
                            <div className="costs-page__accordions-item">
                              <div className="section__label only-mobile">Тип платежа</div>
                              <div className="costs-page__text">{item.name}</div>
                            </div>
                            <div className="costs-page__accordions-item">
                              <div className="section__label only-mobile">Сумма</div>
                              <div className="costs-page__text">{item.amount} ₽</div>
                            </div>
                          </div>
                        ))}
                    </div>
                  </Accordion>
                  {/* //Платежи */}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    )
  );
};

export default TvExpenses;
