import React, { FC, useEffect, useState } from 'react';
import Button from 'src/components/ui/button/Button';
import { paymentDisplay } from 'src/utils.js/paymentDisplay';
import TvChangePacket from '../changePacket/TvChangePacket';
import { PacketDtvOtt, ServiceDtvOtt } from 'src/types/swagger';
import ServiceEquipment from 'src/components/common/services/components/serviceEquipment/ServiceEquipment';
import ServiceSchedule from 'src/components/common/services/components/serviceSchedule/ServiceSchedule';
import ServiceInfoBlock from 'src/components/common/services/components/serviceInfoBlock/ServiceInfoBlock';
import { SERVICEAVAILABLEOPTIONS } from 'src/routes/data';
import { useNavigate } from 'react-router-dom';

interface IProps {
  data: ServiceDtvOtt;
}
const TvOtt: FC<IProps> = ({ data }) => {
  const packets = data?.packets;
  const [addedPackets, setAddedPackets] = useState<PacketDtvOtt[] | null>(null);
  const [isPacketModal, setPacketModal] = useState(false);

  //Фильтрация добавленных пакетов при загрузке
  useEffect(() => {
    const addedPackets = data?.packets?.filter((item) => item.packet_set === true);
    setAddedPackets(addedPackets);
  }, []);
  //Фильтрация добавленных пакетов при загрузке

  //вывод модального окна с дополнительными пакетами
  const handleOptionsClick = () => {
    setPacketModal(true);
  };
  //вывод модального окна с дополнительными пакетами

  return (
    addedPackets && (
      <>
        {/* //Модальное окно с пакетами */}
        {isPacketModal && (
          <TvChangePacket
            title={'Дополнительные пакеты'}
            state={isPacketModal}
            setState={setPacketModal}
            data={packets}
            serviceType={data.service_type}
            serviceCid={data.service_cid}
            condition_block={data.condition_block}
          />
        )}
        {/* //Модальное окно с пакетами */}

        {/* //Подключенные пакеты */}
        <div className="connected-page__inner">
          <div className="connected-page__accordion-grid small">
            {addedPackets &&
              addedPackets.map((pac) => (
                <React.Fragment key={pac.packet_name}>
                  <div className="connected-page__accordion-item">
                    <div className="services-page__card-label">Название пакета</div>
                    <div className="connected-page__text"> {pac.packet_name}</div>
                  </div>
                  {pac.plan_fee !== null && (
                    <div className="connected-page__accordion-item border">
                      <div className="services-page__card-label">Стоимость</div>
                      <div className="connected-page__text">
                        {paymentDisplay(pac.plan_fee, pac.fee_period, pac.fee_kind)}
                      </div>
                    </div>
                  )}
                </React.Fragment>
              ))}
          </div>
        </div>
        {/* //Подключенные пакеты */}

        {/* //Оборудование, планировщик, инфоблок о планировке */}
        {data.equipment && (
          <ServiceEquipment data={data?.equipment} service_cid={data.service_cid} />
        )}
        {data.schedule && <ServiceSchedule data={data.schedule} />}
        {data.info_block && <ServiceInfoBlock data={data.info_block} />}
        {/* //Оборудование, планировщик, инфоблок о планировке */}

        {/* //Опции/ Редактировать */}
        <div className="connected-page__inner flex gap-8">
          {data.show_plan_change_btn && (
            <div className="connected-page__btn">
              <Button
                text="Редактировать"
                theme="outline"
                size="small"
                onClick={handleOptionsClick}
              />
            </div>
          )}
          {data.show_dop_options && (
            <div className="connected-page__btn">
              <Button
                text="Опции"
                theme="outline"
                size="small"
                href={SERVICEAVAILABLEOPTIONS + '/' + data.service_cid}
              />
            </div>
          )}
        </div>
        {/* //Опции/ Редактировать */}
      </>
    )
  );
};

export default TvOtt;
