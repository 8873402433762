import { FC, useState } from 'react';
import SidebarRight from '../../sidebarRight/SidebarRight';
import Button from 'src/components/ui/button/Button';
import FeedBackCallbackSuccess from './success/FeedBackCallbackSuccess';
import $api from 'src/api/service/request';
import { API_DOMAIN } from 'src/api/service/endpoints';
import InputPhone from 'src/components/ui/inputPhone/InputPhone';
import { validatePhoneNumber } from 'src/utils.js/validatePhoneNumber';
import './FeedBackCallback.scss';
import { ContactsResponse } from 'src/types/swagger';
import { getFromLocalStorage } from 'src/api/service/helpers/localStorage.helper';

interface IProps {
  state: boolean;
  setState: (prev: any) => void;
  data: ContactsResponse | null;
}
const FeedBackCallback: FC<IProps> = ({ state, setState, data }) => {
  const [isLoading, setIsLoading] = useState(false);
  // Модальное окно ответа
  const [successActive, setSuccessActive] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [variant, setVariant] = useState<'success' | 'error'>('success');
  // Модальное окно ответа

  // Поле телефона
  const [phoneValue, setPhoneValue] = useState('');
  const [phoneError, setPhoneError] = useState('');
  // Поле телефона

  const handleCloseClick = () => {
    setState(false);

    setTimeout(() => {
      setSuccessActive((prev) => !prev);
      setPhoneValue('');
      setPhoneError('');
    }, 300);
  };

  //Ответ после отправки заявки
  const handleSubmit = (message: string, variant: 'success' | 'error') => {
    setSuccessMessage(message);
    setVariant(variant);
    setSuccessActive((prev) => !prev);
  };
  //Ответ после отправки заявки

  //Отправка заявки
  const onSubmit = async () => {
    if (validatePhoneNumber(phoneValue) === false) {
      setPhoneError('Введите номер телефона');
      return;
    }
    setIsLoading((prev) => !prev);

    try {
      const response = await $api.post(
        `${API_DOMAIN}clients/${getFromLocalStorage('agreement')}/feedback/callback/`,
        {
          contact_phone: phoneValue,
        }
      );

      const { data } = response.data;
      if (data) {
        handleSubmit(data.title, 'success');
        setPhoneError('');
      }
    } catch (error: any) {
      console.error(error);
      handleSubmit(error.response.data.client_message, 'error');
    } finally {
      setIsLoading(false);
    }
  };
  //Отправка заявки

  return (
    <SidebarRight state={state} setState={setState} overlayOff={true}>
      <div
        className="feedback-modal fancybox-content r-feedback-modal"
        data-fancy-modal="feedback"
        style={{ display: 'inline-block' }}
      >
        <div className="feedback-modal__inner">
          {!successActive && (
            <div className="feedback-modal__top">
              <div
                className="feedback-modal__top-icon"
                onClick={() => {
                  setState((prev: boolean) => !prev);
                }}
              >
                <svg
                  className="feedback-modal__top-svg"
                  width={24}
                  height={24}
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M19 10.9998H9L12.29 7.70981C12.3837 7.61685 12.4581 7.50625 12.5089 7.38439C12.5597 7.26253 12.5858 7.13183 12.5858 6.99981C12.5858 6.8678 12.5597 6.7371 12.5089 6.61524C12.4581 6.49338 12.3837 6.38278 12.29 6.28982C12.1026 6.10356 11.8492 5.99902 11.585 5.99902C11.3208 5.99902 11.0674 6.10356 10.88 6.28982L6.59 10.5898C6.21441 10.9632 6.00223 11.4702 6 11.9998C6.00487 12.5259 6.21684 13.0289 6.59 13.3998L10.88 17.6998C10.9732 17.7924 11.0838 17.8657 11.2054 17.9155C11.3269 17.9654 11.4571 17.9908 11.5885 17.9904C11.7199 17.9899 11.8499 17.9636 11.9712 17.9128C12.0924 17.8621 12.2024 17.788 12.295 17.6948C12.3876 17.6016 12.4609 17.491 12.5107 17.3694C12.5606 17.2479 12.586 17.1177 12.5856 16.9863C12.5851 16.8549 12.5588 16.7249 12.508 16.6036C12.4573 16.4824 12.3832 16.3724 12.29 16.2798L9 12.9998H19C19.2652 12.9998 19.5196 12.8945 19.7071 12.7069C19.8946 12.5194 20 12.265 20 11.9998C20 11.7346 19.8946 11.4802 19.7071 11.2927C19.5196 11.1052 19.2652 10.9998 19 10.9998Z"
                    fill="#0076D8"
                  />
                </svg>
              </div>
              <div className="feedback-modal__top-text">Обратная связь</div>
            </div>
          )}
          {!successActive && (
            <div className="feedback-modal__block active">
              <div className="feedback-modal__call">
                <div className="feedback-modal__call-top">
                  <div className="feedback-modal__call-form">
                    <div className="feedback-modal__call-heading">
                      Перезвоним в ближайшее время!
                    </div>
                    <div className="feedback-modal__call-text">
                      Укажите номер, на который можно перезвонить
                    </div>
                    <div className="feedback-modal__call-input">
                      <InputPhone
                        setValue={setPhoneValue}
                        value={phoneValue}
                        error={phoneError}
                        placeholder="Телефон"
                        submitHidden={true}
                      />
                    </div>
                    <div className="feedback-modal__call-btns">
                      <div className="feedback-modal__call-btn">
                        <Button text={'Продолжить'} onClick={onSubmit} isLoading={isLoading} />
                      </div>
                      <div className="feedback-modal__call-btn" onClick={() => setState(false)}>
                        <div className="button button--outline">Отменить</div>
                      </div>
                    </div>
                    <div className="feedback-modal__call-caption">
                      Нажимая “Продолжить” я соглашаюсь с{' '}
                      <a className="feedback-modal__call-link" href={data?.privacy_policy_url}>
                        политикой конфиденциальности
                      </a>
                    </div>
                  </div>
                </div>
                <div className="feedback-modal__call-bot">
                  {data?.contact_center && (
                    <div className="feedback-modal__call-item">
                      {data.contact_center.title}
                      {': '}
                      <a href={data.contact_center.phone}>{data.contact_center.phone}</a>
                    </div>
                  )}
                  {data?.technical_support && (
                    <div className="feedback-modal__call-item">
                      {data.technical_support.title}
                      {': '}
                      <a href={data.technical_support.phone}>{data.technical_support.phone}</a>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}

          {successActive && (
            <FeedBackCallbackSuccess
              handleCloseClick={handleCloseClick}
              text={successMessage}
              variant={variant}
            />
          )}
        </div>
      </div>
    </SidebarRight>
  );
};

export default FeedBackCallback;
