import { FC } from 'react';

export interface ICategory {
  category_code: string;
  category_name: string;
  quantity: number;
}

interface IProps {
  callback: (item: any) => void;
  data: ICategory[];
  currentCategory: ICategory;
}

const Categories: FC<IProps> = ({ data, callback, currentCategory }) => {
  return (
    data && (
      <div className="services-page__categories">
        <div className="categories">
          {data.map((item: ICategory, index: number) => (
            <div className="categories__item" key={index} onClick={() => callback(item)}>
              <div
                className={`categories__btn ${
                  item.category_code === currentCategory?.category_code ? 'active' : ''
                }`}
              >
                {item.category_name} {'('}
                {item.quantity}
                {')'}
              </div>
            </div>
          ))}
        </div>
      </div>
    )
  );
};

export default Categories;
