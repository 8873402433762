import { Navigate, Outlet } from 'react-router-dom';
import { LOGIN } from './data';
import { getFromLocalStorage } from 'src/api/service/helpers/localStorage.helper';

const PrivateRoute = () => {
  const token = getFromLocalStorage('token');
  return token ? <Outlet /> : <Navigate to={LOGIN} />;
};

export default PrivateRoute;
