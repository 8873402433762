import ServiceStatus from 'src/components/common/services/components/ui/status/ServiceStatus';
import CopyButton from 'src/components/ui/copyButton/CopyButton';
import Svg from 'src/components/ui/svg/Svg';
import { paymentDisplay } from 'src/utils.js/paymentDisplay';
import './TvAtv.scss';
import { FC } from 'react';
import TooltipUi from 'src/components/ui/tooltip/TooltipUi';
import { AtvGroup, ServiceTypeEnum } from 'src/types/swagger';
import Button from 'src/components/ui/button/Button';
import {
  SERVICEAVAILABLEOPTIONS,
  SERVICESPAUSE,
  SERVICEUNBLOCK,
  TVEXPENSES,
} from 'src/routes/data';
import ServiceSchedule from 'src/components/common/services/components/serviceSchedule/ServiceSchedule';
import ServiceInfoBlock from 'src/components/common/services/components/serviceInfoBlock/ServiceInfoBlock';

interface IProps {
  data: AtvGroup;
  isCommonAgreement?: boolean;
  commonAgreement?: number | null;
}
const TvAtv: FC<IProps> = ({ data, isCommonAgreement, commonAgreement }) => {
  return (
    data && (
      <>
        <div className="atv tv-block">
          <div className="atv__inner">
            <div className="atv__header">
              <div className="atv__svg">
                <Svg type={'atv'} />
              </div>
              <div className="atv__header-row">
                <h2 className="atv__title">{data.service_title}</h2>
                {isCommonAgreement && commonAgreement && <CopyButton text={commonAgreement} />}
              </div>
            </div>

            <div className="atv__block">
              {data.services?.map((item) => (
                <div className="atv-item" key={item.service_cid}>
                  <div className="atv-item__inner">
                    <div className="atv-item__row">
                      <div className="atv-item__left">
                        <div className="atv-item__status">
                          <ServiceStatus status={item.service_status} />
                          {item.service_agr && !isCommonAgreement && (
                            <CopyButton text={item.service_agr} />
                          )}
                        </div>

                        <div className="atv-item__title">
                          <p className="atv-item__gray">Тариф</p>
                          <div className="atv-item__title-row">
                            <h3> {item.plan?.plan_name}</h3>
                            {item.plan?.plan_descr && <TooltipUi text={item.plan?.plan_descr} />}
                          </div>
                        </div>
                      </div>
                      <div className="atv-item__right">
                        {item.additional_info && (
                          <div className="atv-item__description atv-item__description_desk">
                            <p className="atv-item__gray">{item.additional_info}</p>
                          </div>
                        )}

                        <div className="atv-item__block">
                          {item?.plan && item.plan.plan_fee !== null && (
                            <div className="atv-item__block-item">
                              <p className="atv-item__gray">Cтоимость</p>
                              <p className="atv-item__black">
                                {paymentDisplay(
                                  item.plan.plan_fee,
                                  item.plan.fee_period,
                                  item.plan.fee_kind
                                )}
                              </p>
                            </div>
                          )}

                          {item.additional_info && (
                            <div className="atv-item__description atv-item__description_mob">
                              <p className="atv-item__gray">{item.additional_info}</p>
                            </div>
                          )}

                          {item.plan?.start_date && (
                            <div className="atv-item__block-item">
                              <p className="atv-item__gray">Дата подключения</p>
                              <p className="atv-item__black">{item.plan?.start_date}</p>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    {/* // планировщик, инфоблок о планировке */}
                    {item.schedule && <ServiceSchedule data={item.schedule} />}
                    {item.info_block && <ServiceInfoBlock data={item.info_block} />}
                    {/* //Оборудование, планировщик, инфоблок о планировке */}

                    <div className="atv-item__controls">
                      {/* // Опции */}
                      {item.show_dop_options && (
                        <div className="connected-page__btn">
                          <Button
                            text="Опции"
                            theme="outline"
                            href={SERVICEAVAILABLEOPTIONS + '/' + item.service_cid}
                          />
                        </div>
                      )}
                      {/* // Опции */}

                      {/* //Шильдики */}
                      {item.additional_buttons.map((item) => (
                        <a
                          key={item.caption}
                          className="button react-button undefined button--outline    "
                          href={item.data}
                          target="blank"
                        >
                          {item.caption}
                        </a>
                      ))}
                      {/* //Шильдики */}

                      {/* //Расходы */}

                      {item.service_type === ServiceTypeEnum.CollectiveAtv &&
                        process.env.REACT_APP_ENV !== 'production' && (
                          <Button
                            text="Расходы"
                            theme="outline"
                            href={TVEXPENSES + '/' + item.service_agr}
                          />
                        )}

                      {/* //Расходы */}

                      <div className="atv-item__block">
                        {item.show_block_btn && (
                          <Button
                            text="Приостановить"
                            theme="outline"
                            href={SERVICESPAUSE + '/' + item.service_cid}
                          />
                        )}
                        {item.show_unblock_btn && (
                          <Button
                            text="Возобновить"
                            theme="outline"
                            href={SERVICEUNBLOCK + '/' + item.service_cid}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </>
    )
  );
};

export default TvAtv;
