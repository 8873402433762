import { FC, useEffect, useState } from 'react'

import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'
import { removeFromLocalStorage } from 'src/api/service/helpers/localStorage.helper'
import Loader from 'src/components/ui/loader/Loader'
import { useAppDispatch, useAppSelector } from 'src/hooks/redusHooks'
import { asyncNotifications } from 'src/redux/slices/notificationsSlice'
import { Announce, AvailableService, ServiceSummary } from 'src/types/swagger'

import AnnounceUi from '../../ui/announce/Announce'
import AvailableCard from '../services/components/availableCard/AvailableCard'
import ServicePreviewCard from '../services/components/previewCard/ServicePreviewCard'

import './Home.scss'
import HomeBalance from './components/balance/HomeBalance'

const Home: FC = () => {
	const { clientData, loading } = useAppSelector(store => store.client)
	const [serviceList, setServiceList] = useState<ServiceSummary[] | null>(null)
	const [availableServices, setAvailableServices] = useState<AvailableService[] | null>()
	const [announces, setAnnounces] = useState<Announce[] | null>(null)
	const dispatch = useAppDispatch()
	const [isLoading, setIsLoading] = useState(false)

	//уведомления для пользователя
	useEffect(() => {
		if (clientData?.data.announces) {
			const announcesData = clientData.data.announces

			// Дополнительная проверка на null для каждого элемента массива
			const sortedAnnounces = announcesData
				.filter(announce => announce != null)
				.sort((a, b) => {
					return a.announce_id - b.announce_id
				})

			setAnnounces(sortedAnnounces)
		}
	}, [clientData])
	//уведомления для пользователя

	useEffect(() => {
		removeFromLocalStorage('password')
	}, [])

	//FIXME:
	useEffect(() => {
		if (clientData) {
			const { service_order, ...services } = clientData.data.services

			const keys: string[] = Object.values(service_order)
			const newArray: any[] = []

			keys.forEach(key => {
				//@ts-ignore
				const current = services[key]
				if (!current) return

				current.forEach((item: any) => {
					newArray.push(item)
				})
			})
			setServiceList(newArray)

			const available = clientData?.data?.available_services || []

			const availableServices = available
				.filter((service: any) => service !== undefined && service !== null)
				.sort((a, b) => {
					if (a.order === undefined || b.order === undefined) {
						return 0
					}
					return a.order - b.order
				})

			setAvailableServices(availableServices)
		}
	}, [clientData])

	//Уведомления (системные)
	useEffect(() => {
		;(async () => {
			setIsLoading(true)
			await dispatch(asyncNotifications())
			setIsLoading(false)
		})()
	}, [])

	//Лоадер
	if (loading || isLoading) {
		return <Loader state={true} />
	}
	//Лоадер

	return (
		clientData && (
			<div className="main-page">
				<section className="section section--first">
					<div className="container react-container-announce">
						{announces &&
							announces.map(item => (
								<AnnounceUi key={item.announce_id} text={item.message} />
							))}
					</div>
				</section>
				<section className="main-page__section">
					<div className="container">
						<div className="main-page__row">
							<div className="main-page__col">
								<h1 className="main-page__title">
									Здравствуйте,
									<br />
									<span>{clientData?.data.fio}</span>
								</h1>
								<div className="main-page__services-address">
									Доступные услуги по адресу:&nbsp;
									<br />
									<span>{`${clientData?.data?.building_name} ${
										clientData?.data?.flat ? clientData?.data?.flat : ''
									} ${clientData?.data?.apart ? clientData?.data?.apart : ''}`}</span>
								</div>
							</div>

							<HomeBalance
								agreement={clientData?.data?.agreement}
								binfo={clientData?.data?.binfo}
							/>
						</div>
					</div>
				</section>

				<section className="section main-page__section">
					<div className="container">
						<h3 className="section__title">Мои услуги</h3>
						<Tabs>
							<div className="main-page__services-top">
								<TabList>
									<div className="tabs tabs--wide">
										<div className="tabs__list">
											<Tab selectedClassName="active" className="tabs__item">
												Подключенные
											</Tab>
											{clientData?.data?.available_services &&
												clientData?.data?.available_services.length > 0 && (
													<Tab
														selectedClassName="active"
														className="tabs__item"
													>
														Доступные
													</Tab>
												)}
										</div>
									</div>
								</TabList>
							</div>
							<TabPanel>
								<div className="main-page__services-inner">
									<div className="block active">
										<div className="service-grid">
											{serviceList &&
												serviceList.map((service, index) => (
													<ServicePreviewCard
														data={service}
														key={index}
													/>
												))}
										</div>
									</div>
								</div>
							</TabPanel>

							<TabPanel>
								<div className="main-page__services-inner">
									<div className="block active">
										{availableServices && (
											<div className="service-grid ">
												{availableServices.map((service, index) => (
													<AvailableCard data={service} key={index} />
												))}
											</div>
										)}
									</div>
								</div>
							</TabPanel>
						</Tabs>
					</div>
				</section>
			</div>
		)
	)
}

export default Home
