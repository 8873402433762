import { FC, useEffect, useState } from 'react';
import Button from 'src/components/ui/button/Button';
import Modal from 'src/components/ui/modal/Modal';
import { paymentDisplay } from 'src/utils.js/paymentDisplay';
import { Link } from 'react-router-dom';
import { CONDITIONSPAGE } from 'src/routes/data';
import CheckboxDefault from 'src/components/ui/checkbox/CheckboxDefault';
import { RecAdditionalModule } from 'src/types/swagger';
import Loader from 'src/components/ui/loader/Loader';
import TooltipUi from 'src/components/ui/tooltip/TooltipUi';
import { Condition } from 'src/types/Condition';
import ConditionUi from 'src/components/ui/conditionUI/ConditionUi';

//стили этого компонента тянутся с модального с тв
interface IProps {
  data: RecAdditionalModule[];
  state: boolean;
  setState: (prev: any) => void;
  condition_block?: Condition | null;
}

interface IModules {
  key: string | null;
  plan_id: number;
  cost: number | null;
}

const VideoChangeModules: FC<IProps> = ({ state, setState, data, condition_block }) => {
  const [selectedPackets, setSelectedPackets] = useState<IModules[] | []>([]);
  const [sum, setSum] = useState<number | null>(null);
  //Лоадер
  const [isLoading, setIsLoading] = useState(false);
  const [isLoaderTransparent, setIsLoaderTransparent] = useState(false);
  //Лоадер
  const [isLoadingButton, setIsLoadingButton] = useState(false);
  const [isPacketModalActive, setPacketModalActive] = useState(false);

  //Функция для закрытия модального окна с пакетами
  const handleExit = () => {
    setPacketModalActive(false);
    setTimeout(() => {
      setState((prev: boolean) => !prev);
    }, 600);
  };
  //Функция для закрытия модального окна с пакетами

  //Открытие модального окна при заходе на страницу
  useEffect(() => {
    if (state) {
      setPacketModalActive(true);
    }
  }, [state]);
  //Открытие модального окна при заходе на страницу

  //Обработка данных при заходе
  useEffect(() => {
    if (data) {
      const newArr: IModules[] = [];
      data.map((item) => {
        if (item.module_set === true) {
          newArr.push({
            key: item?.key,
            plan_id: item?.plan_id,
            cost: item?.plan_fee,
          });
        }
        return null;
      });
      setSelectedPackets(newArr);
    }
  }, [data]);
  //Обработка данных при заходе

  //Обработка суммы
  useEffect(() => {
    //@ts-ignore
    const newSum = selectedPackets.reduce((acc: number, item: IModules) => {
      if (item.cost !== null && item.cost !== undefined) {
        return acc + item.cost;
      }
      return acc;
    }, 0);

    setSum(newSum);
  }, [selectedPackets]);
  //Обработка суммы

  //колбек для изменения чекбокса
  const handleCheckboxChange = (props: RecAdditionalModule) => {
    const { key, plan_id, plan_fee: cost } = props;
    const newPacket = { key, plan_id, cost };

    const isSelected = selectedPackets.some((item) => item.plan_id === plan_id);

    if (isSelected) {
      setSelectedPackets(selectedPackets.filter((item) => item.plan_id !== plan_id));
    } else {
      setSelectedPackets([...selectedPackets, newPacket]);
    }
  };
  //колбек для изменения чекбокса

  //FIXME: нет поинта на отправку
  //Отправка формы для изменения пакетов
  // const onSubmit = async () => {
  //   setIsLoadingButton((prev) => !prev);

  //   const newData = selectedPackets.map((item) => {
  //     const { cost, ...rest } = item;
  //     return item;
  //   });
  //   try {
  //     const response = await $api.put(
  //       `${API_DOMAIN}services/${serviceType}/${serviceCid}/subscription/`,
  //       {
  //         packets: [...newData],
  //       }
  //     );

  //     const { data } = response.data;
  //     handleResponseModal(data.title, data.description);
  //   } catch (error: any) {
  //     console.error('Failed to change packets:', error.message);
  //     handleResponseModal(error.response.data.client_message, '', true);
  //   } finally {
  //     setIsLoadingButton((prev) => !prev);
  //   }
  // };
  //Отправка формы для изменения пакетов

  //Обработчик лоадера
  const handleLoader = (state: boolean, isTransparent?: boolean) => {
    setIsLoading(state);
    setIsLoaderTransparent(isTransparent || false);
  };
  //Обработчик лоадера

  return (
    data && (
      <>
        <Loader state={isLoading} />
        {/* //вывод пакетов */}
        <Modal state={isPacketModalActive} setState={handleExit}>
          <div
            className="modules-modal modal fancybox-content react-tvChangePacket"
            data-fancy-modal="modules"
            data-tv-modal=""
            style={{ display: 'inline-block' }}
          >
            <div className="modules-modal__container">
              <div className="modules-modal__title">Дополнительные модули</div>
              <div className="modules-modal__grid">
                {data.map((item) => (
                  <div className="modules-modal__item" key={item.key}>
                    <div className="modules-modal__top">
                      <CheckboxDefault
                        text={item.module_name}
                        defaultValue={item.module_set}
                        // disabled={!item.}
                        callback={() => handleCheckboxChange(item)}
                      />
                    </div>
                    <div className="modules-modal__inner">
                      {item.plan_fee !== null && (
                        <div className="modules-modal__price">
                          <div className="services-page__card-label">Стоимость</div>
                          <div className="connected-page__text">
                            {paymentDisplay(item.plan_fee, item.fee_period, item.fee_kind)}
                          </div>
                        </div>
                      )}
                      {item.module_description && (
                        <div className="modules-modal__wrapper">
                          <TooltipUi text={item.module_description} type={'more'} />
                        </div>
                      )}
                    </div>
                  </div>
                ))}
              </div>
              <div className="modules-modal__bot">
                <div className="connected-page__bot">
                  {/* //buttons */}
                  <div className="connected-page__bot-col">
                    <div className="connected-page__bot-item">
                      <div className="connected-page__btn">
                        <Button text={'Сохранить'} size={'small'} isLoading={isLoadingButton} />
                      </div>
                    </div>
                    <div className="connected-page__bot-item">
                      <div className="connected-page__btn">
                        <Button
                          text={'Отменить'}
                          theme={'outline'}
                          size={'small'}
                          onClick={handleExit}
                        />
                      </div>
                    </div>
                  </div>
                  {/* //buttons */}

                  <div className="connected-page__bot-col">
                    <div className="services-page__card-label">Итоговая стоимость</div>

                    <div className="connected-page__heading">{sum || 0} ₽/мес</div>
                  </div>
                </div>
              </div>

              {/* //ссылка на условия  */}
              <ConditionUi data={condition_block} />
              {/* //ссылка на условия  */}
            </div>
            <div className="modal__icon" data-fancybox-close="" onClick={handleExit}>
              <svg className="modal__svg" width={24} height={24} viewBox="0 0 24 24" fill="none">
                <path
                  d="M21.756 2.24399C21.5997 2.08776 21.3878 2 21.1668 2C20.9459 2 20.7339 2.08776 20.5777 2.24399L12 10.8216L3.42235 2.24399C3.26607 2.08776 3.05414 2 2.83317 2C2.6122 2 2.40027 2.08776 2.24399 2.24399C2.08776 2.40027 2 2.6122 2 2.83317C2 3.05414 2.08776 3.26607 2.24399 3.42235L10.8216 12L2.24399 20.5777C2.08776 20.7339 2 20.9459 2 21.1668C2 21.3878 2.08776 21.5997 2.24399 21.756C2.40027 21.9122 2.6122 22 2.83317 22C3.05414 22 3.26607 21.9122 3.42235 21.756L12 13.1784L20.5777 21.756C20.7339 21.9122 20.9459 22 21.1668 22C21.3878 22 21.5997 21.9122 21.756 21.756C21.9122 21.5997 22 21.3878 22 21.1668C22 20.9459 21.9122 20.7339 21.756 20.5777L13.1784 12L21.756 3.42235C21.9122 3.26607 22 3.05414 22 2.83317C22 2.6122 21.9122 2.40027 21.756 2.24399Z"
                  fill="#001F38"
                />
              </svg>
            </div>
            <button
              type="button"
              data-fancybox-close=""
              className="fancybox-button fancybox-close-small"
              title="Close"
            >
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <path d="M13 12l5-5-1-1-5 5-5-5-1 1 5 5-5 5 1 1 5-5 5 5 1-1z" />
              </svg>
            </button>
          </div>
        </Modal>
        {/* //вывод пакетов */}
      </>
    )
  );
};

export default VideoChangeModules;
