import { FC } from "react";
import { AutopayStatusEnum } from "src/types/swagger";
interface IProps {
  status: AutopayStatusEnum;
  msg_autopay: string | null;
}

const AutoPaymentStatus: FC<IProps> = ({ status, msg_autopay }) => {
  if (status === AutopayStatusEnum.Active) {
    return status && <span className="label inline">{msg_autopay}</span>;
  } else if (status === AutopayStatusEnum.Paused) {
    return (
      status && (
        <span className="label inline status_suspensed">{msg_autopay}</span>
      )
    );
  } else if (status === AutopayStatusEnum.NotConnected) {
    return (
      status && (
        <span className="label inline status_suspensed">{msg_autopay}</span>
      )
    );
  } else {
    return null;
  }
};

export default AutoPaymentStatus;
