import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import Button from 'src/components/ui/button/Button';
import CopyButton from 'src/components/ui/copyButton/CopyButton';
import Input from 'src/components/ui/input/Input';
import { CONDITIONSPAGE, PAYMENTCLOSE } from 'src/routes/data';
import './PaymentReplenishment.scss';
import { FC, useEffect, useState } from 'react';
import { Billing } from 'src/types/swagger';

import {
  getFromSessionStorage,
  removeFromSessionStorage,
  setToSessionStorage,
} from 'src/api/service/helpers/localSession.helper';
import $api from 'src/api/service/request';
import { API_DOMAIN } from 'src/api/service/endpoints';
import { getFromLocalStorage } from 'src/api/service/helpers/localStorage.helper';
import useModal from 'src/hooks/useModal';
import ConditionUi from 'src/components/ui/conditionUI/ConditionUi';

interface IProps {
  data: Billing;
}
const PaymentReplenishment: FC<IProps> = ({ data }) => {
  const {
    register,
    watch,
    getValues,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
  });

  const [isLoading, setIsLoading] = useState(false);
  const { open } = useModal();

  useEffect(() => {
    const sessionData = getFromSessionStorage('PaymentReplenishment');

    setValue('email', sessionData?.email || data.email);
    setValue('amount', sessionData?.amount || 10);
  }, []);

  // const clearSession = removeFromSessionStorage('PaymentReplenishment');

  //Сохранение данных в sessionStorage
  const setSession = () => {
    setToSessionStorage('PaymentReplenishment', {
      amount: getValues('amount'),
      email: getValues('email'),
    });
  };
  //Сохранение данных в sessionStorage

  const onSubmit = async () => {
    setIsLoading(true);

    const body = {
      amount: getValues('amount'),
      email: getValues('email'),
    };
    (async () => {
      try {
        const response = await $api.post(
          `${API_DOMAIN}clients/${getFromLocalStorage('agreement')}/payments/create-payment/`,
          body
        );
        const { data } = response.data;
        removeFromSessionStorage('PaymentReplenishment');

        if (data.payment_url) {
          open({
            text: data.redirect_text,
            modalType: 'success',
            href: data.payment_url,
          });
        }
      } catch (error: any) {
        open({
          text: error.response?.data?.data?.title || error.response.data.client_message || 'Ошибка',
          description: error.response?.data?.data?.description || '',
          modalType: 'error',
        });
      } finally {
        setIsLoading(false);
      }
    })();
  };

  return (
    data && (
      <>
        <form className="payment-page__row" onSubmit={handleSubmit(onSubmit)}>
          <div className="payment-page__col">
            <div className="section__container payment-page__column">
              <div className="payment-page__wrapper payment-page__column-wrapper">
                <div className="payment-page__column-top">
                  <h2 className="section__title">Пополнение баланса</h2>
                  <div className="payment-page__refill">
                    <div className="payment-page__refill-row">
                      <div className="payment-page__refill-col">
                        <Input
                          placeholder="Адрес доставки чека"
                          register={register}
                          filled={!!watch('email')}
                          error={errors.email}
                          name="email"
                          validation={{
                            required: 'Заполните поле',
                            pattern: {
                              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                              message: 'Неверный формат',
                            },
                          }}
                        />
                      </div>
                      <div className="payment-page__refill-col">
                        <div className="payment-page__label">Номер договора </div>
                        {data.agreement && (
                          <CopyButton text={data.agreement} size={true} variant={2} />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="payment-page__column-bot">
                  <div className="react-pay-item">
                    {data.other_payments.show_link_url && (
                      <a
                        className="payment-page__link"
                        href={data.other_payments.payment_url}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {data.other_payments.text}
                      </a>
                    )}
                  </div>
                  <div className="react-pay-item">
                    <Link className="payment-page__link" to={PAYMENTCLOSE}>
                      Заявить об ошибочном платеже
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="payment-page__col">
            <div className="section__container payment-page__column">
              <div className="payment-page__pay">
                <Input
                  placeholder="Сумма пополнения"
                  register={register}
                  filled={!!watch('amount')}
                  name="amount"
                  error={errors.amount}
                  type="number"
                  validation={{
                    required: 'Заполните поле',
                    pattern: {
                      value: /^\d+$/,
                      message: 'Неверный формат',
                    },
                    min: {
                      value: 10,
                      message: 'Минимальная сумма 10 рублей',
                    },
                    max: {
                      value: 30000,
                      message: 'Максимальная сумма 30 000 рублей',
                    },
                  }}
                />
                <div className="payment-page__pay-offset">
                  <div className="payment-page__pay-row">
                    <div className="payment-page__pay-heading">Итого к оплате:</div>
                    <div className="payment-page__pay-price">{watch('amount') + ' ₽'}</div>
                  </div>
                </div>
                <div className="payment-page__pay-btn">
                  <Button text={'Пополнить'} type="submit" isLoading={isLoading} />
                </div>
                {/* //ссылка на условия  */}
                <ConditionUi data={data.condition_block} />
                {/* //ссылка на условия  */}
              </div>
            </div>
          </div>
          <input
            type="text"
            name="agr_n"
            value={data.agreement}
            readOnly
            style={{ opacity: 0, zIndex: '-2', position: 'absolute', bottom: 0 }}
          />
        </form>
      </>
    )
  );
};

export default PaymentReplenishment;
