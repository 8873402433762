//@ts-nocheck
//TSFIX:
import { Calendar, CalendarPropsRange } from 'primereact/calendar';
import { addLocale } from 'primereact/api';
import 'primereact/resources/themes/lara-light-cyan/theme.css';
import './Calendar.scss';
import Button from '../button/Button';
import { FC, useEffect, useRef } from 'react';
import InputError from '../inputError/InputError';

interface IProps {
  value: Date | Date[] | (Date | null)[] | null | undefined;
  setValue: any;
  isRange?: boolean;
  svg: boolean;
  callback?: () => void;
  onClick?: () => void;
  isButtonVisible?: boolean;
  isError?: boolean;
  minDate?: Date;
  maxDate?: Date;
}
const CalendarUi: FC<IProps> = ({
  value,
  setValue,
  isRange,
  svg,
  callback,
  onClick,
  isButtonVisible,
  isError,
  minDate,
  maxDate,
}) => {
  addLocale('ru', {
    firstDayOfWeek: 1,
    dayNames: ['воскресенье', 'понедельник', 'вторник', 'среда', 'четверг', 'пятница', 'суббота'],
    dayNamesShort: ['вс', 'пн', 'вт', 'ср', 'чт', 'пт', 'сб'],
    dayNamesMin: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
    monthNames: [
      'Январь',
      'Февраль',
      'Март',
      'Апрель',
      'Май',
      'Июнь',
      'Июль',
      'Август',
      'Сентябрь',
      'Октябрь',
      'Ноябрь',
      'Декабрь',
    ],
    monthNamesShort: [
      'янв',
      'фев',
      'мар',
      'апр',
      'май',
      'июн',
      'июл',
      'авг',
      'сен',
      'окт',
      'ноя',
      'дек',
    ],
    today: 'Сегодня',
    clear: 'Очистить',
  });

  const calendarRef = useRef(null);

  const handleChangeDate: (e: any) => void = (e) => {
    setValue(e);
    if (callback) {
      callback();
    }
  };

  return (
    <div className={`react-calendar ${isRange ? 'react-calendar_range' : ''}`}>
      <div className="react-calendar__inner">
        <div className="react-calendar__input">
          {svg && (
            <svg
              onClick={() => calendarRef.current.show()}
              xmlns="http://www.w3.org/2000/svg"
              width={24}
              height={24}
              viewBox="0 0 24 24"
              fill="none"
              style={{ flexShrink: 0, cursor: 'pointer' }}
            >
              <path
                d="M17.8333 3.66667H17V2.83333C17 2.61232 16.9122 2.40036 16.7559 2.24408C16.5996 2.0878 16.3877 2 16.1667 2C15.9457 2 15.7337 2.0878 15.5774 2.24408C15.4211 2.40036 15.3333 2.61232 15.3333 2.83333V3.66667H8.66667V2.83333C8.66667 2.61232 8.57887 2.40036 8.42259 2.24408C8.26631 2.0878 8.05435 2 7.83333 2C7.61232 2 7.40036 2.0878 7.24408 2.24408C7.0878 2.40036 7 2.61232 7 2.83333V3.66667H6.16667C5.062 3.66799 4.00296 4.1074 3.22185 4.88852C2.44073 5.66963 2.00132 6.72867 2 7.83333L2 17.8333C2.00132 18.938 2.44073 19.997 3.22185 20.7782C4.00296 21.5593 5.062 21.9987 6.16667 22H17.8333C18.938 21.9987 19.997 21.5593 20.7782 20.7782C21.5593 19.997 21.9987 18.938 22 17.8333V7.83333C21.9987 6.72867 21.5593 5.66963 20.7782 4.88852C19.997 4.1074 18.938 3.66799 17.8333 3.66667ZM3.66667 7.83333C3.66667 7.17029 3.93006 6.53441 4.3989 6.06557C4.86774 5.59673 5.50363 5.33333 6.16667 5.33333H17.8333C18.4964 5.33333 19.1323 5.59673 19.6011 6.06557C20.0699 6.53441 20.3333 7.17029 20.3333 7.83333V8.66667H3.66667V7.83333ZM17.8333 20.3333H6.16667C5.50363 20.3333 4.86774 20.0699 4.3989 19.6011C3.93006 19.1323 3.66667 18.4964 3.66667 17.8333V10.3333H20.3333V17.8333C20.3333 18.4964 20.0699 19.1323 19.6011 19.6011C19.1323 20.0699 18.4964 20.3333 17.8333 20.3333Z"
                fill="#001F38"
              />
              <path
                d="M12 16C12.5523 16 13 15.5523 13 15C13 14.4477 12.5523 14 12 14C11.4477 14 11 14.4477 11 15C11 15.5523 11.4477 16 12 16Z"
                fill="#001F38"
              />
              <path
                d="M8 16C8.55228 16 9 15.5523 9 15C9 14.4477 8.55228 14 8 14C7.44772 14 7 14.4477 7 15C7 15.5523 7.44772 16 8 16Z"
                fill="#001F38"
              />
              <path
                d="M16 16C16.5523 16 17 15.5523 17 15C17 14.4477 16.5523 14 16 14C15.4477 14 15 14.4477 15 15C15 15.5523 15.4477 16 16 16Z"
                fill="#001F38"
              />
            </svg>
          )}
          <Calendar
            ref={calendarRef}
            value={value}
            onChange={(e: any) => handleChangeDate(e.value)}
            locale="ru"
            dateFormat="dd.mm.yy"
            readOnlyInput
            selectionMode={isRange ? 'range' : 'single'}
            minDate={minDate}
            maxDate={maxDate}
          />
        </div>

        {isError && <InputError text="Заполните поле" />}
      </div>

      {isButtonVisible === false ? (
        ''
      ) : (
        <Button text={'Показать'} size={'small'} onClick={onClick} />
      )}
    </div>
  );
};

export default CalendarUi;
