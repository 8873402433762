import { AxiosResponse } from 'axios';
import { FC, useState } from 'react';

import { API_DOMAIN } from 'src/api/service/endpoints';
import { getFromLocalStorage } from 'src/api/service/helpers/localStorage.helper';
import $api from 'src/api/service/request';

import Loader from 'src/components/ui/loader/Loader';
import useModal from 'src/hooks/useModal';
import { BaseResponse, Phone, PhoneUpdateRequest } from 'src/types/swagger';
import { validatePhoneNumber } from 'src/utils.js/validatePhoneNumber';
import ProfilePhoneItem from './blocks/ProfilePhoneItem';

//TASK: перенести на телефон

//смена телефона

interface IProps {
  data: Phone[];
  notice: string | null;
  type: 'base' | 'additional';
}

const ProfilePhones: FC<IProps> = ({ data, type, notice }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { open } = useModal();

  //Изменение телефона
  const changePhone = async (data: { oldPhone: string; phone: string }) => {
    const { oldPhone, phone } = data;
    const newPhone = validatePhoneNumber(phone);

    setIsLoading((prev) => !prev);

    const body: PhoneUpdateRequest = {
      old_phone: oldPhone,
      new_phone: newPhone,
    };

    try {
      const response: AxiosResponse<BaseResponse> = await $api.put(
        `${API_DOMAIN}clients/${getFromLocalStorage('agreement')}/profile/contacts/phone/`,
        body
      );

      const { data } = response;

      open({
        text: data?.data?.title || data?.client_message || 'Изменения сохранены',
        modalType: 'success',
        isReload: true,
        description: data?.data?.description || '',
      });
    } catch (error: any) {
      open({
        text: error.response?.data?.data?.title || error.response.data.client_message || 'Ошибка',
        description: error.response?.data?.data?.description || '',
        modalType: 'error',
      });
    } finally {
      setIsLoading(false);
    }
  };
  //Изменение телефона

  //Добавление телефона
  const addPhone = async (data: { oldPhone: string; phone: string }) => {
    const newPhone = validatePhoneNumber(data.phone);
    setIsLoading((prev) => !prev);

    try {
      const response: AxiosResponse<BaseResponse> = await $api.post(
        `${API_DOMAIN}clients/${getFromLocalStorage('agreement')}/profile/contacts/phone/`,
        {
          phone: newPhone,
        }
      );
      const { data } = response;
      open({
        text: data?.data?.title || data?.client_message || 'Изменения сохранены',
        modalType: 'success',
        isReload: true,
        description: data?.data?.description || '',
      });
    } catch (error: any) {
      console.info('error', error);
      open({
        text: error.response?.data?.data?.title || error.response.data.client_message || 'Ошибка',
        description: error.response?.data?.data?.description || '',
        modalType: 'error',
      });
    } finally {
      setIsLoading(false);
    }
  };
  //Добавление телефона

  return (
    <>
      {/* //лоадер */}
      <Loader state={isLoading} />
      {/* //лоадер */}

      {data?.length > 0 &&
        data?.map((item, index) => (
          <ProfilePhoneItem
            data={item}
            type={type}
            onSubmit={changePhone}
            variant="change"
            key={item.phone_number + index}
          />
        ))}
      {/* //Выводим если нет дополнительного телефона */}
      {!data && type === 'additional' && (
        <ProfilePhoneItem data={null} type={type} onSubmit={addPhone} variant="add" />
      )}

      {!data && type === 'base' && (
        <div className="profile-page__block-item">
          <div>
            <div className="section__label">Основной телефон</div>
            <div className="profile-page__text">Не указан</div>
            <div className="section__label mt-6">
              {notice || 'Вы можете изменить основной номер телефона через наш контактный центр'}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ProfilePhones;
