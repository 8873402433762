import CopyButton from 'src/components/ui/copyButton/CopyButton';
import Svg from 'src/components/ui/svg/Svg';
import ServiceStatus from '../../components/ui/status/ServiceStatus';
import React, { FC, useEffect, useState } from 'react';
import Accordion from 'src/components/ui/accordion/Accordion';
import { paymentDisplay } from 'src/utils.js/paymentDisplay';
import Button from 'src/components/ui/button/Button';
import {
  SERVICEAVAILABLEOPTIONS,
  SERVICECHANGETARIFF,
  SERVICESPAUSE,
  SERVICEUNBLOCK,
} from 'src/routes/data';
import { RecAdditionalModule, ServiceRec, ServicesRec } from 'src/types/swagger';
import ServiceInfoBlock from '../../components/serviceInfoBlock/ServiceInfoBlock';
import ServiceSchedule from '../../components/serviceSchedule/ServiceSchedule';
import ServiceEquipment from '../../components/serviceEquipment/ServiceEquipment';
import VideoChangeModules from '../changeModules/VideoChangeModules';
import { Condition } from 'src/types/Condition';
import useModal from 'src/hooks/useModal';

//TODO: нет поинта оптравки у пакта

interface IProps {
  data: ServicesRec;
}
const VideoConnected: FC<IProps> = ({ data }) => {
  const [videoList, setVideoList] = useState<ServiceRec[] | null>(null);
  const [isChangeModalActive, setIsChangeModalActive] = useState(false);
  const [changeModalList, setChangeModalList] = useState<RecAdditionalModule[] | undefined>([]);
  const [condition, setCondition] = useState<Condition | null>();
  const { open } = useModal();

  //Определение очередности при загрузке
  useEffect(() => {
    if (data) {
      const key = Object.values(data.service_order);
      const arr: ServiceRec[] = [];
      key.map((key) => {
        data.rec?.filter((item) => {
          if (item.service_cid === key) {
            arr.push(item);
          }
        });
      });

      setVideoList(arr);
    }
  }, [data]);
  //Определение очередности при загрузке

  // Открытие модального окна с модулями
  const handleModalChangeModulesOpen = (serviceCid: string) => {
    const targetRec = data.rec?.filter((item) => item.service_cid === serviceCid);

    setChangeModalList(targetRec?.[0].modules_additional);
    setIsChangeModalActive((prev) => !prev);
    setCondition(targetRec?.[0].condition_block);
  };
  // Открытие модального окна с модулями

  //филтрация модулей при выводе на стр ( подлкюченные )
  const moduleFiltering = (arr: RecAdditionalModule[]) => {
    const filteredArr = arr.filter((item) => {
      return item.module_set === true;
    });

    return filteredArr;
  };
  //филтрация модулей при выводе на стр ( подлкюченные )

  return (
    videoList && (
      <>
        {isChangeModalActive && (
          <VideoChangeModules
            state={isChangeModalActive}
            setState={setIsChangeModalActive}
            data={changeModalList || []}
            condition_block={condition}
          />
        )}
        <div className="services-page__inner">
          <div className="services-page__block active">
            <div className="connected-page__container">
              <div className="connected-page__top">
                <div className="connected-page__top-col">
                  <div className="connected-page__icon">
                    <Svg type={'rec'} />
                  </div>
                </div>
                <div className="connected-page__top-col tooltip">
                  {data.service_agr && <CopyButton text={data.service_agr} />}
                  <div className="connected-page__top-label">
                    <ServiceStatus status={data.service_status} />
                  </div>
                </div>
              </div>
              <div className="connected-page__head">
                <div className="connected-page__head-col">
                  <h3 className="section__title">{data.service_title}</h3>
                </div>
                {data.additional_info && (
                  <div className="connected-page__head-col alt">
                    <div className="section__label default">{data.additional_info}</div>
                  </div>
                )}
              </div>
              <div className="connected-page__inner">
                {/* //Итерация по камерам */}
                {videoList.map((item) => (
                  <Accordion
                    title={item.camera_name}
                    key={item.service_cid}
                    active={data.focus_service_cid === item.service_cid}
                  >
                    <div
                      className="accordion__body"
                      data-accordion-dropdown=""
                      style={{ display: 'block' }}
                    >
                      <div className="connected-page__wrapper small">
                        <div className="connected-page__grid">
                          {item.plan && (
                            <div className="connected-page__grid-item">
                              <div className="services-page__card-label">Тариф</div>
                              <div className="connected-page__heading underline">
                                {item.plan.plan_name}
                              </div>
                            </div>
                          )}
                          {item.plan && item.plan.plan_fee !== null && (
                            <div className="connected-page__grid-item">
                              <div className="services-page__card-label">Стоимость</div>
                              <div className="connected-page__text">
                                {paymentDisplay(
                                  item.plan.plan_fee,
                                  item.plan.fee_period,
                                  item.plan.fee_kind
                                )}
                              </div>
                            </div>
                          )}
                          {item?.plan?.start_date && (
                            <div className="connected-page__grid-item">
                              <div className="services-page__card-label">Дата подключения</div>
                              <div className="connected-page__text">{item.plan.start_date}</div>
                            </div>
                          )}
                        </div>
                      </div>
                      {/* //Основные модули */}
                      {item.modules_base && (
                        <div className="connected-page__wrapper">
                          <div className="connected-page__accordion-heading">Основные модули</div>
                          <div className="connected-page__inner">
                            <div className="connected-page__accordion-caption">
                              {item.modules_base.description}
                            </div>
                          </div>

                          {item.modules_base.modules &&
                            item.modules_base.modules.map((module) => (
                              <div className="connected-page__inner" key={module.module_name}>
                                <div className="services-page__card-label">Название</div>
                                <div className="connected-page__text">{module.module_name}</div>
                              </div>
                            ))}
                        </div>
                      )}
                      {/* //Основные модули */}

                      {/* //Дополнительные модули */}
                      {item.modules_additional && (
                        <div className="connected-page__wrapper">
                          <div className="connected-page__accordion-heading">
                            Дополнительные модули
                          </div>
                          <div className="connected-page__inner">
                            <div className="connected-page__accordion-grid">
                              {moduleFiltering(item.modules_additional).map((module, index) => {
                                return (
                                  <React.Fragment key={module.key}>
                                    <div className="connected-page__accordion-item">
                                      <div className="services-page__card-label">Название</div>
                                      <div className="connected-page__text">
                                        {module.module_name}
                                      </div>
                                    </div>
                                    <div className="connected-page__accordion-item border">
                                      <div className="services-page__card-label">Стоимость</div>
                                      <div className="connected-page__text">
                                        {paymentDisplay(
                                          module.plan_fee,
                                          module.fee_period,
                                          module.fee_kind
                                        )}
                                      </div>
                                    </div>
                                  </React.Fragment>
                                );
                              })}
                            </div>
                          </div>
                          {item.show_options_change_btn &&
                            process.env.REACT_APP_ENV !== 'production' && (
                              <div className="connected-page__inner big">
                                <div className="connected-page__btn">
                                  <Button
                                    text={'Настройка модулей'}
                                    size={'small'}
                                    theme={'outline'}
                                    onClick={() => handleModalChangeModulesOpen(item.service_cid)}
                                  />
                                </div>
                              </div>
                            )}
                          {item.show_options_change_btn &&
                            process.env.REACT_APP_ENV === 'production' && (
                              <div className="connected-page__inner big">
                                <div className="connected-page__btn">
                                  <Button
                                    text={'Настройка модулей'}
                                    size={'small'}
                                    theme={'outline'}
                                    onClick={() =>
                                      open({
                                        text: 'Этот раздел находится в разработке.',
                                        modalType: null,
                                        description:
                                          'Попробуйте позже или обратитесь в контактный центр по номеру: 8 (812) 595-81-21',
                                      })
                                    }
                                  />
                                </div>
                              </div>
                            )}
                        </div>
                      )}
                      {/* //Дополнительные модули */}

                      {/* //Оборудование, планировщик, инфоблок о планировке */}
                      {item.equipment && (
                        <ServiceEquipment data={item?.equipment} service_cid={item.service_cid} />
                      )}
                      {item.schedule && <ServiceSchedule data={item.schedule} />}
                      {item.info_block && <ServiceInfoBlock data={item.info_block} />}
                      {/* //Оборудование, планировщик, инфоблок о планировке */}

                      {/* //Блок кнопок */}
                      <div className="connected-page__inner">
                        <div className="connected-page__bot">
                          <div className="connected-page__bot-col">
                            {item.show_plan_change_btn && (
                              <div className="connected-page__bot-item">
                                <div className="connected-page__btn">
                                  <Button
                                    text="Сменить тариф"
                                    size="small"
                                    theme="outline"
                                    href={SERVICECHANGETARIFF + '/' + item.service_cid}
                                  />
                                </div>
                              </div>
                            )}
                            {item.show_block_btn && (
                              <div className="connected-page__bot-item">
                                <div className="connected-page__btn">
                                  <Button
                                    text={'Приостановить'}
                                    size={'small'}
                                    theme={'outline'}
                                    href={SERVICESPAUSE + '/' + item.service_cid}
                                  />
                                </div>
                              </div>
                            )}
                            {item.show_unblock_btn && (
                              <div className="connected-page__bot-item">
                                <div className="connected-page__btn">
                                  <Button
                                    text="Возобновить"
                                    theme="outline"
                                    href={SERVICEUNBLOCK + '/' + item.service_cid}
                                    size="small"
                                  />
                                </div>
                              </div>
                            )}

                            {item.show_dop_options && (
                              <div className="connected-page__bot-item">
                                <div className="connected-page__btn">
                                  <Button
                                    text="Опции"
                                    size="small"
                                    theme="outline"
                                    href={SERVICEAVAILABLEOPTIONS + '/' + item.service_cid}
                                  />
                                </div>
                              </div>
                            )}
                          </div>

                          {item.total_cost && (
                            <div className="connected-page__bot-col">
                              <div className="services-page__card-label">Итоговая стоимость</div>
                              <div className="connected-page__heading">
                                {paymentDisplay(
                                  item.total_cost.cost,
                                  item.total_cost.fee_period,
                                  item.total_cost.fee_kind
                                )}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                      {/* //Блок кнопок */}
                    </div>
                  </Accordion>
                ))}
                {/* //Итерация по камерам */}
              </div>
            </div>
          </div>
        </div>
      </>
    )
  );
};

export default VideoConnected;
