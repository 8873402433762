import { FC } from 'react'

import { useNavigate } from 'react-router-dom'
import Button from 'src/components/ui/button/Button'
import Modal from 'src/components/ui/modal/Modal'
import { IModalData } from 'src/redux/slices/modalSlice'

import './ResponseModal.scss'

interface IProps {
	state: boolean
	setState: (prev: any) => void
	data: IModalData
}

const ResponseModal: FC<IProps> = ({ state, setState, data }) => {
	const navigate = useNavigate()
	if (!data) return null

	const {
		text,
		modalType,
		description,
		isReload,
		redirect,
		text_html,
		href,
		goBack
	} = data

	const closeHandler = () => {
		setState((prev: any) => !prev)
		if (redirect) {
			navigate(redirect)
			return
		}
		console.info(12312321, goBack)
		if (goBack) {
			console.info(234)
			navigate(-1)
		}

		if (href) {
			window.open(href, '_blank')
			return
		}

		if (isReload) {
			window.location.reload()
		}
	}

	return (
		data && (
			<Modal state={state} setState={setState} callback={closeHandler}>
				<div
					className="response-modal options-modal r-response-modal"
					data-response="success"
					style={{
						display: 'inline-block',
						backgroundColor: 'white'
					}}
				>
					<div className="response-modal__container">
						{modalType === 'success' ? (
							<div className="response-modal__icon">
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width={41}
									height={40}
									viewBox="0 0 41 40"
									fill="none"
								>
									<g clipPath="url(#clip0_5348_4143)">
										<circle
											cx="20.5"
											cy={20}
											r={20}
											fill="#56BD68"
										/>
										<path
											d="M17.6056 23.9211L13.3523 19.8476C13.1488 19.6527 12.8728 19.5432 12.585 19.5432C12.2973 19.5432 12.0213 19.6527 11.8178 19.8476C11.6143 20.0425 11.5 20.3068 11.5 20.5824C11.5 20.8581 11.6143 21.1224 11.8178 21.3173L16.071 25.3908C16.2725 25.584 16.5119 25.7372 16.7753 25.8417C17.0387 25.9462 17.321 26 17.6061 26C17.8912 26 18.1735 25.9462 18.4369 25.8417C18.7003 25.7372 18.9397 25.584 19.1412 25.3908L29.1822 15.7741C29.3857 15.5792 29.5 15.3148 29.5 15.0392C29.5 14.7636 29.3857 14.4992 29.1822 14.3043C28.9787 14.1095 28.7027 14 28.415 14C28.1272 14 27.8512 14.1095 27.6477 14.3043L17.6056 23.9211Z"
											fill="white"
										/>
									</g>
									<defs>
										<clipPath id="clip0_5348_4143">
											<rect
												width={40}
												height={40}
												fill="white"
												transform="translate(0.5)"
											/>
										</clipPath>
									</defs>
								</svg>
							</div>
						) : modalType === 'error' ? (
							<div className="response-modal__icon">
								<svg
									width={40}
									height={40}
									viewBox="0 0 40 40"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<g clipPath="url(#clip0_3770_35008)">
										<circle
											cx={20}
											cy={20}
											r={20}
											fill="#EC003E"
										/>
										<g clipPath="url(#clip1_3770_35008)">
											<path
												d="M26.1913 14.309C26.1002 14.2179 25.9765 14.1667 25.8476 14.1667C25.7187 14.1667 25.5951 14.2179 25.504 14.309L20.5003 19.3126L15.4967 14.309C15.4055 14.2179 15.2819 14.1667 15.153 14.1667C15.0241 14.1667 14.9005 14.2179 14.8093 14.309C14.7182 14.4002 14.667 14.5238 14.667 14.6527C14.667 14.7816 14.7182 14.9052 14.8093 14.9964L19.813 20L14.8093 25.0036C14.7182 25.0948 14.667 25.2184 14.667 25.3473C14.667 25.4762 14.7182 25.5998 14.8093 25.691C14.9005 25.7821 15.0241 25.8333 15.153 25.8333C15.2819 25.8333 15.4055 25.7821 15.4967 25.691L20.5003 20.6874L25.504 25.691C25.5951 25.7821 25.7187 25.8333 25.8476 25.8333C25.9765 25.8333 26.1002 25.7821 26.1913 25.691C26.2825 25.5998 26.3337 25.4762 26.3337 25.3473C26.3337 25.2184 26.2825 25.0948 26.1913 25.0036L21.1877 20L26.1913 14.9964C26.2825 14.9052 26.3337 14.7816 26.3337 14.6527C26.3337 14.5238 26.2825 14.4002 26.1913 14.309Z"
												fill="white"
											/>
											<path
												d="M26.1913 14.309C26.1002 14.2179 25.9765 14.1667 25.8476 14.1667C25.7187 14.1667 25.5951 14.2179 25.504 14.309L20.5003 19.3126L15.4967 14.309C15.4055 14.2179 15.2819 14.1667 15.153 14.1667C15.0241 14.1667 14.9005 14.2179 14.8093 14.309C14.7182 14.4002 14.667 14.5238 14.667 14.6527C14.667 14.7816 14.7182 14.9052 14.8093 14.9964L19.813 20L14.8093 25.0036C14.7182 25.0948 14.667 25.2184 14.667 25.3473C14.667 25.4762 14.7182 25.5998 14.8093 25.691C14.9005 25.7821 15.0241 25.8333 15.153 25.8333C15.2819 25.8333 15.4055 25.7821 15.4967 25.691L20.5003 20.6874L25.504 25.691C25.5951 25.7821 25.7187 25.8333 25.8476 25.8333C25.9765 25.8333 26.1002 25.7821 26.1913 25.691C26.2825 25.5998 26.3337 25.4762 26.3337 25.3473C26.3337 25.2184 26.2825 25.0948 26.1913 25.0036L21.1877 20L26.1913 14.9964C26.2825 14.9052 26.3337 14.7816 26.3337 14.6527C26.3337 14.5238 26.2825 14.4002 26.1913 14.309Z"
												fill="white"
											/>
										</g>
									</g>
									<defs>
										<clipPath id="clip0_3770_35008">
											<rect
												width={40}
												height={40}
												fill="white"
											/>
										</clipPath>
										<clipPath id="clip1_3770_35008">
											<rect
												width={14}
												height={14}
												fill="white"
												transform="translate(13.5 13)"
											/>
										</clipPath>
									</defs>
								</svg>
							</div>
						) : modalType === 'tv' ? (
							<div className="response-modal__icon">
								<svg
									className="modules-modal__empty-svg"
									width={41}
									height={40}
									viewBox="0 0 41 40"
									fill="none"
								>
									<path
										d="M30.7083 8H10.2917C8.35851 8.00217 6.50519 8.72373 5.13824 10.0064C3.77129 11.2891 3.00232 13.0281 3 14.8421L3 23.0526C3.00232 24.8666 3.77129 26.6057 5.13824 27.8883C6.50519 29.171 8.35851 29.8926 10.2917 29.8947H19.0417V31.2632H14.6667C14.2799 31.2632 13.909 31.4073 13.6355 31.664C13.362 31.9206 13.2083 32.2687 13.2083 32.6316C13.2083 32.9945 13.362 33.3426 13.6355 33.5992C13.909 33.8558 14.2799 34 14.6667 34H26.3333C26.7201 34 27.091 33.8558 27.3645 33.5992C27.638 33.3426 27.7917 32.9945 27.7917 32.6316C27.7917 32.2687 27.638 31.9206 27.3645 31.664C27.091 31.4073 26.7201 31.2632 26.3333 31.2632H21.9583V29.8947H30.7083C32.6415 29.8926 34.4948 29.171 35.8618 27.8883C37.2287 26.6057 37.9977 24.8666 38 23.0526V14.8421C37.9977 13.0281 37.2287 11.2891 35.8618 10.0064C34.4948 8.72373 32.6415 8.00217 30.7083 8Z"
										fill="#0076D8"
									/>
									<path
										d="M37.2936 10C37.2936 14.5794 33.5813 18.2917 29.002 18.2917C24.4226 18.2917 20.7103 14.5794 20.7103 10C20.7103 5.42064 24.4226 1.70833 29.002 1.70833C33.5813 1.70833 37.2936 5.42064 37.2936 10Z"
										fill="#EC003E"
										stroke="white"
										strokeWidth="3.41667"
									/>
									<path
										d="M29 6L29 14"
										stroke="white"
										strokeWidth={2}
										strokeLinecap="round"
									/>
									<path
										d="M25 10L33 10"
										stroke="white"
										strokeWidth={2}
										strokeLinecap="round"
									/>
								</svg>
							</div>
						) : (
							''
						)}

						{text_html ? (
							<div
								className="react-conditions-content"
								dangerouslySetInnerHTML={{ __html: text_html! }}
							/>
						) : (
							<>
								<div className="response-modal__title">
									{text}
								</div>
								{description && (
									<p className="r-response-modal__description">
										{description}
									</p>
								)}
							</>
						)}

						<div className="response-modal__button">
							<Button
								text="Ок"
								onClick={closeHandler}
								type="button"
							/>
						</div>
					</div>
					<div
						className="response-modal__close"
						onClick={closeHandler}
					>
						<svg
							className="response-modal__close-svg"
							xmlns="http://www.w3.org/2000/svg"
							width={24}
							height={24}
							viewBox="0 0 24 24"
							fill="none"
						>
							<path
								d="M21.756 2.24399C21.5997 2.08776 21.3878 2 21.1668 2C20.9459 2 20.7339 2.08776 20.5777 2.24399L12 10.8216L3.42235 2.24399C3.26607 2.08776 3.05414 2 2.83317 2C2.6122 2 2.40027 2.08776 2.24399 2.24399C2.08776 2.40027 2 2.6122 2 2.83317C2 3.05414 2.08776 3.26607 2.24399 3.42235L10.8216 12L2.24399 20.5777C2.08776 20.7339 2 20.9459 2 21.1668C2 21.3878 2.08776 21.5997 2.24399 21.756C2.40027 21.9122 2.6122 22 2.83317 22C3.05414 22 3.26607 21.9122 3.42235 21.756L12 13.1784L20.5777 21.756C20.7339 21.9122 20.9459 22 21.1668 22C21.3878 22 21.5997 21.9122 21.756 21.756C21.9122 21.5997 22 21.3878 22 21.1668C22 20.9459 21.9122 20.7339 21.756 20.5777L13.1784 12L21.756 3.42235C21.9122 3.26607 22 3.05414 22 2.83317C22 2.6122 21.9122 2.40027 21.756 2.24399Z"
								fill="#001F38"
							/>
						</svg>
					</div>
					<button
						type="button"
						onClick={closeHandler}
						className="fancybox-button fancybox-close-small"
						title="Close"
					>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							viewBox="0 0 24 24"
						>
							<path d="M13 12l5-5-1-1-5 5-5-5-1 1 5 5-5 5 1 1 5-5 5 5 1-1z" />
						</svg>
					</button>
				</div>
			</Modal>
		)
	)
}

export default ResponseModal
