import { createSelector } from '@reduxjs/toolkit'

import { RootState } from 'src/redux/store'

export const selectData = (state: RootState) => state.serviceChange.data
export const selectSortedTariffs = (state: RootState) => state.serviceChange.sortedTariffs
export const selectLoading = (state: RootState) => state.serviceChange.loading
export const selectTariff = (state: RootState) => state.serviceChange.selectTariff
export const selectAbonement = (state: RootState) => state.serviceChange.selectAbonement

export const selectChangeTariff = createSelector(
	[selectData, selectSortedTariffs, selectTariff, selectAbonement, selectLoading],
	(data, sortedTariffs, selectTariff, selectAbonement, loading) => {
		const abonementList = selectTariff?.abonements || []

		const sortedTariffsWithSelect = sortedTariffs?.map(item => {
			return { ...item, value: item.plan_id, label: item.plan_full_name }
		})

		const selectTariffWithSelect = {
			value: selectTariff?.plan_id,
			label: selectTariff?.plan_full_name,
			...selectTariff
		}

		const abonementListWithSelect = abonementList.map(item => {
			return { ...item, value: item.plan_id, label: item.abonement_full_name }
		})

		const currentAbonementWitchSelect =
			selectAbonement === null
				? {
						value: abonementList[0]?.plan_id,
						label: abonementList[0]?.abonement_full_name,
						...abonementList[0]
					}
				: {
						value: selectAbonement.plan_id,
						label: selectAbonement.abonement_full_name,
						...selectAbonement
					}

		return {
			data,
			loading,
			sortedTariffsWithSelect,
			selectTariffWithSelect,
			abonementListWithSelect,
			currentAbonementWitchSelect
		}
	}
)
