//@ts-ignore
import InputMask from 'react-input-mask';
import InputError from '../inputError/InputError';
import { FC } from 'react';
import Button from '../button/Button';
import './InputPhone.scss';

interface IProps {
  placeholder?: string;
  type?: string;
  variant?: string;
  error?: string;
  name?: string | number;
  value: string;
  setValue: (value: string) => void;
  isControlsShown?: boolean;
  submitHidden?: boolean;
  onClickClose?: () => void;
  onClickSubmit?: () => void;
}
const InputPhone: FC<IProps> = ({
  placeholder,
  type = 'text',
  variant,
  error,
  name,
  value,
  setValue,
  submitHidden,
  isControlsShown,
  onClickClose,
  onClickSubmit,
  ...props
}) => {
  return (
    <div className="relative r-input-phone">
      <div
        className={`input-wrapper ${value ? 'filled' : ''} ${
          variant === 'border' ? '' : 'input-wrapper--secondary'
        }`}
      >
        <div className="input-wrapper__placeholder">{placeholder}</div>

        <InputMask
          mask="+7 (999) 999-99-99"
          className="input"
          value={value ?? ''}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setValue(e.target.value)}
        >
          {/* {(inputProps: React.InputHTMLAttributes<HTMLInputElement>) => (
            <input
              className="input"
              placeholder={placeholder}
              type={type}
              {...inputProps}
              {...props}
            />
          )} */}
        </InputMask>
        {/* {!submitHidden && (
          <button type="submit" className="connected-page__service-icon">
            <svg
              className="connected-page__service-svg"
              width={24}
              height={24}
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M20.5745 6.23322L9.05963 17.1836C8.98221 17.2575 8.89021 17.3162 8.7889 17.3562C8.68759 17.3962 8.57897 17.4168 8.46926 17.4168C8.35956 17.4168 8.25094 17.3962 8.14963 17.3562C8.04832 17.3162 7.95632 17.2575 7.8789 17.1836L3.42595 12.9447C3.34853 12.8708 3.25653 12.8121 3.15522 12.7721C3.05391 12.7321 2.94529 12.7115 2.83559 12.7115C2.72588 12.7115 2.61726 12.7321 2.51595 12.7721C2.41464 12.8121 2.32264 12.8708 2.24522 12.9447C2.1675 13.0183 2.10582 13.1058 2.06374 13.2022C2.02166 13.2985 2 13.4018 2 13.5061C2 13.6105 2.02166 13.7138 2.06374 13.8101C2.10582 13.9065 2.1675 13.994 2.24522 14.0676L6.69983 18.3034C7.16975 18.7495 7.80657 19 8.47051 19C9.13446 19 9.77128 18.7495 10.2412 18.3034L21.7552 7.35536C21.8328 7.28174 21.8944 7.19429 21.9364 7.09802C21.9784 7.00174 22 6.89853 22 6.79429C22 6.69005 21.9784 6.58684 21.9364 6.49056C21.8944 6.39428 21.8328 6.30683 21.7552 6.23322C21.6778 6.1593 21.5858 6.10064 21.4845 6.06062C21.3832 6.0206 21.2745 6 21.1648 6C21.0551 6 20.9465 6.0206 20.8452 6.06062C20.7439 6.10064 20.6519 6.1593 20.5745 6.23322Z"
                fill="#0076D8"
              />
            </svg>
          </button>
        )} */}
        {isControlsShown && onClickSubmit && onClickClose && (
          <div className="r-input-phone__controls">
            <Button
              text="Отменить"
              theme="outline"
              size="small"
              type={'button'}
              onClick={onClickClose}
            />
            <Button text="Применить" size="small" type={'submit'} onClick={onClickSubmit} />
          </div>
        )}
      </div>
      {error && <InputError text={error} />}
    </div>
  );
};

export default InputPhone;
