import axios from 'axios'
import { ErrorEventEmitter } from 'src/App'
import { eventEmitter } from 'src/utils.js/eventEmitter'
import log from 'src/utils.js/log'

import { API_DOMAIN } from './endpoints'
import { getFromLocalStorage, setToLocalStorage } from './helpers/localStorage.helper'

const errorHandler = (body: ErrorEventEmitter) => {
	eventEmitter.emit('request-error', body)
}

const isProduction = process.env.REACT_APP_ENV === 'production'
//Флаг обновления токена
let isRefreshing = false

const $api = axios.create({
	withCredentials: true
})

$api.interceptors.request.use(
	async config => {
		const token = getFromLocalStorage('token')
		if (token) {
			config.headers.Authorization = `Bearer ${token}`
		}
		if (!isProduction) {
			log({
				name: config.url ?? 'undefined url',
				data: config,
				type: 'request',
				payload: config.data
			})
		}
		return config
	},
	error => {
		throw error
	}
)

$api.interceptors.response.use(
	response => {
		if (!isProduction) {
			log({
				name: response.config.url ?? 'undefined url',
				data: response,
				type: 'response'
			})
		}
		return response
	},
	error => {
		throw error
	}
)

async function handleResponseError(error: any) {
	const originalRequest = error.config
	const isSystemErrorHidden =
		error?.response?.is_system_error_hidden || error?.response?.data?.is_system_error_hidden

	//Уведомляем в консоли что от ПАКТ пришла ошибка с пометкой, что модальное окно не должно открываться
	if (isSystemErrorHidden) {
		console.log('Ошибка, заблокированная системой ПАКТ')
	}

	if (error.code === 'ERR_NETWORK' || error.message === 'Network Error') {
		if (!originalRequest._retry) {
			originalRequest._retry = true
			originalRequest._retryCount = (originalRequest._retryCount || 0) + 1

			if (!isProduction) console.log(`Retry attempt: ${originalRequest._retryCount}`)

			if (originalRequest._retryCount <= isProduction ? 2 : 3) {
				const delay = (retryCount: any) =>
					new Promise(res => setTimeout(res, Math.pow(2, retryCount) * 600))
				await delay(originalRequest._retryCount)
				originalRequest._retry = false
				return $api(originalRequest)
			} else {
				window.location.href = '/error'
			}
		}
	}

	if (error.response && error.response.status === 404) {
		window.location.href = '/404'
	}

	if (error.response.status === 500 || error.status === 500) {
		window.location.href = '/error'
	}

	if (error.response.status === 401 && error.response.data.error_code === 40103) {
		errorHandler({
			message: error.response.data.client_message,
			action: 'modal'
		})
	}

	if (error.response.status === 401 && error.response.data.error_code === 40101) {
		errorHandler({
			action: 'logout'
		})
	}

	if (
		error.response &&
		error.response.status === 401 &&
		error.response.data.error_code === 40100
	) {
		!isProduction && console.info('response 401 40100')

		if (!isRefreshing) {
			isRefreshing = true
			if (!originalRequest._retry) {
				originalRequest._retry = true

				console.info('response 401 !originalRequest._retry')

				const refreshToken = getFromLocalStorage('refresh')
				const login = getFromLocalStorage('agreement')
				!isProduction && console.info('response 401 !isRefreshing')

				try {
					const response = await $api.post(`${API_DOMAIN}auth/token/refresh/`, {
						refresh: refreshToken,
						login: login
					})
					!isProduction && console.info(response, 'refresh response')
					const { access, refresh } = response?.data?.data
					isRefreshing = false
					if (access && refresh) {
						setToLocalStorage('token', access)
						setToLocalStorage('refresh', refresh)
						!isProduction && console.info('response 401 access refresh success', access)
					}

					!isProduction && console.info('response 401 try')

					return $api(originalRequest)
				} catch (error) {
					!isProduction && console.info('response 401 catch', error)
					isRefreshing = false
					errorHandler({
						action: 'logout'
					})
				}
			}
		} else {
			await new Promise<void>(resolve => {
				const interval = setInterval(() => {
					if (!isRefreshing) {
						clearInterval(interval)
						resolve()
					}
				}, 100)
			})

			return $api(originalRequest)
		}

		!isProduction && console.info('response 401 end')
	}

	if (
		error.response &&
		error.response.status === 401 &&
		error.response.data.error_code === 40102
	) {
		return Promise.reject(error)
	}

	!isSystemErrorHidden &&
		errorHandler({
			message: error.response.data.client_message,
			action: 'modal'
			// goBack: true
		})
	return Promise.reject(error)
}

$api.interceptors.response.use(
	response => response,
	async error => {
		try {
			if (!isProduction) {
				logErrorDetails(error)
			}
			return await handleResponseError(error)
		} catch (error) {
			return Promise.reject(error)
		}
	}
)

function logErrorDetails(error: any) {
	log({
		name: axios.isAxiosError(error)
			? error.config?.url ?? 'undefined url'
			: 'Not instance of AxiosError',
		data: error,
		type: 'catch'
	})
}

export default $api
