import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { API_DOMAIN } from 'src/api/service/endpoints';
import $api from 'src/api/service/request';
import Panel from 'src/components/common/panel/Panel';
import Categories, { ICategory } from 'src/components/ui/categories/Categories';
import Loader from 'src/components/ui/loader/Loader';
import { SERVICEAVAILABLE, SERVICES } from 'src/routes/data';
import './Available.scss';
import SpecialOffer from '../specialOffer/SpecialOffer';
import { getFromLocalStorage } from 'src/api/service/helpers/localStorage.helper';
import {
  AvailablePlans,
  Channels,
  MainAvailablePlans,
  ResponseChannels,
  ResponseMainAvailablePlans,
} from 'src/types/swagger';
import { AxiosResponse } from 'axios';
import AvailableOptionsCard from '../../availableOptions/components/AvailableOptionsCard';
import AvailableOptionsBlock from '../../availableOptions/components/AvailableOptionsBlock';
import TvChannelList from '../../tv/ui/TvChannelList';

const Available = () => {
  const { service_type } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState<MainAvailablePlans | null>(null);
  const [categories, setCategories] = useState<ICategory[]>([]);
  const [currentCategory, setCurrentCategory] = useState<ICategory>();
  const [sortedData, setSortedData] = useState<AvailablePlans[]>();
  const [channelsData, setChannelsData] = useState<Channels | null>(null);
  const [isChannelsModalActive, setChannelsModalActive] = useState(false);

  //Первичные данные
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response: AxiosResponse<ResponseMainAvailablePlans> = await $api.get(
          `${API_DOMAIN}clients/${getFromLocalStorage(
            'agreement'
          )}/services/available/${service_type}/plans/`
        );

        const { data } = response.data;
        if (data) {
          setData(data);
        }
      } catch (error: any) {
        console.error('Failed to fetch VideoAvailable:', error.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);
  //Первичные данные

  //Получаем список категорий
  useEffect(() => {
    if (data) {
      const arr: ICategory[] = [];
      let allItems = 0;
      const uniqueCategories: { [key: string]: boolean } = {};

      for (const categoryCode in data.available_plans) {
        if (data.available_plans.hasOwnProperty(categoryCode)) {
          const category = data.available_plans[categoryCode];

          if (!uniqueCategories[categoryCode]) {
            uniqueCategories[categoryCode] = true;
            allItems += category.items?.length ?? 0;
            arr.push({
              category_code: category.category_code,
              category_name: category.category_name,
              quantity: category.items?.length ?? 0,
            });
          }
        }
      }
      //Получаем количество всех доступных планов

      const allItemsCategory = {
        category_code: '',
        category_name: 'Все',
        quantity: allItems,
      };
      arr.unshift(allItemsCategory);
      //Получаем количество всех доступных планов

      setCategories(arr);
      setCurrentCategory(allItemsCategory);
    }
  }, [data]);
  //Получаем список категорий

  // Сортировка и фильтрация
  useEffect(() => {
    if (data && data.available_plans) {
      const sortedData = Object.values(data.available_plans)
        .sort((a, b) => {
          return a.display_order - b.display_order;
        })
        .filter((item) => {
          if (currentCategory && currentCategory.category_code) {
            return item.category_code === currentCategory.category_code;
          } else {
            return true;
          }
        });

      setSortedData(sortedData);
    }
  }, [data, currentCategory]);
  //Сортировка и фильтрация

  //колбек для категорий
  const categoryCallback = (category: ICategory) => {
    setCurrentCategory(category);
  };
  //колбек для категорий

  //Запрос и вывод модального окна с каналами при нажатии
  const handleChannels = (key: string, serviceType: string) => {
    setIsLoading(true);
    const fetchData = async () => {
      try {
        const response: AxiosResponse<ResponseChannels> = await $api.get(
          `${API_DOMAIN}channels/${serviceType}/${key}`
        );
        const { data } = response.data;
        console.info('channels', DataTransfer);
        setChannelsData(data);
        setChannelsModalActive(true);
      } catch (error: any) {
        console.error('Failed to fetch channels:', error.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  };
  //Запрос и вывод модального окна с каналами при нажатии

  return (
    <>
      <Loader state={isLoading} />
      {/* //Модальное окно вывода каналов */}
      <TvChannelList
        data={channelsData!}
        state={isChannelsModalActive}
        setState={setChannelsModalActive}
      />
      {/* //Модальное окно вывода каналов */}
      {data && (
        <div className="services-page">
          <Panel linkText={'Услуги'} text={'Доступные'} href={SERVICEAVAILABLE} />

          <section className="section section--first-panel">
            <div className="container">
              <h3 className="section__title">{data.service_title}</h3>
              <div className="services-page__categories">
                {categories && categories.length > 1 && (
                  <Categories
                    data={categories}
                    callback={categoryCallback}
                    currentCategory={currentCategory!}
                  />
                )}
              </div>
              {/* //Специальное предложение */}
              {data.spec_offers && <SpecialOffer data={data.spec_offers} />}
              {/* //Специальное предложение */}
              <div className="services-page__list">
                {sortedData &&
                  sortedData.map((item, index) => {
                    if (item.is_block) {
                      return (
                        <div className="services-page__list" key={item.category_name}>
                          <div className="services-page__item">
                            <div className="services-page__item-heading">{item.category_name}</div>
                            <AvailableOptionsBlock
                              data={item}
                              service_title={data.service_title}
                              condition_block={data.condition_block}
                              service_type={data.service_type}
                            />
                          </div>
                        </div>
                      );
                    } else {
                      return (
                        <div className="services-page__list" key={item.category_name}>
                          <div className="services-page__item">
                            <div className="services-page__item-heading">{item.category_name}</div>
                            <div className="services-page__item-grid">
                              {item.items &&
                                item.items.map((card) => (
                                  <AvailableOptionsCard
                                    variant="available"
                                    key={card.plan_id}
                                    data={card}
                                    service_cid={''}
                                    category_code={item.category_code}
                                    service_type={data.service_type}
                                    service_title={data.service_title}
                                    condition_block={data.condition_block}
                                    handleChannels={handleChannels}
                                  />
                                ))}
                            </div>
                          </div>
                        </div>
                      );
                    }
                  })}
              </div>
            </div>
          </section>
        </div>
      )}
    </>
  );
};

export default Available;
