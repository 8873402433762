import Button from 'src/components/ui/button/Button';
import { FC, useEffect, useState } from 'react';
import $api from 'src/api/service/request';
import { API_DOMAIN } from 'src/api/service/endpoints';
import Loader from 'src/components/ui/loader/Loader';
import { AxiosResponse } from 'axios';
import { ExtraOptions, ExtraOptionsRequestRequest, ResponseExtraOptions } from 'src/types/swagger';
import Panel from 'src/components/common/panel/Panel';
import { useNavigate, useParams } from 'react-router-dom';
import ResponsePage from 'src/components/ui/responsePage/ResponsePage';
import { IModalData } from 'src/redux/slices/modalSlice';
import Input from 'src/components/ui/input/Input';
import { useForm } from 'react-hook-form';
import ConditionUi from 'src/components/ui/conditionUI/ConditionUi';
import ChangeTariffModal from '../../changeTariff/components/changeTariffModal/ChangeTariffModal';

const ServiceConnect: FC = () => {
  const navigate = useNavigate();
  const { variant, service_cid, id, category_code } = useParams();
  const [data, setData] = useState<ExtraOptions | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [modalData, setModalData] = useState<IModalData | null>(null);
  const [isChangeModalActive, setIsChangeModalActive] = useState(false);

  const {
    register,
    watch,
    getValues,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
  });

  const handleModal = (data: IModalData) => {
    setModalData(data);
  };

  //Запрос данных
  const fetchData = async () => {
    setIsLoading(true);
    try {
      const response: AxiosResponse<ResponseExtraOptions> = await $api.get(
        variant === 'equipment-pay'
          ? `${API_DOMAIN}services/rent/${service_cid}/payback/${id}/`
          : variant === 'ip'
          ? `${API_DOMAIN}services/eth/${service_cid}/external_ip/${id}/`
          : variant === 'extra_options'
          ? `${API_DOMAIN}services/${service_cid}/extra_options/${category_code}/${id}/`
          : variant === 'ip-delete'
          ? `${API_DOMAIN}services/eth/${service_cid}/external_ip/${id}/del/`
          : ''
      );

      const { data } = response.data;

      if (data) {
        setData(data);
      }
    } catch (error: any) {
      console.error('Failed to fetch serviceConnect:', error.message);
    } finally {
      setIsLoading(false);
    }
  };
  //Запрос данных

  useEffect(() => {
    setValue('phone', data?.contact_phone);
  }, [data]);

  // Первичная загрузка
  useEffect(() => {
    fetchData();
  }, []);
  // Первичная загрузка

  //Отправка формы

  const onSubmit = () => {
    const requestBody: ExtraOptionsRequestRequest = {
      connection_address: data?.connection_address!,
      full_name: data?.full_name!,
      additional_data: data?.additional_data,
      contact_phone: getValues('phone'),
      text_context: data?.text_context!,
    };

    const fetchData = async () => {
      setIsLoading(true);

      try {
        const response = await $api.post(
          variant === 'equipment-pay'
            ? `${API_DOMAIN}services/rent/${service_cid}/payback/${id}/`
            : variant === 'ip'
            ? `${API_DOMAIN}services/eth/${service_cid}/external_ip/${id}/`
            : variant === 'extra_options'
            ? `${API_DOMAIN}services/${service_cid}/extra_options/${category_code}/${id}/`
            : variant === 'ip-delete'
            ? `${API_DOMAIN}services/eth/${service_cid}/external_ip/${id}/del/`
            : '',
          requestBody
        );

        const { data } = response.data;

        handleModal({
          text: data?.data?.title || data?.client_message || data.title || 'Изменения сохранены',
          modalType: 'success',
          isReload: true,
          description: data?.data?.description || data.description || '',
        });

        return data;
      } catch (error: any) {
        handleModal({
          text:
            error?.response?.data?.data?.title || error.response.data.client_message || 'Ошибка',
          description: error?.response?.data?.data?.description || '',
          modalType: 'error',
        });
        throw error; // Ретируем ошибку, чтобы ее можно было обработать в вызывающем коде
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  };
  //Отправка формы

  //Лоадер
  if (isLoading) {
    return <Loader state={isLoading} />;
  }
  //Лоадер

  //Окно об успешной приостановке
  if (modalData) {
    return <ResponsePage data={modalData} />;
  }
  //Окно об успешной приостановке

  const handleSubmitChange = () => {
    if (data?.condition_block?.code === 'abonement') {
      setIsChangeModalActive(true);
    } else {
      onSubmit();
    }
  };
  return (
    <>
      <ChangeTariffModal
        title=""
        id={data?.condition_block?.code!}
        onSubmit={onSubmit}
        setState={setIsChangeModalActive}
        state={isChangeModalActive}
        checkboxes={data?.condition_block?.checkboxes}
      />
      {data && (
        <div className="services-page connected-page connected-page--default r-service-connect">
          <Panel text={data.title} onBack={true} linkText={'Услуги'} />
          <section className="section section--first-panel">
            <div className="container">
              <div className="section__container">
                <form className="services-page__delay" onSubmit={handleSubmit(handleSubmitChange)}>
                  <h1 className="section__title">{data.title}</h1>
                  <div className="connected-page__inner">
                    <div className="connected-page__accordion-heading">Данные абонента</div>
                    <div className="connected-page__inner">
                      <div className="services-page__delay-wrapper">
                        <div className="services-page__delay-inner">
                          <div className="services-page__delay-item">
                            <div className="services-page__card-label">ФИО</div>
                            <div className="connected-page__text">{data.full_name}</div>
                          </div>
                          <div className="services-page__delay-item">
                            <div className="services-page__card-label">Номер договора</div>
                            <div className="connected-page__text">{data.agreement}</div>
                          </div>
                          <div className="services-page__delay-item">
                            <Input
                              type="tel"
                              error={errors.phone}
                              register={register}
                              filled={!!watch('phone')}
                              validation={{ required: 'Введите номер телефона' }}
                              name="phone"
                              placeholder={'Номер телефона'}
                            />
                          </div>
                          <div className="services-page__delay-item">
                            <div className="services-page__card-label">Адрес подключения</div>
                            <div className="connected-page__text">{data.connection_address}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="connected-page__inner react-close-payment">
                    <div className="services-page__delay-item">
                      <div className="services-page__card-label">{data.title_context}</div>
                      <div className="connected-page__text r-service-connect__border">
                        <p>{data.text_context}</p>
                      </div>
                    </div>

                    <div className="services-page__delay-bot">
                      <div className="services-page__delay-btn">
                        <Button
                          text={'Отменить'}
                          size={'small'}
                          theme={'outline'}
                          onClick={() => {
                            navigate(-1);
                          }}
                        />
                      </div>
                      <div className="services-page__delay-btn">
                        <Button text={data.text_btn || 'Отправить'} size="small" type={'submit'} />
                      </div>
                    </div>

                    {/* //ссылка на условия  */}
                    <ConditionUi data={data.condition_block} />
                    {/* //ссылка на условия  */}
                  </div>
                </form>
              </div>
            </div>
          </section>
        </div>
      )}
    </>
  );
};

export default ServiceConnect;
