import { useEffect } from 'react'

import { useLocation, useNavigate } from 'react-router-dom'

import ConditionModal from './components/common/conditionModal/ConditionModal'
import FeedBack from './components/common/feedBack/FeedBack'
import BaseLayout from './components/layouts/baseLayout/BaseLayout'
import ConditionsLayout from './components/layouts/conditionsLayout/ConditionsLayout'
import ResponseModal from './components/ui/ResponseModal/ResponseModal'
import Loader from './components/ui/loader/Loader'
import { logoutF } from './features/logout/model/logout'
import { useAppDispatch, useAppSelector } from './hooks/redusHooks'
import Page404 from './pages/404/404'
import { Login } from './pages/auth/Login'
import RouteError from './pages/error/RouteError'
import Test from './pages/test/Test'
import { closeModal, openModal } from './redux/slices/modalSlice'
import { CONDITIONSPAGE } from './routes/data'
import { eventEmitter } from './utils.js/eventEmitter'

type RoutePaths = '/login' | '/error' | '/404' | '/test'

export interface ErrorEventEmitter {
	message?: string
	code?: number
	action: 'modal' | 'logout' | 'navigation'
	goBack?: boolean
	href?: string
}

const ROUTE_COMPONENTS: Record<RoutePaths, React.ComponentType> = {
	'/login': Login,
	'/error': RouteError,
	'/404': Page404,
	'/test': Test
}

const App = (): JSX.Element => {
	const { pathname } = useLocation()
	const { isOpen, modalData } = useAppSelector(state => state.modal)
	const {
		app: { isLoaderActive }
	} = useAppSelector(state => state.app)

	const dispatch = useAppDispatch()
	const navigate = useNavigate()

	useEffect(() => {
		// Подписка на событие
		eventEmitter.on('request-error', handleRequestError)
	}, [])

	const handleRequestError = (error: ErrorEventEmitter) => {
		if (error.action === 'modal') {
			dispatch(
				openModal({
					modalType: 'error',
					text: error?.message ? error.message : 'Неизвестная ошибка',
					goBack: error?.goBack || false
				})
			)
		}

		if (error.action === 'logout') {
			logoutF(dispatch)
		}

		if (error.action === 'navigation') {
			navigate(error?.href || 'Error')
		}
	}

	const isConditionsPage = pathname.startsWith(`${CONDITIONSPAGE}/`)
	const Component = isConditionsPage
		? ConditionsLayout
		: ROUTE_COMPONENTS[pathname as RoutePaths] || BaseLayout

	return (
		<div>
			<ResponseModal
				data={modalData}
				state={isOpen}
				setState={() => dispatch(closeModal())}
			/>
			<Loader state={isLoaderActive} />
			<ConditionModal />
			<Component />
			{pathname !== '/error' && <FeedBack />}
		</div>
	)
}

export default App
