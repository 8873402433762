import React, { FC } from 'react';
import { Equipment, StatusEnum } from 'src/types/swagger';
import { keyRender } from 'src/utils.js/keyRender';
import './ServiceEquipment.scss';
import { useNavigate } from 'react-router-dom';
import { SERVICECONNECTNEW } from 'src/routes/data';
import { paymentDisplay } from 'src/utils.js/paymentDisplay';

interface IProps {
  data: Equipment;
  service_cid: string | number;
}
const ServiceEquipment: FC<IProps> = ({ data, service_cid }) => {
  const navigate = useNavigate();
  return (
    <div className="connected-page__wrapper r-service-equipment">
      <div className="connected-page__accordion-heading">
        {data.title || 'Дополнительное оборудование'}
      </div>
      <div className="connected-page__inner">
        <div className="connected-page__accordion-grid">
          {data.equipments?.map((item) => (
            <React.Fragment key={keyRender()}>
              <div className="connected-page__accordion-item">
                <div className="services-page__card-label">Оборудование</div>
                <div className="connected-page__text">{item.name_equipment}</div>
              </div>
              {item.number_of_cameras && (
                <div className="connected-page__accordion-item">
                  <div className="services-page__card-label">Колличество камер</div>
                  <div className="connected-page__text">{item.number_of_cameras}</div>
                </div>
              )}
              {item.status && (
                <div className="connected-page__accordion-item">
                  <div className="services-page__card-label">Статус</div>
                  <div className="connected-page__text">
                    {item.status === StatusEnum.OnLease
                      ? 'В аренде'
                      : item.status === StatusEnum.Owned
                      ? 'В собственности'
                      : ''}
                  </div>

                  {item.show_equipment_payback_btn && (
                    <p
                      className="connected-page__link cursor-pointer"
                      onClick={() =>
                        navigate(
                          SERVICECONNECTNEW + `/equipment-pay/${service_cid}/${item.rent?.rent_id}/`
                        )
                      }
                    >
                      Выкуп оборудования
                    </p>
                  )}
                </div>
              )}

              {/* //Стоимость для покупки */}
              {item.cost_equipment && (
                <div className="connected-page__accordion-item">
                  <div className="services-page__card-label">Стоимость</div>
                  <div className="connected-page__text">{item.cost_equipment} ₽</div>
                </div>
              )}
              {/* //Стоимость для покупки */}

              {/* //Стоимость для аренды */}
              {item.rent && (
                <div className="connected-page__accordion-item">
                  <div className="services-page__card-label">Стоимость</div>
                  <div className="connected-page__text">
                    {paymentDisplay(
                      item.rent.plan.plan_fee,
                      item.rent.plan.fee_period,
                      item.rent.plan.fee_kind
                    )}
                  </div>
                </div>
              )}
              {/* //Стоимость для аренды */}

              {/* //Дата подключения*/}
              {item.rent?.rent_start_date && (
                <div className="connected-page__accordion-item">
                  <div className="services-page__card-label">Дата подключения</div>
                  <div className="connected-page__text">{item.rent.rent_start_date}</div>
                </div>
              )}
              {/* //Дата подключения*/}
            </React.Fragment>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ServiceEquipment;
